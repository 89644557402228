import React, { useContext, useEffect, useState } from "react";
import favorito from "../../../../../../images/menu/favorito.png";
import favorito_resaltado from "../../../../../../images/menu/resaltado.png";
import EditModule from "../EditModules";
import EditDate from "../EditDate"
import "../../../timeLineRRHH.css";
import $, { data } from "jquery";
import { API } from "../../../../../../http-common";
import { DataContext } from "../../../../../../context/dataContext";
import { UserContext } from "../../../../../../context/userContext";
import moment from "moment";
import {Col, Container, Row} from "react-bootstrap";
import Select from 'react-select'
import * as Constants from "../../../../../../constants.js";
const Accordion1 = ({
  available,
  setAvailable,
  idSearch,
  module,
  applicant,
  disableEdit,
  setModalProcessShow = null,
  onDataChange = null
}) => {
  const { userData, setUserData } = useContext(UserContext);
  const {
    dataTableChanged,
    setDataTableChanged,
    currentSearchStatus,
    setCurrentSearchStatus,
  } = useContext(DataContext);
  const [submit, setSubmit] = useState(false);
  const [outstanding, setOutstanding] = useState(applicant.outstanding);
  const [attributes, setAttributes] = useState(applicant.attribute)
  const [attributeTaking, setAttributeTaking] = useState(0)
  const [highlighted, setHighlighted] = useState(false);
  const [feedback, setFeedback] = useState(module.data.feedback);
  const [moduleStatus, setModuleStatus] = useState(module.status);
  const [moduleWritable, setModuleWritable] = useState(module.writable);
  const [feedbackError, setFeedbackError] = useState(false);
  const [addmissionDate, setAdmissionDate] = useState(module.admission_date)
  const [errorDate, setErrorDate] = useState(false)
  const [acceptedDate, setAcceptedDate] = useState(module.admission_date ? module.admission_date : null)
  const [date, setDate] = useState(
    module.data.date !== ""
      ? moment(module.data.date, "DD/MM/YYYY").format("YYYY-MM-DD")
      : moment(new Date()).format("YYYY-MM-DD")
  );

  const [perfilSelected, setPerfilSelected] = useState([])
  const [perfilSelectedTaking,setPerfilSelectedTaking] = useState([])
  const [presentedBy2, setPresentedBy2] = useState([
    { value: 0, label: "PEOPLE" },
    { value: 1, label: "LINEA" }
  ])
  const [presentedBy, setPresentedBy] = useState(applicant.presentedby)
  const [presentedSelectedTaking,setPresentedSelectedTaking] = useState(applicant.presentedby)
  const [dataModule, setDataModule] = useState(module);
  const [showModal, setShowModal] = useState(false);
  const [showModalDate, setshowModalDate] = useState(false); //Nuevo estado para editar la fecha de entrevista
  const [perfilSelected2, setPerfilSelected2] = useState([])
  const [loading, setLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const fixFeedbackDate= () =>{
    if(module.status === 4){
      setDate(moment().format("YYYY-MM-DD"))
    }
  }
  const handleClose = () => setShowModal(false);

  const handleCloseDate = () => setshowModalDate(false); //Nueva funcion para editar la fecha de entrevista

  const editionMode = () => {
    setModuleWritable(1);
    //module.writable = 1;
    setShowModal(false);
  };
  useEffect(() => {
    getCandidateById(applicant.candidate_id)
    changeAttributesValues(applicant.attributes)
    fixFeedbackDate()
  }, []);
  const changeAttributesValues = (attribute) => {
  let filterAttributes = attribute.filter((module) => module.module_id === 1)
   let SelectAttributes = filterAttributes.map((attribute) => {
    return {value:attribute.id_attributes   , label:attribute.attributes_name}
   })
   setAttributes(SelectAttributes)
  }
  //Api para traer los perfiles y funcion para setearlos en el select y selector del candidato
  const getCandidateById = async (candidate_id) => {
    let success = true;
    try{
      const res = await API.get(`/candidates/${candidate_id}`)
      if(res.data.error !== 0){
        throw new Error(res.data.message)
      }
      const dataCandidate = res.data.data
      const resListProfiles = await  API.get("/candidates/profiles")
      if(resListProfiles.data.error !== 0){
        throw new Error(resListProfiles.data.message)
      }
      let perfilesData = []
      const profileList = resListProfiles.data.data
      perfilesData = profileList.map(i =>{
        return {value: i.id, label: i.profile_detail}
      })

      setPerfilSelected(perfilesData)
      const profile = profileList.find((x) => x.id === dataCandidate.profile_id )
      if(profile){
        setPerfilSelected2({value: profile.id, label: profile.profile_detail})
      } else {
        setPerfilSelected2({value: 0, label: "Selecionar un perfil"})
      }
      setLoading(false)

    }
    catch(e){
      console.log(e)
    }

  }

  const editionModeDate = () => { //Nueva funcion para editar la fecha de entrevista

    const dataForm = {
      search_id: idSearch,
      candidate_id: applicant.candidate_id,
      applicant_id: applicant.applicant_id,
      module_id: 1,
      employees_id: userData.id,
      feedback_date: date,
      feedback: feedback,
      accepted: null,
      link_note: null,
      admission_date: acceptedDate,
      status: 4,
    }

    setErrorDate(false)
    if(!acceptedDate){
      setErrorDate(true)
    }else{
      const module_id = dataModule.id ?? 0;

        API.post("/applications/timeline/"+applicant.applicant_id+"/module/"+module_id, dataForm)
        .then((response) => {
          setAdmissionDate(acceptedDate)
          setDataModule({
            ...dataModule,
            id: response.data.id
          })
            if(null !== onDataChange) onDataChange()
          toggleClass()

        })
        .catch()
    }
    setshowModalDate(false);

  }

  const toggleClass = () => {
    let modal = document.getElementsByClassName("modal_edit_applicant");
    if(modal.length > 0){
      setTimeout(() => {
        document.body.classList.add("modal-open");
      }, 500)
    }

  }

  const sendData = async () => {
    setIsLoading(true)
    if(moduleStatus === 4) {
     try {
      API.post(
        "applications/timeline/" + applicant.applicant_id + "/save-for-later",{profile_id:perfilSelectedTaking})
        .then((response) => {
          if(null !== onDataChange) onDataChange()
          if(null !== setModalProcessShow) setModalProcessShow(false)

          toggleClass()// setDataTableChanged(true);
          setIsLoading(false)
        })
     }
      catch(error) {
        console.log(error)
        setIsLoading(false)
      }
    }
      //*ojo highlighted es Destacado (avanza) y outstanding es resaltado ...
      const dataForm = {
        search_id: idSearch,
        candidate_id: applicant.candidate_id,
        applicant_id: applicant.applicant_id,
        module_id: 1,
        employees_id: userData.id,
        feedback_date: date,
        feedback: feedback,
        accepted: null,
        link_note: null,
        admission_date: addmissionDate,
        status: moduleStatus,
        attributes: moduleStatus === 2 ? attributeTaking : 0
      };
       const module_id = dataModule.id ?? 0
        try{
          await API.post("/applications/timeline/"+applicant.applicant_id+"/module/"+module_id, dataForm,{profile_id:null})
        .then(async (response) => {
          await API.put("/candidates/toggle_approve", {
            value: outstanding ,
            id_user: applicant.candidate_id,
          }).then((respornse) => {});

          await API.put("/candidates/toggle_approve_presentedby", {
            valuePresentedby: presentedSelectedTaking,
            id_user: applicant.candidate_id,
          }).then((respornse) => {});
          
          setModuleWritable(0);
          setDataModule({
            ...dataModule,
            id: response.data.id
          })

          if(null !== onDataChange) onDataChange()
          toggleClass()
          setIsLoading(false)
        })
        }
        catch(error){
          console.log(error)
          setIsLoading(false)
        }
 
  };


  const handleChange = e => {
    const { value } = e.target;
    setAcceptedDate(value)
  }
  const selectChange = (e) => {
    setPerfilSelectedTaking(e.value)
  }

  const sendAcceptedDate = () => {
    const dataForm = {
      search_id: idSearch,
      candidate_id: applicant.candidate_id,
      applicant_id: applicant.applicant_id,
      module_id: 1,
      employees_id: userData.id,
      feedback_date: date,
      feedback: feedback,
      accepted: null,
      link_note: null,
      admission_date: acceptedDate,
      status: 4,
    }
    setIsLoading(true)
    setErrorDate(false)
    if(!acceptedDate){
      setErrorDate(true)
    }else{
      try{
        const module_id = dataModule.id ?? 0;

        API.post("/applications/timeline/"+applicant.applicant_id+"/module/"+module_id, dataForm)
        .then((response) => {
          setAdmissionDate(acceptedDate)
          setDataModule({
            ...dataModule,
            id: response.data.id
          })
            if(null !== onDataChange) onDataChange()
          toggleClass()
          setIsLoading(false)
        })
      }
      catch(e){
        console.log(e)
        setIsLoading(false)
      }
       
    }
  }

  const handlebackModulo = async () => {
    try {
      setIsLoading(true)
      const response = await API.post(`/applications/timeline/${applicant.candidate_id}/${idSearch}/turn_back_module`);
      if (response.data.error === 0) {
        // Handle success (e.g., show a success message or update the UI)
       
        if(null !== onDataChange) onDataChange()
         if(null !== setModalProcessShow) setModalProcessShow(false)
        setIsLoading(false)
      } else {
        // Handle error (e.g., show an error message)
        console.error('Error turning back module:', response.data.message);
      }
    } catch (error) {
      console.error('Error turning back module:', error);
    }
  };


  const handleInterview1 = (event) => {
    const status = event.target.id;
    switch (status) {
      case "ok_avanza":
        setModuleStatus(1);
        break;
      case "ok_no_avanza":
        setModuleStatus(2);
        break;
      case "ok_guarda":
        setModuleStatus(3);
        break;
      case "database":
        setModuleStatus(4);
        break;
        case "avanza-con-reserva":
        setModuleStatus(8);
        break;
      default:
        setModuleStatus(0);
    }
    checkInputs(status);
    setSubmit(true);
  };

  const checkInputs = (status) => {
    if (feedback.length === 0 && !['ok_dicidir', 'database'].includes(status)) {
      setFeedbackError(true);
    } else {
      setFeedbackError(false);
    }
  };


  useEffect(() => {
    if(submit && !feedbackError)
      sendData()
    setSubmit(false)
  }, [submit])
  return (
    <>
      {/* Modal for edition purpose */}
      <div className="card">
        <div className="card-header" id="headingOne">
          <h5 className="mb-0">
            <button
              className="btn btn-link"
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
            >
              1° Entrevista
            </button>
            <span
              className="mas"
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
            >
              +
            </span>
          </h5>
        </div>

        <div
          id="collapseOne"
          className={
            disableEdit
              ? "collapse disabled-accordion show"
              : moduleWritable === 1
                ? "collapse show"
                : "collapse disabled-accordion show"
          }
          aria-labelledby="headingOne"
        >
          <div
            className={`card-body ${
              disableEdit ? "disabled" : !(moduleWritable === 1) && "disabled"
              }`}
          >
            {/*//Nueva funcion para editar la fecha de entrevista */}
            {(null !== addmissionDate || module.completed === 1) && (
              <>
                {(null !== addmissionDate || module.completed === 1) ? (
                  <>
                    {dataModule.completed === 1 &&
                      moduleWritable === 1 &&
                      dataModule.editable === "all" ? (

                      <p> Agendado para el día {moment(addmissionDate).format('DD/MM/YYYY')}  <EditDate
                        editionModeDate={editionModeDate}
                        showModalDate={showModalDate}
                        handleCloseDate={handleCloseDate}
                        setshowModalDate={setshowModalDate}
                        handleChange={handleChange}
                        acceptedDate={acceptedDate}
                        errorDate={errorDate}
                        date={date}
                      >
                      </EditDate>
                      </p>
                    ) : <>
                      <p> Agendado para el día {moment(addmissionDate).format('DD/MM/YYYY')}</p>
                    </>
                    }

                  </>
                ) : <p>Sin fecha de entrevista</p>}

                <div className="barra-superior">
                  <div className="left">
                    {moduleWritable === 1 ? (
                      <div className="item">
                        <span>Fecha</span>
                        <input max={moment().format("YYYY-MM-DD")} style={{ margin: '15px 0' }} type="date" value={date} onChange={(e) => {
                          setDate(e.target.value)
                        }} />
                      </div>
                    ) : (
                      <div className="item">
                        <span>Fecha</span>
                        {/*<Moment format="DD/MM/YYYY">{new Date()}</Moment>*/}
                        <label>
                          {moment(date).format("DD/MM/YYYY")}
                        </label>
                      </div>
                    )}

                    <div className="item">
                      <span>Entrevista realizada por</span>
                      <label>
                        {dataModule.data.interviewer.username != ""
                          ? dataModule.data.interviewer.username
                      : Constants.RRHH.includes(userData.role_id)
                            ? userData.username
                            : ""}
                      </label>
                    </div>
                  </div>
                  <div className="right discretion">
                  <div className="item">
                    <button
                      className={outstanding ? "highlighted" : ""}
                      onClick={() => setOutstanding(outstanding  === 0 ? 1 : 0 )}
                    >
                      Resaltar Candidato{" "}
                      <img
                        src={outstanding ? favorito_resaltado : favorito}
                        alt=""
                      />
                    </button>
                    </div>

                      <div className="reserva">
                       <span> Presentado Por</span>
                        {
                          moduleWritable === 1 ? (
                            <Select
                          onChange={(e) => setPresentedSelectedTaking(e.value)}
                          className="select"
                          defaultValue={presentedBy === 1 ? presentedBy2[1] : presentedBy2[0]}
                          options={presentedBy2}
                        />
                          ) :
                          (
                            <Select
                            isDisabled={true}
                          onChange={(e) => setPresentedSelectedTaking(e.value)}
                          className="select"
                          defaultValue={presentedBy === 1 ? presentedBy2[1] : presentedBy2[0]}
                          options={presentedBy2}
                        />
                          )
                        }
                      </div>
           
                  </div>
                </div>
                <div className="content-feedback">
                  <span>Feedback</span>
                  <textarea
                    id="feedback"
                    cols="30"
                    rows="10"
                    placeholder=""
                    className={feedbackError ? "input-error" : ""}
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    readOnly={moduleWritable === 0 ? true : false}
                    disabled={false}
                  ></textarea>
                  <span className={feedbackError ? "span-error" : "d-none"}>
                    Campo Vacio
                  </span>
                </div>
                {
                  <div className="botones-vista">
                    <button
                      id="aprobar_1"
                      onClick={() => $("#avanza-busqueda-1").modal("show")}
                      data-toggle="modal"
                      data-target="#avanza-busqueda-1"
                      disabled={isLoading ? true : false}
                    >
                      AVANZA
                    </button>
                    <button
                      id="aprobar_reserva"
                      onClick={() => $("#avanza-reserva-busqueda-1").modal("show")}
                      data-toggle="modal"
                      data-target="#avanza-reserva-busqueda-1"
                      disabled={isLoading ? true : false}
                    >
                      AVANZA CON RESERVA
                    </button>
                    <button
                      id="pausar_1"
                      onClick={() => $("#guardar-busqueda-1").modal("show")}
                      data-toggle="modal"
                      data-target="#guardar-busqueda-1"
                      disabled={isLoading ? true : false}
                    >
                      NO AVANZA, GUARDAR PERFIL PARA OTRA BÚSQUEDA
                    </button>
                    <button
                      id="negar_1"
                      onClick={() => $("#no-avanza-busqueda-1").modal("show")}
                      data-toggle="modal"
                      data-target="#no-avanza-busqueda-1"
                      disabled={isLoading ? true : false}
                    >
                      NO AVANZA
                    </button>
                    <button
                      id="decidir_1"
                      onClick={() => $("#decidir-busqueda-1").modal("show")}
                      data-toggle="modal"
                      data-target="#decidir-busqueda-1"
                      disabled={isLoading ? true : false}
                    >
                      DECIDIR MÁS TARDE
                    </button>

                    <button
                      id="database"
                      data-toggle="modal"
                      data-target="#database-busqueda-1"
                      onClick={() => $("#database-busqueda-1").modal("show")}
                      disabled={isLoading ? true : false}
                    >
                      BASES DE DATOS
                    </button>
                    
                  </div>
                }

                {(disableEdit || moduleWritable === 0) && (
                  <div className="estado botones-vista">
                    {(() => {
                      switch (moduleStatus) {
                        case 0:
                          return <span className="pausado">PENDIENTE</span>;
                          break;
                        case 1:
                          return <span className="aprobado">AVANZA</span>;
                          break;
                        case 2:
                          return <span className="negado">NO AVANZA</span>;
                          break;
                        case 3:
                          return (
                            <span className="pausado">
                              GUARDADO PARA OTRA BÚSQUEDA
                            </span>
                          );
                          break;
                          case 8:
                            return <span className="aprobado">AVANZA CON RESERVAS</span>;
                            break;
                        default:
                          return <span className="pausado">PENDIENTE</span>;
                      }
                    })()}
                  </div>
                )}

                {dataModule.completed === 1 &&
                  moduleWritable === 0 &&
                  dataModule.editable === "all" && (
                    <EditModule
                      editionMode={editionMode}
                      showModal={showModal}
                      handleClose={handleClose}
                      setShowModal={setShowModal}
                    ></EditModule>
                  )}
              </>
            )}

            {(module.completed === 0 && null === addmissionDate) && (
              <>
                <div className="barra-superior">
                  <div className="left">
                    <div className="item">
                      <span>Fecha</span>
                      <input type="date" onChange={handleChange} value={acceptedDate} />
                      <span className={errorDate ? "span-error" : "d-none"}>
                        Campo Vacio
                      </span>
                    </div>
                  </div>
                </div>

                {
                  isLoading && (
                <div className="botones-vista">
                  <button onClick={sendAcceptedDate} id="aceptar" disabled={true}>Cargando...</button>
                  <button onClick={handlebackModulo} id="negar_1" disabled={true}>Cargando...</button>
                </div>
                  )
                }
                  {
                    !isLoading && (
                <div className="botones-vista">
                  <button onClick={sendAcceptedDate} id="aceptar">Entrevista agendada</button>

                  <button onClick={handlebackModulo} id="negar_1">Regresar Modulo Pendiente</button>
                </div>
                    )
                  }
                  
              </>
            )}

          </div>
        </div>
      </div>

      {/* Modal de avanza candidato */}
      <div
        className="modal fade"
        id="avanza-busqueda-1"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="basicModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body ">
              <span className="texto-general">
                ¿Estas seguro de avanzar con este candidato?
              </span>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                id="ok_avanza"
                data-dismiss="modal"
                onClick={(e) => handleInterview1(e)}
              >
                Ok
              </button>
              <button
                type="button"
                className="btn btn-primary"
                id="cancel"
                data-dismiss="modal"
                onClick={() => {
                  toggleClass()
                }}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Modal de avanza candidato pero con reserva*/}
      <div
        className="modal fade"
        id="avanza-reserva-busqueda-1"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="basicModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body ">
              <span className="texto-general">
                ¿Estas seguro de avanzar con este candidato con reserva?
              </span>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                id="avanza-con-reserva"
                data-dismiss="modal"
                onClick={(e) => handleInterview1(e)}
              >
                Ok
              </button>
              <button
                type="button"
                className="btn btn-primary"
                id="cancel"
                data-dismiss="modal"
                onClick={() => {
                  toggleClass()
                }}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal de no avanza, guardar perfil para otra busqueda*/}
      <div
        className="modal fade"
        id="guardar-busqueda-1"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="basicModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body ">
              <span className="texto-general">
                ¿Estas seguro que deseas guardar este perfil para otra búsqueda?
              </span>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                id="ok_guarda"
                data-dismiss="modal"
                onClick={(e) => handleInterview1(e)}
              >
                Ok
              </button>
              <button
                type="button"
                className="btn btn-primary"
                id="cancel"
                data-dismiss="modal"
                onClick={() => {
                  toggleClass()
                }}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal de no avanza candidato */}
      <div
        className="modal fade"
        id="no-avanza-busqueda-1"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="basicModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body ">
            <Container >
                    <Row className='with-line aling-items-center bsPrefix'>
                        <Col md="6" lg="6" sm="12" xs="12" >
                        <span className=""> <strong>Seleccionar un motivo</strong></span>
                        {
                          loading && (
                            <Select
                            onChange={(e) => setAttributeTaking(e.value)}
                            className="select"
                            defaultValue={{value:0 , label:'Seleccionar un motivo'}}
                            options={attributes}
                          />
                          )
                        }
                        {
                          !loading && (
                            <Select
                          onChange={(e) => setAttributeTaking(e.value)}
                          className="select"
                          defaultValue={{value:0 , label:'Seleccionar un motivo'}}
                          options={attributes}
                          />
                          )
                        }



                        </Col>
                    </Row>
                    <span className="texto-general">
                      ¿Estas seguro que deseas rechazar el candidato? <br />
                     Por favor seleccione el motivo por el cual lo rechaza
                  </span>
              </Container>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                id="ok_no_avanza"
                data-dismiss="modal"
                onClick={(e) => handleInterview1(e)}
              >
                Ok
              </button>
              <button
                type="button"
                className="btn btn-primary"
                id="cancel"
                data-dismiss="modal"
                onClick={() => {
                  toggleClass()
                }}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal de decidir mas tarde */}
      <div
        className="modal fade"
        id="decidir-busqueda-1"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="basicModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body ">
              <span className="texto-general">
                ¿Estas seguro que deseas decidir mas tarde?
              </span>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                id="ok_dicidir"
                data-dismiss="modal"
                onClick={(e) => handleInterview1(e)}
              >
                Ok
              </button>
              <button
                type="button"
                className="btn btn-primary"
                id="cancel"
                data-dismiss="modal"
                onClick={() => {
                  toggleClass()
                }}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>



      {/* Modal de bases de datos*/}
      <div
        className="modal fade"
        id="database-busqueda-1"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="basicModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body row">
            <Container >
                    <Row className='with-line aling-items-center bsPrefix'>
                        <Col md="6" lg="6" sm="12" xs="12" >
                        <span className=""> <strong>Seleccionar un perfil para el usuario</strong></span>
                        {
                          loading && (
                            <Select
                            onChange={selectChange}
                            defaultValue={perfilSelected2 ? perfilSelected2 : 'Seleccionar perfil'}
                            options={perfilSelected}
                            />
                          )
                        }
                        {
                          !loading && (
                            <Select
                            onChange={selectChange}
                            defaultValue={perfilSelected2 ? perfilSelected2 : 'Seleccionar perfil'}
                            options={perfilSelected}
                            />
                          )
                        }



                        </Col>
                    </Row>
                    <span className="texto-general">
                      ¿Estas seguro que deseas pasarlo a bases de datos? <br />
                      Esta acción descartará el feedback escrito
                  </span>
              </Container>

            </div>
            <div className="modal-footer">
            <button
                type="button"
                className="btn btn-primary"
                id="database"
                data-dismiss="modal"
                onClick={(e) => handleInterview1(e)}
              >
                Ok
              </button>
              <button
                type="button"
                className="btn btn-primary"
                id="cancel"
                data-dismiss="modal"
                onClick={() => {
                  toggleClass()
                }}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Accordion1;
