import React, { useState} from "react"
import './header.css'

const HeaderPublic = () => {

    return (
        <div className="banner">
            <div className="content-banner">
                <div className="left">
                    <span className="titulo"><a href="#">BALANZ</a></span>
                </div>

            </div>
        </div>
    )
}

export default HeaderPublic