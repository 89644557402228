import Footer from '../../../includes/Footer/Footer'
import Header from '../../../includes/Header/Header'
import Menu from '../../../includes/Menu/Menu'
import '../applicants.css'
import favorito from '../../../../images/menu/favorito.png'
import curriculum from '../../../../images/menu/curriculum.png'
import { Link } from 'react-router-dom'

const InfoApplicant = () => {

  const style = {
    display: {
      display: "none"
    },
    border: {
      border: "none"
    }
  }

  return (
    <>
      <Header />
      <Menu />
      <div className="content-vista" 
      // style={style.display}
      >
          <div className="content-pestana">
              <div className="pestana active">
                  <span>CANDIDATOS</span>
              </div>
          </div>
          <div className="vista-candidato">
              <div className="content-cerrar">
                <Link to='/candidatos'><span className="cerraar">CERRAR VISTA CANDIDATO <b>X</b></span></Link>
              </div>

              <div className="cabecera row">
                  <div className="col-md-4 caja">
                      <span>Ana Inés Colinski</span>
                  </div>
                  <div className="col-md-4 caja">

                  </div>
                  <div className="col-md-4 caja">
                      <span>NÚMERO DE POSTULACIONES: <b>3</b></span>
                  </div>
              </div>
              <div className="datos row">
                  <div className="col-md-4 caja">
                      <span>DNI <b>22.365.365</b></span>
                  </div>
                  <div className="col-md-4 caja">
                      <span>e-mail <b>ai.colinsky@gmail.com</b></span>
                  </div>
                  <div className="col-md-4 caja">
                      <span>Tel <b>113 444 1245</b></span>
                  </div>
              </div>
              <div className="datos row ">
                  <div className="col-md-4 caja">
                      <span>Fecha de Nacimiento <b>17/05/1974</b></span>
                  </div>
                  <div className="col-md-4 caja">
                      <span>Educación <b>Postgrado</b></span>
                  </div>
                  <div className="col-md-4 caja">
                      <span>Titulo <b>Lic. en Economía - Maestria en Finanzas</b></span>
                  </div>
              </div>
              <div className="datos row">
                  <div className="col-md-4 caja">
                      <span>Universidad <b>Universidad de San Andres</b></span>
                  </div>
                  <div className="col-md-4 caja">
                      <span>Nombre de última posición <b>Asistente de Cuentas</b></span>
                  </div>
                  <div className="col-md-4 caja">
                      <span>Gente a cargo <b>0</b></span>
                  </div>
              </div>
              <div className="datos row" style={style.border}>
                  <div className="col-md-4 caja">
                      <span>Años de Experiencia <b>6 Años</b></span>
                  </div>
                  <div className="col-md-4 caja">
                  </div>
                  <div className="col-md-4 caja">
                      <button className="curriculum"><img src={curriculum} alt="" /> VER CV ADJUNTO</button>
                  </div>
              </div>
              <div id="accordion">
                  <div className="card">
                      <div className="card-header" id="headingOne">
                          <h5 className="mb-0">
                              <button className="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                  1° Entrevista
                              </button>
                              <span className="mas" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">+</span>
                          </h5>
                      </div>

                      <div id="collapseOne" className="collapse show" aria-labelledby="headingOne">
                          <div className="card-body">
                              <div className="barra-superior">
                                  <div className="left">
                                      <div className="item">
                                          <span>Fecha</span>
                                          <label>14/09/2020</label>
                                      </div>
                                      <div className="item">
                                          <span>Entrevista realizada por</span>
                                          <label>Florencia Maldonado</label>
                                      </div>
                                  </div>
                                  <div className="right">
                                      <button>
                                          Resaltar Candidato <img src={favorito} alt="" />
                                      </button>
                                  </div>
                              </div>
                              <div className="content-feedback">
                                  <span>Feedback</span>
                                  <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Numquam recusandae quasi velit amet ab ea neque deleniti, odit ducimus soluta temporibus debitis nisi illum reiciendis repellat, accusantium magnam libero suscipit!</p>
                              </div>
                              <span className="avanza">AVANZA</span>
                          </div>
                      </div>
                  </div>
                  <div className="card">
                      <div className="card-header" id="headingTwo">
                          <h5 className="mb-0">
                              <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                  2° Entrevista
                              </button>
                              <span className="mas" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">+</span>
                          </h5>
                      </div>
                      <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo">
                          <div className="card-body">
                              <div className="barra-superior">
                                  <div className="left">
                                      <div className="item">
                                          <span>Fecha</span>
                                          <label>14/09/2020</label>
                                      </div>
                                      <div className="item">
                                          <span>Entrevista realizada por</span>
                                          <label>Florencia Maldonado</label>
                                      </div>
                                  </div>
                                  <div className="right">
                                      <button className="resaltado">
                                          Resaltar Candidato <img src="images/menu/resaltado.png" alt="" />
                                      </button>
                                  </div>
                              </div>
                              <div className="content-feedback">
                                  <span>Feedback</span>
                                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Tempora vero iure veniam, blanditiis facere aliquam amet corrupti vel consectetur, quis asperiores eum. In, dicta. Asperiores ut nobis distinctio dolore corporis!</p>
                              </div>
                              <span className="guardado">CANDIDATO GUARDADO</span>
                          </div>
                      </div>
                  </div>
                  <div className="card">
                      <div className="card-header" id="headingThree">
                          <h5 className="mb-0">
                              <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                  3° Entrevista
                              </button>
                              <span className="mas" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">+</span>
                          </h5>
                      </div>
                      <div id="collapseThree" className="collapse" aria-labelledby="headingThree">
                          <div className="card-body">
                              <div className="barra-superior">
                                  <div className="left">
                                      <div className="item">
                                          <span>Fecha</span>
                                          <label>14/09/2020</label>
                                      </div>
                                      <div className="item">
                                          <span>Entrevista realizada por</span>
                                          <label>Florencia Maldonado castro parra</label>
                                      </div>
                                  </div>
                                  <div className="right">
                                      <button>
                                          Resaltar Candidato <img src={favorito} alt="" />
                                      </button>
                                  </div>
                              </div>
                              <div className="content-feedback">
                                  <span>Feedback</span>
                                  <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus iure accusamus, repudiandae deserunt quasi minus nesciunt, cumque expedita architecto est suscipit illo possimus voluptates repellendus nisi enim recusandae quisquam voluptatem!</p>
                              </div>
                              <span className="no_avanza">NO AVANZA</span>
                          </div>
                      </div>
                  </div>
                  <div className="card">
                      <div className="card-header" id="propuesta-heading">
                          <h5 className="mb-0">
                              <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#propuesta" aria-expanded="false" aria-controls="propuesta">
                                  Propuesta
                              </button>
                              <span className="mas" data-toggle="collapse" data-target="#propuesta" aria-expanded="false" aria-controls="propuesta">+</span>
                          </h5>
                      </div>
                      <div id="propuesta" className="collapse" aria-labelledby="propuesta-heading">
                          <div className="card-body">
                              <span className="descicion">ACEPTO</span>
                          </div>
                      </div>
                  </div>
                  <div className="card">
                      <div className="card-header" id="psicotecnica-heading">
                          <h5 className="mb-0">
                              <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#psicotecnica" aria-expanded="false" aria-controls="psicotecnica">
                                  Psicotecnica
                              </button>
                              <span className="mas" data-toggle="collapse" data-target="#psicotecnica" aria-expanded="false" aria-controls="psicotecnica">+</span>
                          </h5>
                      </div>
                      <div id="psicotecnica" className="collapse" aria-labelledby="psicotecnica-heading">
                          <div className="card-body">
                              <div className="barra-superior">
                                  <div className="left">
                                      <div className="item">
                                          <span>Fecha</span>
                                          <label>14/09/2020</label>
                                      </div>
                                      <div className="item">
                                          <span>Entrevista realizada por</span>
                                          <label>Florencia Maldonado</label>
                                      </div>
                                  </div>
                                  <div className="right">
                                      <button>
                                          DESCARGAR
                                      </button>
                                  </div>
                              </div>
                              <div className="content-feedback">
                                  <span>Feedback</span>
                                  <p>notas dejadas por el psicólogo</p>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="card">
                      <div className="card-header" id="ingreso-heading">
                          <h5 className="mb-0">
                              <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#ingreso" aria-expanded="false" aria-controls="ingreso">
                                  Ingreso
                              </button>
                              <span className="mas" data-toggle="collapse" data-target="#ingreso" aria-expanded="false" aria-controls="ingreso">+</span>
                          </h5>
                      </div>
                      <div id="ingreso" className="collapse" aria-labelledby="ingreso-heading">
                          <div className="card-body">
                              <div className="barra-superior">
                                  <div className="card-body">
                                      <div className="left">
                                          <div className="item">
                                              <span>Fecha de ingreso</span>
                                              <input type="date" />
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="botones-vista">
                                  <button id="aceptar">CONFIRMAR INGRESO</button>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    <Footer />
    </>
  )
}

export default InfoApplicant