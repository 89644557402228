import React, { useEffect, useState, useContext, useRef } from "react";
import {  Button, Col, Modal, Row } from "react-bootstrap";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useSortBy,
} from "react-table";
import Footer from "../../includes/Footer/Footer";
import Header from "../../includes/Header/Header";
import Menu from "../../includes/Menu/Menu";
import "./ABMUser.css";
import ver from "../../../images/menu/ver.png";
import { API } from "../../../http-common";
import derecha from "../../../images/menu/derecha.png"
import LoadingList from "../Loading/LoadingList";
import '.././../ModalGenericoBalanz/ModalBalanz.css'
// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);
  return (
    <input
      className="form-control search"
      value={value || ""}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      placeholder={`Buscar`}
    />
  );
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      className="form-control"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

const Styles = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;

  /* This will make the table scrollable when it gets too small */
  .tableWrap {
    display: block;
    max-width: 100%;
    overflow-y: hidden;
    border-bottom: 1px solid black;
  }

  table {
    /* Make sure the inner table is always as wide as needed */
    width: 100%;
    border-spacing: 0;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      /* The secret sauce */
      /* Each cell should grow equally */
      width: 1%;
      /* But "collapsed" cells should be as small as possible */
      &.collapse {
        width: 0.0000000001%;
      }

      :last-child {
        border-right: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`;

function Table({ columns, data, setAltaModal, setBajaModal }) {
  const currentStateId = 'ListUsers_0';

  let initialState = {};
  if (localStorage.getItem('UsersTableStateId') === currentStateId) {
    try {
      const lastState = JSON.parse(localStorage.getItem('UsersTableStateData'));
      initialState = lastState || {};
    } catch {
      initialState = {};
    }
  }

  const storeTableStateReducer = (newState, action, prevState) => {
    localStorage.setItem('UsersTableStateId', currentStateId);
    localStorage.setItem('UsersTableStateData', JSON.stringify(newState));
    return newState;
  };

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data,
      initialState,
      stateReducer: storeTableStateReducer
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );

  const setTableRelativePage = (page) => {
    switch (page) {
      case 'prev':
        previousPage();
        break;
      case 'next':
        nextPage();
        break;
      case 'first':
        gotoPage(0);
        break;
      case 'last':
        gotoPage(pageCount - 1);
        break;
    }
  }

  const setTablePageNumber = pageNumber => {
    const pageIndex = pageNumber - 1;
    if (pageIndex >= 0 && pageIndex < pageCount) gotoPage(pageIndex);
  }

  // Render the UI for your table
  return (
    <Styles>
      <div className="tableWrap">
      <div className="d-flex">
          <div className="botones">
            <button type="button" onClick={() => {
              setAltaModal(true)
            }}>Alta de usuario</button>
          </div>
          <div className="botones">
            <button type="button" onClick={() => {
              setBajaModal(true)
            }}>Baja de usuario</button>
          </div>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </div>
        <table className="table table_busqueda" {...getTableProps()}>
        <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {(
                  <th date-sortBy-id={column.id} {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}{" "}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                    {/* Render the columns filter UI */}
                    <div>
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </th>
                )})}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr className={row.original.status} {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <ul className="pagination">
        <li
            className="page-item"
            onClick={() => {
              setTableRelativePage("first");
            }}
            disabled={!canPreviousPage}
          >
            <a className="page-link">{"Primera"}</a>
          </li>
          <li
            className="page-item"
            onClick={() => {
              setTableRelativePage("prev");
            }}
            disabled={!canPreviousPage}
          >
            <a className="page-link">{"< Anterior"}</a>
          </li>
          <li
            className="page-item"
            onClick={() => {
              setTableRelativePage("next");
            }}
            disabled={!canNextPage}
          >
            <a className="page-link">{"Siguiente >"}</a>
          </li>
          <li
            className="page-item"
            onClick={() => {
              setTableRelativePage("last");
            }}
            disabled={!canNextPage}
          >
            <a className="page-link">{"Ultimo"}</a>
          </li>
          <li>
            <a className="page-link no-hover">
              Pagina{" "}
              <strong>
                {pageIndex + 1} de {pageCount}
              </strong>{" "}
            </a>
          </li>
          <li className="page-item">
            <span>
              <input
                className="page-link no-hover"
                type="number"
                defaultValue={pageIndex + 1}
                min="1"
                max={pageCount}
                value={pageIndex + 1}
                onChange={(e) => {
                  const pageNumber = e.target.value ? Number(e.target.value) : 1;
                  setTablePageNumber(pageNumber);
                }}
                style={{ width: "80px", textAlign: "center" }}
              />
            </span>
          </li>{" "}
          <select
            className="page-link no-hover"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            style={{ fontSize: "15px", width: "120px", padding: "7px" }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Mostrar {pageSize}
              </option>
            ))}
          </select>
        </ul>
      </div>
    </Styles>
  );
}

// This is a custom filter UI for selecting a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">Todos</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

function SelectStatusFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <>
      <div className="content-filtrar">
        <span className="filtrar-span">Filtrar por</span>
        <select
          name="filter"
          value={filterValue}
          onChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
        >
          <option value="">Todos</option>
          {options.map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    </>
  );
}
const initialForm = [
  {
    Nombre: '',
    Apellido: '',
    idPersona: '',
    email: '',
    Documento: '',
    role_id: '',
    area: '',
    cost_center: '',
    sector: '',
    position: ''
    
  }
]
const ABMUsers = () => {
  const [data, setData] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [showModal, displayModal] = useState(0);
  const [updateUser, setUpdateUser] = useState("");

  const [altaModal, setAltaModal] = useState(false)
  const [step, setStep] = useState(1)
  const [searchUser, setSearchUser] = useState('')
  const [stepDataForDelete,setStepDataForDelete] = useState([])
  const [searchUserError, setSearchUserError] = useState(null)
  const [stepDataForm, setStepDataForm] = useState(initialForm)
  const [formOptions, setFormOptions] = useState([])
  const [bajaModal, setBajaModal] = useState(false)
  const [employee, setEmployee] = useState(null)
  const [loading, setLoading] = useState(false)
  const [available, setAvailable] = useState(false);
  const [roles, setRoles] = useState([
    { value: 0, label: "Seleccionar" },
    { value: 2, label: "Recursos Humanos" },
    { value: 3, label: "Director/a" },
    { value: 4, label: "Gerente H/M" },
    { value: 5, label: "Psicólogo/a" },
    { value: 6, label: "Director/a RRHH" },
    { value: 7, label: "Gerente H/M RRHH" },

  ])
  //Variables de validacion de error
  const [roleError, setRoleError] = useState(true)
  const [areaError, setAreaError] = useState(false)
  const [costCenterError, setCostCenterError] = useState(false)
  const [sectorError, setSectorError] = useState(false)

  //Variables de mensaje de error
  const [roleErrorMessage, setRoleErrorMessage] = useState('')
  const [areaErrorMessage, setAreaErrorMessage] = useState('')
  const [costCenterErrorMessage, setCostCenterErrorMessage] = useState('')
  const [sectorErrorMessage, setSectorErrorMessage] = useState('')
  const columns = React.useMemo(
    () => [
      {
        Header: "NOMBRE",
        accessor: "username",
        disableFilters: true,
      },
      {
        Header: "AREA",
        accessor: "area",
        disableFilters: true,
      },
      {
        Header: "CENTRO DE COSTOS",
        accessor: "cost_center",
        disableFilters: true,
      },
      {
        Header: "EMAIL",
        accessor: "email",
        disableFilters: true,
      // },
      // {
      //   Header: "ELIMINAR USUARIOS BALANZ",
      //   accessor: "delete",
      //   disableFilters: true,
      //   Cell: ({ row }) => (
      //     <button
      //       onClick={() => {
      //         setUpdateUser(row);
      //         displayModal(1);
      //       }}
      //     >
      //       ELIMINAR
      //     </button>
      //   ),
      // },
      // {
      //   Header: "Asignar como GERENTE",
      //   accessor: "upgrade",
      //   disableFilters: true,
      //   Cell: ({ row }) => (
      //     <button
      //       className={row.original.role_id === 6 ? "" : "d-none"}
      //       onClick={() => {
      //         setUpdateUser(row);
      //         displayModal(2);
      //       }}
      //     >
      //       {row.original.role_id === 6 ? "HABILITAR" : "DESHABILITAR"}
      //     </button>
      //   ),
      },
    ],
    []
  );

  function getSearches() {
    // @todocambiar la url de la API para q traiga solo busquedas para aprobar
    API.get("/positions/approve").then(function (response) {
      if (response.data.error === 0) {
        setData(response.data.data);
      } else {
        //todo
      }
    });
  }
  async function getEmployessByEmail (searchUser, setLoading) { //API para traer los empleados por email
    await API.post(`/employees/people/email/${searchUser}`)
    .then(function (response) {
      if (response.status !== 200 || response.data.error === 1) {
        setSearchUserError('Ocurrió un error al obtener los datos, por favor intente nuevamente')
        setStep(1)
      } else {
        setInfo(response.data.data);
        setFormOptions(response.data.dummyData.formOptions);
      }
    }) .catch (function (error) {
      if(error.response.status === 404 || error.response.status === 500){
        setSearchUserError(error.response.data.message)
        setStep(1)

        if (error.response.data.hasOwnProperty('refresh') && error.response.data.refresh) {
          getUsers();
        }
      }
      else{
        setSearchUserError('Ocurrió un error al obtener los datos, por favor intente nuevamente')
        setStep(1)
      }
    });
    setLoading(false)
  }
  const sendForm = async (stepDataForm) => { //API para enviar el formulario
    setLoading(true)
    await API.post('/employees/people/alta', stepDataForm)
      .then(function (response) {
        if (response.status !== 200 || response.data.error === 1) {
          setSearchUserError('Ocurrió un error al obtener los datos, por favor intente nuevamente')
          setStep(1)
        } else {
         setStep(3)
        }
      });
      setLoading(false)

  }
  const sendFormDelete = async (stepDataForDelete) => { //API para enviar el formulario
    setLoading(true)
    await API.post('/employees/people/remover', stepDataForDelete)
      .then(function (response) {
        if (response.status !== 200 || response.data.error === 1) {
          setSearchUserError('Ocurrió un error al obtener los datos, por favor intente nuevamente')
          setStep(1)
        } else {
         setStep(3)
        }
      })
     .catch (function (error) {
      if(error.response.status === 404 || error.response.status === 500){
        setSearchUserError('No se encontró el usuario')
        setStep(1)
      }
      else{
        setSearchUserError('Ocurrió un error al obtener los datos, por favor intente nuevamente')
        setStep(1)
      }
     })
      setLoading(false)
      
  } 
  const setInfo = (item) => {
   if(item){
    setAvailable(true)
    setEmployee(item)
    setStepDataForm({
      idPersona: item.idPersona,
      Nombre: item.Nombre,
      Apellido: item.Apellido,
      email: item.email,
      Documento: item.Documento,
      role_id: 0,
      area:0,
      cost_center:0,
      sector:0,
      position:''
    })
    setStepDataForDelete({
      idPersona: item.idPersona,
      email: item.email,
    })
   }
    else {
      setAvailable(true)
      setEmployee([])
    }
    

  }
  useEffect(() => {
    if(!available){
      setEmployee([])
    }
  }, [available]);

  const handleChange = (value,name, label) => {
    setStepDataForm({
      ...stepDataForm,
      [name]: value
    })
    if(label){
      setStepDataForm({
        ...stepDataForm,
        'position': label,
        [name]: value
      })
    }
  }
  const checkEmpty = () => {
   let count = 0
   if(stepDataForm.role_id === 0) {
    count++
    setRoleError(true)
    setRoleErrorMessage('Seleccione un rol')
  }
  else {
    setRoleError(false)
    setRoleErrorMessage('')
  }
  if(stepDataForm.area === 0){
    count++
    setAreaError(true)
    setAreaErrorMessage('Seleccione un area')
  }
  else {
    setAreaError(false)
    setAreaErrorMessage('')
  }
  if(stepDataForm.cost_center === 0){
    count++
    setCostCenterError(true)
    setCostCenterErrorMessage('Seleccione un centro de costo')
  }
  else {
    setCostCenterError(false)
    setCostCenterErrorMessage('')
  }
  if(stepDataForm.sector === 0){
    count++
    setSectorError(true)
    setSectorErrorMessage('Seleccione un sector')
  }
  else {
    setSectorError(false)
    setSectorErrorMessage('')
  }

  return count
  }
  const stepForm = (e) => {
    e.preventDefault();
    setSearchUserError(null)
    switch(step){
      case 1:
        if(searchUser === ''){
          setSearchUserError('Debe colocar un correo de usuario')
          return;
        }
        else {
          setLoading(true)
          getEmployessByEmail(searchUser, setLoading)
          setStep(2)
        }

      break;
      case 2:
        if(checkEmpty() === 0){
          sendForm(stepDataForm)
          setStep(3)
        }
        else {
          return
        }
      break;
      default:
      break;
    }
  }
  const stepFormToUnsubscribe  = (e) => {
    e.preventDefault();
    setSearchUserError(null)
    switch(step){
      case 1:
        if(searchUser === ''){
          setSearchUserError('Debe colocar un correo de usuario')
          return;
        }
        else {
          setLoading(true)
          getEmployessByEmail(searchUser, setLoading)
          setStep(2)
        }
      break;
      case 2:
        sendFormDelete(stepDataForDelete)
        setStep(3)
      break;
      default:
      break;
    }
  }

  useEffect(() => {
    getUsers();

  }, []);

  const getUsers = () => {
    API.get("/employees/all").then(function (response) {
      if (response.data.error === 0) {
        setEmployees(response.data.data);
      } else {
        //todo
      }
    });
  };

  return (
    <>
      <Header />
      <Menu />
      <div className="content-candidatos">
        <div className="contenido">
          <div className="content-table">
            <Table columns={columns} data={employees} setAltaModal={setAltaModal} setBajaModal={setBajaModal}  />
          </div>
        </div>
      </div>
      <Footer />
    {/* Alta de los usuario */}
    <Modal
        size="lg"
        show={altaModal}
        onHide={() => {
          setAltaModal(false);
          setStep(1);
          setSearchUser('')
          setSearchUserError(null)
        }}
        aria-labelledby="example-modal-sizes-title-lg"
        className='modal_edit_applicant'
        // aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header className="modal-header" closeButton>

        </Modal.Header>
        <Modal.Body>
          {step === 1 && (
            <form onSubmit={stepForm}>
              <Row>
                <Col xs="12">
                  <p className="text-center">
                    Para comenzar, ingrese el email de Balanz de la persona a <br />
                    dar de alta en la plataforma People.
                  </p>
                </Col>
                <Col xs="12">
                  <div className="content-datos">
                      <input
                        name="search_user"
                        className="input-search"
                        type="text"
                        value={searchUser}
                        onChange={(e) => {
                          setSearchUser(e.target.value)
                        }}
                      />
                  </div>
                </Col>
                <Col xs="12" className="botones text-center">
                  <button 
                  type="submit" 
                  className="search-button mx-auto"
                  id="search"
                  >Buscar</button>
                </Col>

                <Col xs="12">
                  <div className={`alert alert-danger text-center mt-3 modal-alert-error ${searchUserError !== null ? "" : "d-none"}`}>{searchUserError}</div>
                </Col>
              </Row>
            </form>
          )}

          {step === 2 && loading && (
            <LoadingList />
          )}     
          {step === 2  && !loading && (
            <form onSubmit={stepForm}>
              <Row>
              <Col xs="12" sm="4" className='text-center text-sm-left'>
                  <div>
                      <b className='d-block mb-1'>Nombre</b>
                      <p>{employee.Nombre} {employee.Apellido}</p>
                  </div>
                </Col>

                <Col xs="12" sm="4" className='text-center text-sm-left'>
                  <div>
                      <b className='d-block mb-1'>Email</b>
                      <p>{employee.email}</p>
                  </div>
                </Col>
                <Col xs="12" sm="4" className='text-center text-sm-left'>
                  <div>
                      <b className='d-block mb-1'>documento</b>
                      <p><p>{employee.Documento}</p></p>
                  </div>
                </Col>
                <Col xs="12">
                    <p className="mt-4 mb-3 text-center parrafo-alta-user">Complete los siguientes datos para dar de alta al usuario</p>
                </Col>
                </Row>
              <Row className="modal-step2-form">
                <Col md="6" className='content-datos mb-3'>
                    <span className='d-block mb-1'><b>Rol</b></span>
                    <select name="role_id" 
                    onChange={(e) => handleChange(e.target.value, e.target.name, e.target.options[e.target.selectedIndex].dataset.value )}> 
                    
                      {
                        roles.map((item) => {
                          return (
                            <option value={item.value} data-value={item.label} >{item.label}</option>
                          )
                        })
                      }
                    </select>
                    <div className={`mb-2 mt-1 warning-message  ${roleError === true ? "" : "d-none"}`}>{roleErrorMessage}</div>
                </Col>
                <Col md="6" className='content-datos mb-3'>
                    <span className='d-block mb-1'><b>Área</b></span>
                    <select name="area" onChange={(e) => handleChange(e.target.value, e.target.name)}>
                    <option value={0}>Seleccionar</option>
                        {
                          formOptions.Area.map((item,index) => {
                            return (
                              <option value={item.name}>{item.name}</option>
                            )
                          })
                        }
                    </select>
                    <div className={`mb-2 mt-1 warning-message  ${areaError === true ? "" : "d-none"}`}>
                    {areaErrorMessage}
                    </div>
                </Col>

                <Col md="6" className='content-datos mb-3'>
                    <span className='d-block mb-1'><b>Centro de costos</b></span>
                    <select name="cost_center" onChange={(e) => handleChange(e.target.value, e.target.name)}>
                    <option value={0}>Seleccionar</option>
                        {
                          formOptions.CentroDeCostos.map((item,index) => {
                            return (
                              <>
                              <option
                              value={item.name}>
                                {item.name}
                                </option>
                              </>
                            )
                          })
                        }
                    </select>
                    <div className={`mb-2 mt-1 warning-message  ${costCenterError === true ? "" : "d-none"}`}>
                    {costCenterErrorMessage}
                    </div>
                </Col>


                <Col md="6" className='content-datos mb-3'>
                    <span className='d-block mb-1'><b>Sector</b></span>
                    <select name="sector" onChange={(e) => handleChange(e.target.value, e.target.name)}>
                    <option value={0}>Seleccionar</option>
                        {
                          formOptions.Sector.map((item,index) => {
                            return (
                              <option value={item.name}>{item.name}</option>
                            )
                          })
                        }
                    </select>
                    <div className={`mb-2 mt-1 warning-message  ${sectorError === true ? "" : "d-none"}`}>
                    {sectorErrorMessage}
                    </div>
                </Col>

                <Col xs={{ span: 6, offset: 6 }} className='content-datos'>
                  <div className="botones step-modal-actions">

                    <button
                      className='d-flex align-items-center justify-content-center ml-0'
                      type="button"
                      onClick={() => {
                        setStep(1)
                      }}>
                      <img src={derecha} className="mr-2" alt="" />
                      Volver
                    </button>
                    <button 
                    type="submit" 
                    className="search-button mx-auto"
                    id="search"
                    
                    >
                      Confirmar
                    </button>
                  </div>
                </Col>
              </Row>
            </form>
          )}

          
          {step === 3 && loading && (
              <LoadingList />
            )}     
            {step === 3  && !loading && (
            <Row>
              <Col xs="12">
                <div className='text-center mt-4'>
                    <p>El usuario <strong>{searchUser}</strong> fue dado de alta <br /> exitosamente</p>
                </div>
              </Col>

              <Col xs="12" className="botones text-center">
                <button type="submit" onClick={() => {
                  setAltaModal(false);
                  setStep(1)
                  getUsers();
                  setSearchUser('');
                }} className="btn-blue mx-auto">Cerrar</button>
              </Col>
              <Col xs="12" className='text-center'>
                <Button onClick={() => {
                  setStep(1)
                  setSearchUser('')
                }} variant="link">Dar de alta otro usuario</Button>
              </Col>
            </Row>
          )}


        </Modal.Body>
      </Modal>

      {/* Baja de los usuarios*/}
      <Modal
        size="lg"
        show={bajaModal}
        onHide={() => {
          setBajaModal(false);
          setStep(1);
          setSearchUser('');
          setSearchUserError(null)
        }}
        aria-labelledby="example-modal-sizes-title-lg"
        className='modal_edit_applicant'
      >
        <Modal.Header className="modal-header" closeButton>
        </Modal.Header>
        <Modal.Body>
          {step === 1 && (
              <form onSubmit={stepFormToUnsubscribe}>
                <Row>
                  <Col xs="12">
                    <p className="text-center">
                    Ingresar email del usuario que quiere eliminar
                    </p>
                  </Col>
                  <Col xs="12">
                    <p className="content-datos">
                      <input
                        name="search_user"
                        className="input-search"
                        type="text"
                        value={searchUser}
                        onChange={(e) => {
                          setSearchUser(e.target.value)
                        }}
                      />
                    </p>
                  </Col>
                  <Col xs="12" className="botones text-center">
                  <button 
                  type="submit" 
                  className="search-button mx-auto"
                  id="search"
                  // Aqui va la funcion que busca el usuario
                  >
                    Buscar
                  </button>
                </Col>
                <Col xs="12">
                  <div className={`alert alert-danger text-center mt-3 modal-alert-error ${searchUserError !== null ? "" : "d-none"}`}>{searchUserError}</div>
                </Col>
                </Row>
              </form> 
          )}
          
            {step === 2 && loading && (
              <LoadingList />
            )}     
            {step === 2  && !loading && (
              <form onSubmit={stepFormToUnsubscribe}>
                <Row>
                <Col xs="12">
                  <p className="text-center">
                    ¿Está seguro que desea eliminar al usuario <br />
                    {employee.email}?
                  </p>
                </Col>
                <Col xs={{ span: 4, offset: 4 }} className='content-datos'>
                  <div className="botones step-modal-actions">

                    <button
                      className='d-flex align-items-center justify-content-center ml-0'
                      type="button"
                      onClick={() => {
                        setStep(1)
                      }}>
                      <img src={derecha} className="mr-2" alt="" />
                      Volver
                    </button>
                    <button 
                    type="submit" 
                    className="search-button mx-auto"
                    id="search"
                    onClick={() => {}}
                    >
                      Confirmar
                    </button>
                  </div>
                </Col>
              </Row>
              </form>
            )
          }
          {step === 3 && loading && (
              <LoadingList />
            )}     
            {step === 3  && !loading && (
              <Row>
                <Col xs="12">
                  <p className="text-center">
                    El usuario {employee.email} fue dado de baja <br /> 
                    exitosamente
                  </p>
                </Col>
                <Col xs="12" className="botones text-center">
                <button type="submit" onClick={() => {
                  setBajaModal(false);
                  setStep(1)
                  getUsers();
                  setSearchUser('');
                }} className="btn-blue mx-auto">Cerrar</button>
              </Col>
              </Row>
            )
          }
        </Modal.Body>
      </Modal>
              
      {/* <Modal
        // size="sm"
        show={showModal > 0 && true}
        onHide={() => {
          displayModal(0);
          setUpdateUser("");
        }}
        className="modal_check_edit_applicant"
        // aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header className="modal-header" closeButton></Modal.Header>
        <Modal.Body>
          <Modal.Title className="titulo">
            {showModal === 1 &&
              "¿Estás seguro que deseas Eliminar a esta persona?"}
            {showModal === 2 &&
              `¿Estás seguro que deseas ${
                updateUser.original.role_id === 6 ? "Habilitar" : "Deshabilitar"
              } a esta persona como Gerente?`}
          </Modal.Title>
          <Row className="content-buttons">
            {showModal === 1 ? (
              <button
                id="guardar"
                onClick={() => {
                  API.post("/employees/delete/" + updateUser.original.id)
                    .then(function (response) {
                      window.location.reload();
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
              >
                ACEPTAR
              </button>
            ) : showModal === 2 ? (
              <button
                id="guardar"
                onClick={() => {
                  API.post("/employees/him/add/" + updateUser.original.id)
                    .then(function (response) {
                      window.location.reload();
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }}
              >
                ACEPTAR
              </button>
            ) : (
              ""
            )}

            <button
              onClick={() => {
                displayModal(0);
                setUpdateUser("");
              }}
              id="cancelar"
            >
              CANCELAR
            </button>
          </Row>
        </Modal.Body>
      </Modal> */}
    </>
  );
};

export default ABMUsers;
