import React from "react";
import { Modal, Button } from "react-bootstrap";
import editar from "../../../../../images/menu/editar.png";

const EditModules = ({ editionModeDate, showModalDate, setshowModalDate, buttonStyle,handleChange,acceptedDate,errorDate,date}) => {
    const handleCloseDate = () => setshowModalDate(false);
  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModalDate}
        onHide={handleCloseDate}
        className="modal-edit"
      >
        <Modal.Header className="modal-edit-header" closeButton />

        <Modal.Body>
          <div className="edit-modal-content">
            <div className="barra-superior">
                  <div className="left">
                      <div className="item">
                          <span>Fecha</span>
                          <input type="date" onChange={handleChange} value={acceptedDate}  />
                         
                      </div>
                  </div>
              </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={handleCloseDate}>
            Cancelar
          </Button>
          <Button onClick={editionModeDate}>
            Guardar Cambios
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="edit-button-date">
        <button id="edit" style={buttonStyle} onClick={() => setshowModalDate(true)}>
          <img style={{ margin: "0 0 0 0" }} src={editar} alt="pen" /> 
        </button>
      </div>
    </>
  );
};

export default EditModules;
