
import moment from "moment"
import { useEffect, useRef, useState } from "react"
// import { Steps, Panel, ButtonGroup, Button} from "rsuite"

import { API } from "../../../../http-common"
import HeaderPublic from "../../../includes/Header/HeaderPublic"
import './completeRegister.css'
import { Step, Steps } from "react-step-builder"
import {Tooltip,OverlayTrigger} from 'react-bootstrap'
let OptionsList = require("../OptionsList.json");
const CompleteRegister = (req) => {

    // Ref
    const file = useRef('');
    const searchParam = req.location.search;
    const token = new URLSearchParams(searchParam).get('token');

    // variables to get parameters

    const hash = req.match.params.hash;
    const [candidate_id, setCandidateId] = useState(req.match.params.candidate_id)
    const [application_id, setApplicationId] = useState(req.match.params.application_id)

    const [step1, setStep1] = useState(true)
    const [step2, setStep2] = useState(false)


    // CONSTANTS TO FORM
    const [id, setId] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')

    //nombre apellido y numero de contacto de emergencia

    const [emergencyLastName, setEmergencyLastName] = useState('')
    const [emergencyFirstName, setEmergencyFirstName] = useState('')
    const [emergencyPhoneNumber, setEmergencyPhoneNumber] = useState('')

    const [title, setTitle] = useState('')
    const [dni, setDni] = useState('')
    const [university, setUniversity] = useState("") 
    const [universityCode, setUniversityCode] = useState("")
    const [universityLabel, setUniversityLabel] = useState("");
    const [email, setEmail] = useState('')
    const [experience, setExperience] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [lastJobTitle, setLastJobTitle] = useState('')
    const [birthday, setBirthday] = useState('')
    const [personInCharge, setPersonInCharge] = useState('')
    const [education, setEducation] = useState('')

   
    const [cuit, setCuit] = useState('')
    const [maritalStatus, setMaritalStatus] = useState('')
    const [country, setCountry] = useState('')
    const [province, setProvince] = useState('')
    const [city, setCity] = useState('')
    const [street, setStreet] = useState('')
    const [number, setNumber] = useState('')
    const [zipCode, setZipCode] = useState('')
    const [apartment, setApartment] = useState('')
    const [noFloor, setNoFloor] = useState('')
    const [betweenStreets, setBetweenStreets] = useState('')
    const [selectedFile, setSelectedFile] = useState('');
    const [nameFile, setNameFile] = useState('')
    const [avatar, setAvatar] = useState(null)
    const [isLoading, setIsLoading] = useState(true);
    const [search, setSearch] = useState([])
    const [message, setMessage] = useState('Cargando...')
    const [isSentData, setIsSentData] = useState(false)

    const [countriesOptions, setCountriesOptions] = useState([])
    const [maritalStatesOptions, setMaritalStatesOptions] = useState([])

    const [edit, setEdit] = useState(true)

    const [checkedInfo, setCheckedinfo] = useState(null)


    const [firstNameError, setFirstNameError] = useState(false)
    const [lastNameError, setLastNameError] = useState(false)

    const [emergencyFirstNameError, seteEmergencyFirstNameError] = useState(false)
    const [emergencyLastNameError, setEmergencyLastNameError] = useState(false)
    const [emergencyPhoneNumberError, setEmergencyPhoneNumberError] = useState(false)


    const [titleError, setTitleError] = useState(false)
    const [dniError, setDniError] = useState(false)
    const [universityError, setUniversityError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [experienceError, setExperienceError] = useState(false)
    const [phoneNumberError, setPhoneNumberError] = useState(false)
    const [lastJobTitleError, setLastJobTitleError] = useState(false)
    const [birthdayError, setBirthdayError] = useState(false)
    const [personInChargeError, setPersonInChargeError] = useState(false)
    const [educationError, setEducationError] = useState(false)

    const [cuitError, setCuitError] = useState('')
    const [maritalStatusError, setMaritalStatusError] = useState('')
    const [countryError, setCountryError] = useState('')
    const [provinceError, setProvinceError] = useState('')
    const [cityError, setCityError] = useState('')
    const [streetError, setStreetError] = useState('')
    const [numberError, setNumberError] = useState('')
    const [zipCodeError, setZipCodeError] = useState('')
    const [apartmentError, setApartmentError] = useState('')
    const [noFloorError, setNoFloorError] = useState('')
    const [betweenStreetsError, setBetweenStreetsError] = useState('')
    const [selectedFileError, setSelectedFileError] = useState('');
    const [nameFileError, setNameFileError] = useState('')
    const [avatarError, setAvatarError] = useState(null)
    //...........................................................


    const [firstNameWarning, setFirstNameWarning] = useState('')
    const [lastNameWarning, setLastNameWarning] = useState('')

    const [emergencyFirstNameWarning, setEmergencyFirstNameWarning] = useState('')
    const [emergencyLastNameWarning, setEmergencyLastNameWarning] = useState('')
    const [emergencyPhoneNumberWarning, setEmergencyPhoneNumberWarning] = useState('')

    const [titleWarning, setTitleWarning] = useState('')
    const [dniWarning, setDniWarning] = useState('')
    const [universityWarning, setUniversityWarning] = useState('')
    const [emailWarning, setEmailWarning] = useState('')
    const [experienceWarning, setExperienceWarning] = useState('')
    const [phoneNumberWarning, setPhoneNumberWarning] = useState('')
    const [lastJobTitleWarning, setLastJobTitleWarning] = useState('')
    const [birthdayWarning, setBirthdayWarning] = useState('')
    const [personInChargeWarning, setPersonInChargeWarning] = useState('')
    const [educationWarning, setEducationWarning] = useState('')

    const [cuitWarning, setCuitWarning] = useState('')
    const [maritalStatusWarning, setMaritalStatusWarning] = useState('')
    const [countryWarning, setCountryWarning] = useState('')
    const [provinceWarning, setProvinceWarning] = useState('')
    const [cityWarning, setCityWarning] = useState('')
    const [streetWarning, setStreetWarning] = useState('')
    const [numberWarning, setNumberWarning] = useState('')
    const [zipCodeWarning, setZipCodeWarning] = useState('')
    const [apartmentWarning, setApartmentWarning] = useState('')
    const [noFloorWarning, setNoFloorWarning] = useState('')
    const [betweenStreetsWarning, setBetweenStreetsWarning] = useState('')
    const [selectedFileWarning, setSelectedFileWarning] = useState('');
    const [nameFileWarning, setNameFileWarning] = useState('')
    const [avatarWarning, setAvatarWarning] = useState('')
    const [listUniversity,setListUniversity] = useState([])

    const regExDate = /^(?:(?:(?:0?[1-9]|1\d|2[0-8])[/](?:0?[1-9]|1[0-2])|(?:29|30)[/](?:0?[13-9]|1[0-2])|31[/](?:0?[13578]|1[02]))[/](?:0{2,3}[1-9]|0{1,2}[1-9]\d|0?[1-9]\d{2}|[1-9]\d{3})|29[/]0?2[/](?:\d{1,2}(?:0[48]|[2468][048]|[13579][26])|(?:0?[48]|[13579][26]|[2468][048])00))$/
    const regExLetters = /^[A-Z a-z ÁÉÍÓÚáéíóúñÑ]+$/;
    const regExEmail = /\S+@\S+\.\S+/

    const formInitial = [{
        'application_id': application_id,
        'candidate_id': candidate_id,
        'cuit': cuit,
        'maritalStatus': maritalStatus,
        'country': country,
        'province': province,
        'city': city,
        'street': street,
        'number': number,
        'zipCode': zipCode,
        'apartment': apartment,
        'noFloor': noFloor,
        'betweenStreets': betweenStreets,
        'selectedFile': selectedFile,
        'emergencyFirstName': emergencyFirstName,
        'emergencyLastName': emergencyLastName,
        'emergencyPhoneNumber': emergencyPhoneNumber,
        'university': university,
        'universityCode':universityCode
        
    }]

    const [formData, setFormData] = useState(formInitial)
    const [formDataPreviousData, setFormDataPreviousData] = useState([])
    const checkvalue = (nameInput, value) => {
        if ((value === '' || /^[0-9\b]+$/.test(value)) && nameInput === 'cuit') {
            setCuit(value)
        } else if (nameInput === 'maritalStatus') {
            setMaritalStatus(value)
        } else if (nameInput === 'country') {
            setCountry(value)
        } else if (nameInput === 'province') {
            setProvince(value)
        } else if (nameInput === 'city') {
            setCity(value)
        } else if (nameInput === 'street') {
            setStreet(value)
        } else if ((value === '' || /^[0-9\b]+$/.test(value)) && nameInput === 'number') {
            setNumber(value)
        } else if (nameInput === 'zipCode') {
            setZipCode(value)
        } else if (nameInput === 'apartment') {
            setApartment(value)
        } else if (nameInput === 'noFloor') {
            setNoFloor(value)
        } else if (nameInput === 'betweenStreets') {
            setBetweenStreets(value)
        }

        else if(nameInput === 'emergencyFirstName') {
            setEmergencyFirstName(value)
        }
        else if(nameInput === 'emergencyLastName') {
            setEmergencyLastName(value)
        }
        else if(nameInput === 'emergencyPhoneNumber') {
            setEmergencyPhoneNumber(value)
        }

        else if (nameInput === 'selectedFile') {
            setSelectedFile(value)
            setNameFile(value.name)
            setAvatar(URL.createObjectURL(value))
        }
        updateFormData(nameInput, value)
    }

    const checkvaluePrevious = (nameInput, value, label) => {
        if ((value === '' || /^[0-9\b]+$/.test(value)) && nameInput === 'dni') {
            setDni(value)
        } else if ((value === '' || /^[0-9\b]+$/.test(value)) && nameInput === 'experience') {
            setExperience(value)
        } else if ((value === '' || /^[0-9\b]+$/.test(value)) && nameInput === 'phoneNumber') {
            setPhoneNumber(value)
        } else if (nameInput === 'email') {
            setEmail(value)
        } else if (nameInput === 'firstName') {
            setFirstName(value)
        } else if (nameInput === 'lastName') {
            setLastName(value)
        }
        else if (nameInput === 'birthday') {
            setBirthday(value)
        } else if ((value === '' || /^[0-9\b]+$/.test(value)) && nameInput === 'personInCharge') {
            setPersonInCharge(value)
        } else if (nameInput === 'title') {
            setTitle(value)
        } else if (nameInput === 'university') {
            setUniversityCode(value)
            setUniversity(label)
        } else if (nameInput === 'lastJobTitle') {
            setLastJobTitle(value)
        } else if (nameInput === 'education') {
            setEducation(value)
        }
        updateFormDataPrevious(nameInput, value)
    }

    const updateFormData = (nameInput, value) => {
        const newForm = formData.map((item) => {
            if (nameInput === 'birthday') {
                const updatedItem = { ...item, [nameInput]: moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD'), }
                return updatedItem
            } else {
                const updatedItem = { ...item, [nameInput]: value, }
                return updatedItem
            }
        });
        setFormData(newForm);
    }
    const getListUniversity = () => {
        try {
          API.post("/candidates/university/public").then(function (response) {
            if (response.data.error === 0) {
              let list = response.data.data
              setListUniversity(list)
            }
          });
        }
        catch {
          setListUniversity([])
         throw new Error("Error al cargar la lista de universidades")
        }
      }
    const updateFormDataPrevious = (nameInput, value) => {
        const newForm = formDataPreviousData.map((item) => {
            if (nameInput === 'birthday') {
                const updatedItem = { ...item, [nameInput]: moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD'), }
                return updatedItem
            } else {
                const updatedItem = { ...item, [nameInput]: value, }
                return updatedItem
            }
        });
        setFormDataPreviousData(newForm);
    }

    const checkEmptyPrevData = () => {
        let flag = 0;

        if (firstName.length === 0) {
            flag++
            setFirstNameWarning('El campo no puede estar vacío')
        } else if (!regExLetters.test(firstName)) {
            flag++
            setFirstNameWarning('Ingrese solo letras')
        } else {
            setFirstNameWarning('')
        }

        if (lastName.length === 0) {
            flag++
            setLastNameWarning('El campo no puede estar vacío')
        } else if (!regExLetters.test(lastName)) {
            flag++
            setLastNameWarning('Ingrese solo letras')
        } else {
            setLastNameWarning('')
        }

        if (title.length === 0) {
            flag++
            setTitleWarning('El campo no puede estar vacío')
        } else if (title.length > 0 && title.length <= 4) {
            flag++
            setTitleWarning('Debe tener mas de 4 caracteres')
        } else {
            setTitleWarning('')
        }

        if (dni.length === 0) {
            flag++
            setDniWarning('El campo no puede estar vacío')
        }
        else if(dni.length > 0 && dni.length <= 5) {
            flag++
            setTitleWarning('Debe tener mas de 5 caracteres')
        }
        else if(dni[0] === "0") {
            flag++
            setTitleWarning('El primer caracter debe ser distinto de 0')
        }
        else {
            setDniWarning('')
        }

        if (university.length === 0) {
            flag++
            setUniversityWarning('El campo no puede estar vacío')
        } else {
            setUniversityWarning('')
        }

        if (email.length === 0) {
            flag++
            setEmailWarning('El campo no puede estar vacío')
        } else if (!regExEmail.test(email)) {
            flag++
            setEmailWarning('Ingrese un correo valido')
        } else {
            setEmailWarning('')
        }

        if (experience.length === 0) {
            flag++
            setExperienceWarning('El campo no puede estar vacío')
        } else if (experience.length > 2) {
            flag++
            setExperienceWarning('Ingrese una cantidad permitida')
        } else {
            setExperienceWarning('')
        }

        if (phoneNumber.length === 0) {
            flag++
            setPhoneNumberWarning('El campo no puede estar vacío')
        } else if (phoneNumber.length <= 5) {
            flag++
            setPhoneNumberWarning('Ingrese un numero valido')
        } else {
            setPhoneNumberWarning('')
        }

        if (lastJobTitle.length === 0) {
            flag++
            setLastJobTitleWarning('El campo no puede estar vacío')
        } else if (lastJobTitle.length > 0 && lastJobTitle.length < 3) {
            flag++
            setLastJobTitleWarning('Ingrese más caracteres')
        } else {
            setLastJobTitleWarning('')
        }

        if (birthday.length === 0) {
            flag++
            setBirthdayWarning('El campo no puede estar vacío')
        } else if (!regExDate.test(birthday)) {
            flag++
            setBirthdayWarning('Ingrese el formato correcto de fecha')
        } else {
            setBirthdayWarning('')
        }

        if (personInCharge.length === 0) {
            flag++
            setPersonInChargeWarning('El campo no puede estar vacío')
        } else {
            setPersonInChargeWarning('')
        }

        if (education.length === 0) {
            flag++
            setEducationWarning('El campo no puede estar vacío')
        } else {
            setEducationWarning('')
        }

        return flag
    }

    const checkEmpty = () => {
        let flag = 0;

        if (firstName.length === 0) {
            flag++
            setFirstNameWarning('El campo no puede estar vacío')
        } else if (!regExLetters.test(firstName)) {
            flag++
            setFirstNameWarning('Ingrese solo letras')
        } else {
            setFirstNameWarning('')
        }

        if (lastName.length === 0) {
            flag++

            setLastNameWarning('El campo no puede estar vacío')
        } else if (!regExLetters.test(lastName)) {
            flag++
            setLastNameWarning('Ingrese solo letras')
        } else {
            setLastNameWarning('')
        }

        if (emergencyFirstName.length === 0) {
            flag++
            setEmergencyFirstNameWarning('El campo no puede estar vacío')
        } else if (!regExLetters.test(firstName)) {
            flag++
            setEmergencyFirstNameWarning('Ingrese solo letras')
        } else {
            setEmergencyFirstNameWarning('')
        }

        if (emergencyLastName.length === 0) {
            flag++
            setEmergencyLastNameWarning('El campo no puede estar vacío')
        } else if (!regExLetters.test(emergencyLastName)) {
            flag++
            setEmergencyLastNameWarning('Ingrese solo letras')
        } else {
            setEmergencyLastNameWarning('')
        }


        if (title.length === 0) {
            flag++
            setTitleWarning('El campo no puede estar vacío')
        } else if (title.length > 0 && title.length <= 4) {
            flag++
            setTitleWarning('Debe tener mas de 4 caracteres')
        } else {
            setTitleWarning('')
        }

        if (dni.length === 0) {
            flag++
            setDniWarning('El campo no puede estar vacío')
        }
        else if(dni.length > 0 && dni.length <= 5) {
            flag++
            setTitleWarning('Debe tener mas de 5 caracteres')
        }
        else if(dni[0] === "0"){
            flag++
            setTitleWarning('El primer caracter debe ser distinto de 0')
        }
        else {
            setDniWarning('')
        }

        if (university.length === 0) {
            flag++
            setUniversityWarning('El campo no puede estar vacío')
        } else {
            setUniversityWarning('')
        }

        if (email.length === 0) {
            flag++
            setEmailWarning('El campo no puede estar vacío')
        } else if (!regExEmail.test(email)) {
            flag++
            setEmailWarning('Ingrese un correo valido')
        } else {
            setEmailWarning('')
        }

        if (experience.length === 0) {
            flag++
            setExperienceWarning('El campo no puede estar vacío')
        } else if (experience.length > 2) {
            flag++
            setExperienceWarning('Ingrese una cantidad permitida')
        } else {
            setExperienceWarning('')
        }

        if (phoneNumber.length === 0) {
            flag++
            setPhoneNumberWarning('El campo no puede estar vacío')
        } else if (phoneNumber.length <= 5) {
            flag++
            setPhoneNumberWarning('Ingrese un numero valido')
        } else {
            setPhoneNumberWarning('')
        }

        if (emergencyPhoneNumber.length === 0) {
            flag++
            setEmergencyPhoneNumberWarning('El campo no puede estar vacío')
        } else if (emergencyPhoneNumber.length <= 5) {
            flag++
            setEmergencyPhoneNumberWarning('Ingrese un numero valido')
        } else {
            setEmergencyPhoneNumberWarning('')
        }

        if (lastJobTitle.length === 0) {
            flag++
            setLastJobTitleWarning('El campo no puede estar vacío')
        } else if (lastJobTitle.length > 0 && lastJobTitle.length < 3) {
            flag++
            setLastJobTitleWarning('Ingrese más caracteres')
        } else {
            setLastJobTitleWarning('')
        }

        if (birthday.length === 0) {
            flag++
            setBirthdayWarning('El campo no puede estar vacío')
        } else if (!regExDate.test(birthday)) {
            flag++
            setBirthdayWarning('Ingrese el formato correcto de fecha')
        } else {
            setBirthdayWarning('')
        }

        if (personInCharge.length === 0) {
            flag++
            setPersonInChargeWarning('El campo no puede estar vacío')
        } else {
            setPersonInChargeWarning('')
        }

        if (education.length === 0) {
            flag++
            setEducationWarning('El campo no puede estar vacío')
        } else {
            setEducationWarning('')
        }
        if (country.length === 0) {
            flag++
            setCountryError(true)
            setCountryWarning('El campo no puede estar vacío')
        } else {
            setCountryError(false)
            setCountryWarning('')
        }
        const isCuitEmpty = cuit.length === 0;
        const isCuitLessThan11 = cuit.length < 11;
        const isCountryArgentina = country === '1011';

        if (isCountryArgentina) { 
            if (isCuitEmpty || isCuitLessThan11) {
                flag++;
                setCuitError(true);
                setCuitWarning('El campo no puede estar vacío');
            } else {
                setCuitError(false);
                setCuitWarning('');
            }
        } else {
            setCuitError(false); 
            setCuitWarning('');
        }
        if (maritalStatus.length === 0) {
            flag++
            setMaritalStatusError(true)
            setMaritalStatusWarning('El campo no puede estar vacío')
        } else {
            setMaritalStatusError(false)
            setMaritalStatusWarning('')
        }

        if (province.length === 0) {
            flag++
            setProvinceError(true)
            setProvinceWarning('El campo no puede estar vacío')
        } else {
            setProvinceError(false)
            setProvinceWarning('')
        }

        if (city.length === 0) {
            flag++
            setCityError(true)
            setCityWarning('El campo no puede estar vacío')
        } else {
            setCityError(false)
            setCityWarning('')
        }

        if (street.length === 0) {
            flag++
            setStreetError(true)
            setStreetWarning('El campo no puede estar vacío')
        } else {
            setStreetError(false)
            setStreetWarning('')
        }

        if (number.length === 0) {
            flag++
            setNumberError(true)
            setNumberWarning('El campo no puede estar vacío')
        } else {
            setNumberError(false)
            setNumberWarning('')
        }

        if (selectedFile.length === 0) {
            flag++
            setSelectedFileError(true)
            setSelectedFileWarning('El campo no puede estar vacío')
        } else {
            setSelectedFileError(false)
            setSelectedFileWarning('')
        }

        return flag
    }

    const getCheck = () => {
        setIsLoading(true)
        const request_api = (null === token) ? `/applications/public/recruitment-form/${application_id}/${candidate_id}/${hash}` : `/applications/public/recruitment-form?token=${token}`
        API.get(request_api)
            .then(res => {
                setCandidateId(res.data.candidate_data.candidate_id)
                setApplicationId(res.data.search_id)
                setCheckedinfo(false)
                setIsLoading(false)
                setMaritalStatesOptions(res.data.formOptions.EstadoCivil)
                setCountriesOptions(res.data.formOptions.Pais)
                getDataPreviousCandidate(res.data.candidate_data.candidate_id)
            })
            .catch(err => {
                if (err.response.status === 403) {
                    setCheckedinfo(true)
                    setMessage(<><span>Lo sentimos. </span><br />Ya has suministrado tu información anteriormente.</>);
                    setIsLoading(false)
                } else if (err.response.status === 404) {
                    setCheckedinfo(true)
                    setMessage(<><span>Lo sentimos. </span><br />El link al que ingresaste es erroneo.</>);
                    setIsLoading(false)
                }
                else if (err.response.status === 400) {
                    setCheckedinfo(true)
                    setMessage(<><span>Lo sentimos. </span><br />No puedes volver a completar el formulario.</>);
                    setIsLoading(false)
                }
            })

    }

    const getDataPreviousCandidate = (candidate_id) => {
        API.get('/candidates/public/' + candidate_id)
            .then(function (response) {
                if (response.data.error === 0) {
                    setId(response.data.data.id)
                    // setName(response.data.data.username)
                    if (response.data.data.first_name === null) {
                        setFirstName('')
                    } else {
                        setFirstName(response.data.data.first_name)
                    }
                    if (response.data.data.last_name === null) {
                        setLastName('')
                    } else {
                        setLastName(response.data.data.last_name)
                    }

                    setTitle(response.data.data.title)
                    setDni(response.data.data.dni)
                    setUniversityCode(response.data.data.university_code)
                    setUniversity(response.data.data.university)
                    setEmail(response.data.data.email)
                    setExperience(response.data.data.experience)
                    setPhoneNumber(response.data.data.phone_number)
                    setLastJobTitle(response.data.data.last_job_title)
                    setPersonInCharge(response.data.data.person_in_charge)
                    setEducation(response.data.data.education)
                    let date = new Date(response.data.data.birthday)
                    let birthday = ((date.getUTCDate() < 10) ? '0' + date.getUTCDate() : date.getUTCDate()) + '/' + (date.getUTCMonth() + 1) + "/" + date.getUTCFullYear()
                    setBirthday(birthday)

                    const formInitialPreviousData = [{
                        "id": response.data.data.id,
                        'name': response.data.data.username,
                        'firstName': response.data.data.first_name,
                        'lastName': response.data.data.last_name,
                        'title': response.data.data.title,
                        'dni': response.data.data.dni,
                        'universityCode': response.data.data.university_code,
                        'university': response.data.data.university,
                        'email': response.data.data.email,
                        'experience': response.data.data.experience,
                        'phoneNumber': response.data.data.phone_number,
                        'lastJobTitle': response.data.data.last_job_title,
                        'birthday': birthday,
                        'personInCharge': response.data.data.person_in_charge,
                        'education': response.data.data.education,
                        'status': response.data.data.status,
                        'outstanding': response.data.data.outstanding,
                    }]
                    setFormDataPreviousData(formInitialPreviousData)
                }
            })
    }
    const sendData = async (e) => {
        const form = new FormData()
        formData[0].university = university
        formData[0].universityCode = universityCode
        if (selectedFile !== '') {
            const ext = formData[0].selectedFile.name.substr(formData[0].selectedFile.name.lastIndexOf('.'));
            const fileName = (`CRM_AVATAR_${application_id}_${candidate_id}${ext}`.split(' ').join('_')).normalize('NFD').replace(/[\u0300-\u036f]/g, "");
            form.append('selectedFile', formData[0].selectedFile, fileName)

            formData[0].selectedFile = fileName
        }

        API.post('/applications/file', form, { timeout: 60000 })
            .then(response => {
                let msg = '';
                if (response.data.error === 0) {
                    API.put('/applications/public/recruitment-form'+(token === null ? '' : '?token='+token), formData[0], { timeout: 60000 })
                        .then(response => {
                            setIsSentData(true)
                            if (response.data.error === 0) {
                                msg = <><span>Los datos se han enviado correctamente. </span><br />Muchas gracias por tu tiempo.</>
                            } else {
                                msg = <><span>Lo sentimos.</span><br /> Ha ocurrido un error en el envío. Por favor Intentar nuevamente mas tarde.</>
                            }
                            setMessage(msg)
                        })
                        .catch(error => {
                            msg = <><span>Lo sentimos.</span><br /> Ha ocurrido un error en el envío. Por favor Intentar nuevamente mas tarde.</>
                            setMessage(msg)
                            setIsSentData(true)
                        })
                } else {
                    //todo
                    msg = <><span>Lo sentimos.</span><br /> Ha ocurrido un error en el envío. Por favor Intentar nuevamente mas tarde.</>
                }
                setMessage(msg)
                setIsSentData(true)
            })
            .catch(error => {
                let msg = <><span>Lo sentimos.</span><br /> El formato de archivo no está permitido (formatos permitidos jpg|jpeg|png|gif|txt|doc|docx|pdf).</>;
                setMessage(msg)
                setIsSentData(true)
            })
    }

    const handleClick = e => file.current.click()

    const handleSubmit = e => {
        e.preventDefault()
        if (checkEmpty() === 0) {
            sendData()
        }
    }

    const handleSubmitPreviousData = (e) => {
        e.preventDefault()
        if (checkEmptyPrevData() === 0) {
            sendPreviousData()
        }
    }

    const sendPreviousData = (props) => {
        formDataPreviousData[0].birthday = moment(birthday, 'DD/MM/YYYY').format('YYYY-MM-DD')
        formDataPreviousData[0].name = `${firstName} ${lastName}`
        formDataPreviousData[0].university = university
        formDataPreviousData[0].universityCode = universityCode
        API.put('/candidates/update_candidate/public', formDataPreviousData[0])
            .then(res => {
                setEdit(true)
                setStep1(false)
                setStep2(true)
            })
            .catch(error => { })
    }

    const initialStateData = () => {
        setEdit(true)
        getDataPreviousCandidate(candidate_id)
    }

    useEffect(() => {
        getCheck()
        getListUniversity()
    }, [])
    const tooltip = (
        <Tooltip id="tooltip" className="custom-tooltip">
            Solo números. 
            <br/>
            Requisito válido solo para Argentina.
        </Tooltip>
    )
    return (
        <>
            <HeaderPublic />
            {(checkedInfo || isLoading || isSentData) ? (
                <div className="content-formulario aplicant_external">
                    <div className="formulario newapplicant">
                        <span className="Complete" style={{ margin: '25% auto', textAlign: 'center' }}>{message}</span>
                    </div>
                </div>
            ) : (
                <div className="content-formulario-completar aplicant_external">
                    <div className="formulario newapplicant">
                        <span className="Complete">Verifique sus datos y complete el siguiente formulario<br></br><span>para completar tu registro</span></span>
                        <br />
                        {step1 && (
                            <div className="content-datos seccion-previa">
                                <h3 className='titulo'>Verifique su información</h3>
                                <div className="content-linea">
                                    <div className="input-long">
                                        <label className='title'>Nombre</label>
                                        <input name='firstName' value={firstName} disabled={edit} type="text" id="primer_nombre" onChange={(e) => checkvaluePrevious(e.target.name, e.target.value)} placeholder="Nombre" />
                                        <div className={`alert alert-danger mt-3 ${firstNameWarning ? "" : "d-none"}`}>{firstNameWarning}</div>
                                    </div>
                                    <div className="input-long">
                                        <label className='title'>Apellido</label>
                                        <input name='lastName' value={lastName} disabled={edit} type="text" id="primer_apellido" onChange={(e) => checkvaluePrevious(e.target.name, e.target.value)} placeholder="Apellido" />
                                        <div className={`alert alert-danger mt-3 ${lastNameWarning ? "" : "d-none"}`}>{lastNameWarning}</div>
                                    </div>
                                </div>
                                <div className="content-linea">
                                <div className="input-long">
                                        <label className='title'>Educación</label>
                                         <select
                                           style={{ marginTop: "5px" }}
                                           name="education"
                                           value={education}
                                           id="education"
                                           className="select-universidad"
                                           onChange={(e) =>
                                            checkvaluePrevious(e.target.name, e.target.value)
                                            }
                                           disabled={edit}
                                         > 
                                                {OptionsList.Grado.map((element, key) => {
                                                    return (
                                                        <option value={key === 0 ? "" : element} key={key}>
                                                            {element}
                                                        </option>
                                                    );
                                                })}
                                        </select>
                                        
                                        <div className={`alert alert-danger mt-3 ${universityWarning ? "" : "d-none"}`}>{universityWarning}</div>
                                    </div>
                                    <div className="input-long">
                                        <label className='title'>DNI</label>
                                        <input name='dni' value={dni} type="text" disabled={edit} id="dni" onChange={(e) => checkvaluePrevious(e.target.name, e.target.value)} placeholder="DNI" />
                                        <div className={`alert alert-danger mt-3 ${dniWarning ? "" : "d-none"}`}>{dniWarning}</div>
                                    </div>

                                </div>
                                <div className="content-linea">
                                    <div className="input-long">
                                        <label className='title'>Título</label>
                                        <input name='title' value={title} disabled={edit} type="text" id="titulo" onChange={(e) => checkvaluePrevious(e.target.name, e.target.value)} placeholder="Título" />
                                        <div className={`alert alert-danger mt-3 ${titleWarning ? "" : "d-none"}`}>{titleWarning}</div>
                                    </div>
                                    <div className="input-long">
                                        <label className='title'>E-mail</label>
                                        <input name='email' value={email} disabled={edit} type="text" id="mail" onChange={(e) => checkvaluePrevious(e.target.name, e.target.value)} placeholder="E-mail" />
                                        <div className={`alert alert-danger mt-3 ${emailWarning ? "" : "d-none"}`}>{emailWarning}</div>
                                    </div>
                                </div>
                                <div className="content-linea">
                                    <div className="input-long">
                                        <label className='title'>Universidad</label>
                                         <select
                                           style={{ marginTop: "5px" }}
                                           name="university"
                                           value={university}
                                           id="universidad"
                                           className="select-universidad"
                                           onChange={(e) => checkvaluePrevious(e.target.name, e.target.value, e.target.options[e.target.selectedIndex].dataset.value)}
                                           disabled={edit}
                                         > <>
                                         {
                                             <option value={universityCode} data-value={university}>
                                            {
                                               university
                                            }
                                             </option>
                                            }
                                          {
                                           listUniversity.map((element, key) => {
                                             return (
                                               <option value={element.value}  key={element.value} data-value={element.name}>
                                                 {element.name}
                                               </option>
                                             )})}
                                         </>
                                        </select>
                                        
                                        <div className={`alert alert-danger mt-3 ${universityWarning ? "" : "d-none"}`}>{universityWarning}</div>
                                    </div>
                                    
                                    <div className="input-long">
                                        <label className='title'>Teléfono</label>
                                        <input name='phoneNumber' value={phoneNumber} disabled={edit} type="text" id="telefono" onChange={(e) => checkvaluePrevious(e.target.name, e.target.value)} placeholder="Teléfono" />
                                        <div className={`alert alert-danger mt-3 ${phoneNumberWarning ? "" : "d-none"}`}>{phoneNumberWarning}</div>
                                    </div>
                                </div>
                                <div className="content-linea">
                                    <div className="input-long">
                                        <label className='title'>Nombre de la última posición</label>
                                        <input name='lastJobTitle' value={lastJobTitle} disabled={edit} type="text" id="ultima-pocision" onChange={(e) => checkvaluePrevious(e.target.name, e.target.value)} placeholder="Nombre de última posicion" />
                                        <div className={`alert alert-danger mt-3 ${lastJobTitleWarning ? "" : "d-none"}`}>{lastJobTitleWarning}</div>
                                    </div>
                                    <div className="input-long">
                                        <label className='title'>Fecha de nacimiento (dd/mm/aaaa)</label>
                                        <input name='birthday' value={birthday} disabled={edit} type="text" id="fecha-nac" onChange={(e) => checkvaluePrevious(e.target.name, e.target.value)} placeholder="Fecha de nacimiento (dd/mm/aaaa)" />
                                        <div className={`alert alert-danger mt-3 ${birthdayWarning ? "" : "d-none"}`}>{birthdayWarning}</div>
                                    </div>
                                </div>
                                <div className="content-linea">
                                    <div className="input-long">
                                        <label className='title'>Años de experiencia</label>
                                        <input name='experience' value={experience} disabled={edit} type="text" id="experiencia" onChange={(e) => checkvaluePrevious(e.target.name, e.target.value)} placeholder="Años de experiencia" />
                                        <div className={`alert alert-danger mt-3 ${experienceWarning ? "" : "d-none"}`}>{experienceWarning}</div>
                                    </div>
                                    <div className="input-long">
                                        <label className='title'>Gente a cargo</label>
                                        <input name='personInCharge' value={personInCharge} disabled={edit} type="text" id="a-cargo" onChange={(e) => checkvaluePrevious(e.target.name, e.target.value)} placeholder="Gente a cargo" />
                                        <div className={`alert alert-danger mt-3 ${personInChargeWarning ? "" : "d-none"}`}>{personInChargeWarning}</div>
                                    </div>
                                </div>
                                <div className="content-linea">
                                    <div className="inputbuttons">
                                        {edit ? (
                                            <div className='button_edit' onClick={() => setEdit(false)}>EDITAR</div>
                                        ) : (
                                            <div className='button_cancel' onClick={() => initialStateData()}>CANCELAR</div>
                                        )}
                                        <div className='button_checked' onClick={e => {
                                            handleSubmitPreviousData(e)
                                            // props.next()
                                        }}>CONFIRMAR</div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {step2 && (
                            <div className="content-datos">
                                <h3 className='titulo'>Complete los datos</h3>
                                <div className="content-linea">
                                    <div className="input-long">
                                        <OverlayTrigger placement="top" overlay={tooltip}> 
                                        <label className='title'>CUIT <span className='thin'><strong>(?)</strong></span></label>
                                        </OverlayTrigger>
                                        <input name='cuit' value={cuit} type="text" onChange={(e) => checkvalue(e.target.name, e.target.value)} placeholder="CUIT" />
                                       
                                        <div className={`alert alert-danger mt-3 ${cuitError ? "" : "d-none"}`}>{cuitWarning}</div>
                                    </div>
                                    <div className="input-long">
                                        <label className='title'>Estado civil</label>
                                        <select name='maritalStatus' defaultValue='' value={maritalStatus} onChange={(e) => checkvalue(e.target.name, e.target.value)} >
                                            <option value=''>Seleccionar</option>
                                            {maritalStatesOptions.map(item => (
                                                <option key={`${item.name}_${item.value}`} value={item.value}>{item.name}</option>
                                            ))}
                                        </select>
                                        <div className={`alert alert-danger mt-3 ${maritalStatusError ? "" : "d-none"}`}>{maritalStatusWarning}</div>
                                    </div>
                                </div>
                                <div className="content-linea">
                                    <div className="input-error">
                                        <label className='title'>País de residencia</label>
                                        <select name='country' defaultValue='' value={country} onChange={(e) => checkvalue(e.target.name, e.target.value)} >
                                            <option value=''>Seleccionar</option>
                                            {countriesOptions.map(item => (
                                                <option key={`${item.name}_${item.value}`} value={item.value}>{item.name}</option>
                                            ))}
                                        </select>
                                        <div className={`alert alert-danger mt-3 ${countryError ? "" : "d-none"}`}>{countryWarning}</div>
                                    </div>
                                    <div className="input-error">
                                        <label className='title'>Provincia</label>
                                        <input name='province' value={province} type="text" onChange={(e) => checkvalue(e.target.name, e.target.value)} placeholder="Provincia" />
                                        <div className={`alert alert-danger mt-3 ${provinceError ? "" : "d-none"}`}>{provinceWarning}</div>
                                    </div>
                                    <div className="input-error">
                                        <label className='title'>Ciudad</label>
                                        <input name='city' value={city} type="text" onChange={(e) => checkvalue(e.target.name, e.target.value)} placeholder="Ciudad" />
                                        <div className={`alert alert-danger mt-3 ${cityError ? "" : "d-none"}`}>{cityWarning}</div>
                                    </div>
                                </div>
                                <div className="content-linea">
                                    <div className="input-error">
                                        <label className='title'>Calle</label>
                                        <input name='street' value={street} type="text" onChange={(e) => checkvalue(e.target.name, e.target.value)} placeholder="Calle" />
                                        <div className={`alert alert-danger mt-3 ${streetError ? "" : "d-none"}`}>{streetWarning}</div>
                                    </div>
                                    <div className="input-error">
                                        <label className='title'>Número</label>
                                        <input name='number' value={number} type="text" onChange={(e) => checkvalue(e.target.name, e.target.value)} placeholder="Número" />
                                        <div className={`alert alert-danger mt-3 ${numberError ? "" : "d-none"}`}>{numberWarning}</div>
                                    </div>
                                    <div className="input-error">
                                        <label className='title'>Código postal</label>
                                        <input name='zipCode' value={zipCode} type="text" onChange={(e) => checkvalue(e.target.name, e.target.value)} placeholder="Código Postal" />
                                        <div className={`alert alert-danger mt-3 ${zipCodeError ? "" : "d-none"}`}>{zipCodeWarning}</div>
                                    </div>
                                </div>
                                <div className="content-linea">
                                    <div className="input-error">
                                        <label className='title'>Departamento</label>
                                        <input name='apartment' value={apartment} type="text" onChange={(e) => checkvalue(e.target.name, e.target.value)} placeholder="Departamento" />
                                        <div className={`alert alert-danger mt-3 ${apartmentError ? "" : "d-none"}`}>{apartmentWarning}</div>
                                    </div>
                                    <div className="input-error">
                                        <label className='title'>Piso</label>
                                        <input name='noFloor' value={noFloor} type="text" onChange={(e) => checkvalue(e.target.name, e.target.value)} placeholder="Piso" />
                                        <div className={`alert alert-danger mt-3 ${noFloorError ? "" : "d-none"}`}>{noFloorWarning}</div>
                                    </div>
                                    <div className="input-error">
                                        <label className='title'>Entre calles</label>
                                        <input name='betweenStreets' value={betweenStreets} type="text" onChange={(e) => checkvalue(e.target.name, e.target.value)} placeholder="Entre Calles" />
                                        <div className={`alert alert-danger mt-3 ${betweenStreetsError == 1 ? "" : "d-none"}`}>{betweenStreetsWarning}</div>
                                    </div>
                                </div>
                                <div className="content-linea">
                                    <div className="input-error">
                                        <label className='title'>Nombre de contacto de emergencia</label>
                                        <input name='emergencyFirstName' value={emergencyFirstName} type="text" id="primer_nombre_emergencia" onChange={(e) => checkvalue(e.target.name, e.target.value)} placeholder="Nombre de emergencia" />
                                        <div className={`alert alert-danger mt-3 ${emergencyFirstNameWarning ? "" : "d-none"}`}>{emergencyFirstNameWarning}</div>
                                    </div>
                                    <div className="input-error">
                                        <label className='title'>Apellido de contacto de emergencia</label>
                                        <input name='emergencyLastName' value={emergencyLastName} type="text" id="primer_apellido_emergencia" onChange={(e) => checkvalue(e.target.name, e.target.value)} placeholder="Apellido de emergencia" />
                                        <div className={`alert alert-danger mt-3 ${emergencyLastNameWarning ? "" : "d-none"}`}>{emergencyLastNameWarning}</div>
                                    </div>
                                    <div className="input-error">
                                        <label className='title'>Número de contacto de emergencia</label>
                                        <input name='emergencyPhoneNumber' value={emergencyPhoneNumber} type="text" onChange={(e) => checkvalue(e.target.name, e.target.value)} placeholder="Número de emergencia" />
                                        <div className={`alert alert-danger mt-3 ${emergencyPhoneNumberWarning ? "" : "d-none"}`}>{emergencyPhoneNumberWarning}</div>
                                    </div>
                                </div>
                                <div className="content-image">
                                    <div className="input image">
                                        <label className='title'>Fotografía</label>
                                        <div className='line'>
                                            <input accept=".jpg,.jpeg,.png" className='d-none' ref={file} name='selectedFile' type="file" onChange={(e) => checkvalue(e.target.name, e.target.files[0])} />
                                            <div onClick={(e) => handleClick()} className="button_file">Seleccionar</div>
                                            <span className='name_file'>{nameFile.length > 17 ? `${nameFile.substr(0, 33 - 3)}...` : nameFile}</span>
                                        </div>
                                        <img className={avatar ? 'view_image' : ''} src={avatar} alt='' />
                                        <div className={`alert alert-danger mt-3 ${selectedFileError ? "" : "d-none"}`}>{selectedFileWarning}</div>
                                        <p className="text-danger pb-4 mr-0">La foto debe ser tipo carnet.</p>
                                    </div>
                                    <div className="input">
                                        <div className='button_back' onClick={() => {setStep2(false); setStep1(true)} }>REGRESAR</div>
                                        <div className='button_send' onClick={e => handleSubmit(e)}>ENVIAR</div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    )
}

export default CompleteRegister