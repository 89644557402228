
const Footer = () => {
  return (
    <>
      <div className="modal fade" id="destacar" tabIndex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            </div>
            <div className="modal-body ">
              <span className="texto-modal">¿Estas seguro que deseas destacar este candidato?</span>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" id="ok-destacar" data-dismiss="modal">Ok</button>
              <button type="button" className="btn btn-primary" id="cancel-destacar" data-dismiss="modal">Cancelar</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="general" tabIndex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            </div>
            <div className="modal-body ">
              <span className="texto-general">¿Estas seguro que deseas destacar este candidato?</span>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" id="ok_general" data-dismiss="modal">Ok</button>
              <button type="button" className="btn btn-primary" id="cancel" data-dismiss="modal">Cancelar</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="aprobar-busqueda" tabIndex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            </div>
            <div className="modal-body ">
              <span className="texto-general">¿Estas seguro que deseas aprobar esta búsqueda?</span>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" id="ok_aprobar" data-dismiss="modal">Ok</button>
              <button type="button" className="btn btn-primary" id="cancel" data-dismiss="modal">Cancelar</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="negar-busqueda" tabIndex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            </div>
            <div className="modal-body ">
              <span className="texto-general">¿Estas seguro que deseas rechazar esta búsqueda?</span>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" id="ok_negar" data-dismiss="modal">Ok</button>
              <button type="button" className="btn btn-primary" id="cancel" data-dismiss="modal">Cancelar</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="avanza-busqueda" tabIndex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            </div>
            <div className="modal-body ">
              <span className="texto-general">¿Estas seguro de avanzar con esta búsqueda?</span>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" id="ok_avanza" data-dismiss="modal">Ok</button>
              <button type="button" className="btn btn-primary" id="cancel" data-dismiss="modal">Cancelar</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="guarda-busqueda" tabIndex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            </div>
            <div className="modal-body ">
              <span className="texto-general">¿Estas seguro que deseas guardar esta búsqueda?</span>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" id="ok_guarda" data-dismiss="modal">Ok</button>
              <button type="button" className="btn btn-primary" id="cancel" data-dismiss="modal">Cancelar</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="no-avanza-busqueda" tabIndex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            </div>
            <div className="modal-body ">
              <span className="texto-general">¿Estas seguro que deseas rechazar esta búsqueda?</span>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" id="ok_no_avanza" data-dismiss="modal">Ok</button>
              <button type="button" className="btn btn-primary" id="cancel" data-dismiss="modal">Cancelar</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade" id="decidir-busqueda" tabIndex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            </div>
            <div className="modal-body ">
              <span className="texto-general">¿Estas seguro que deseas decidir mas tarde?</span>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" id="ok_dicidir" data-dismiss="modal">Ok</button>
              <button type="button" className="btn btn-primary" id="cancel" data-dismiss="modal">Cancelar</button>
            </div>
          </div>
        </div>
      </div>

      
    </>
  )
}

export default Footer