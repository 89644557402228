import axios from "axios";
import { API_URL, TOKEN_KEY } from "./constants.js";


export const API = axios.create({
  baseURL: `${API_URL}`,
  timeout: 120000,
  headers: {
    "Content-type": "application/json",
  },
});

export function setToken(token) {
  localStorage.setItem(TOKEN_KEY, token);
}

export function getToken() {
  return localStorage.getItem(TOKEN_KEY);
}


export async function deleteToken() {
  try {
    
    
    await API.post(`/auth/logout`);
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem('TimelineTableStateId');
    localStorage.removeItem('SearchesTableStateId');
    localStorage.removeItem('ApplicantsTableStateId');
    localStorage.removeItem('UsersTableStateId');
  } catch (error) {
    console.error(error);
    throw error; 
  }
}
export async function initAxiosInterceptors() {
  API.interceptors.request.use((config) => {
    const token = getToken();

    if (token) {
      config.headers.Authorization = `bearer ${token}`;
    }

    return config;
  });

  API.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response.status === 401) {
          await deleteToken(); 
          window.location = "/login";
      } else {
        return Promise.reject(error);
      }
    }
  );
}
