import { useContext, useEffect, useState } from "react"
import {  Modal } from "react-bootstrap"
import { API } from "../../../../http-common"
import '../applicants.css'
import ApplicantEvaluation from "../../TimeLineRRHH/ShowApplicants/ApplicantEvaluation/ApplicantEvaluation"
import * as Constants from "../../../../constants";
import { UserContext} from "../../../../context/userContext";
import $ from "jquery";
import LoadingList from "../../Loading/LoadingList"
import {Col, Container, Row} from "react-bootstrap";
import Select from 'react-select'


const ModalProcessApplicant = ({candidateId, applicationId, profileId, idSearch, data, show, onHide, setModalProcessShow, onDataChange = null}) => {
  const { userData } = useContext(UserContext)
const [perfilSelected, setPerfilSelected] = useState([])
const [perfilSelectedTaking,setPerfilSelectedTaking] = useState([])
const [perfilSelected2, setPerfilSelected2] = useState([])
const [candidateApplicant, setCandidateApplicant] = useState([])

//   const optionsSnackWellDone = {
//     style: {
//       backgroundColor: '#0078c6',
//       color: 'white',
//       borderRadius: '10px',
//       fontSize: '18px',
//       textAlign: 'center',
//     },
//     closeStyle: {
//       fontSize: '16px',
//     },
//     }

//   const optionsSnackError = {
//     style: {
//         backgroundColor: '#c60000',
//         color: 'white',
//         borderRadius: '10px',
//         fontSize: '18px',
//         textAlign: 'center',
//     },
//     closeStyle: {
//         fontSize: '16px',
//     },
//     }

  const [disableEdit, setDisableEdit] = useState(false);
  const [info, setInfo] = useState([])
  const [loading, setLoading] = useState(true);
  const [lazyloading, setlazyloading] = useState(true);
  const [errorFTP, setErrorFTP] = useState(true);
  const [feedback, setFeedback] = useState('')
  const getApplicant = async () => {
    await API.get('/applications/timeline/' + applicationId)
    .then(function (response) {
      if(response.status !== 200 || response.data.error === 1){
        setErrorFTP(false)
      }
      else {
          // status detail id = 93  Es reasignado a otra búsqueda
          setDisableEdit(
            ![2, 3].includes(response.data.data.application.status_id) || response.data.data.application.status_detail_id === 93
          );
  
          setInfo(response.data.data)
          handleFeedback(response.data.data)
      }
    })
    .catch((err) => {
      if(err.response.status === 404 || err.response.status === 500){
        setErrorFTP(false)
      }
      else {
        setErrorFTP(false)
      }
    });
    setLoading(false);
  }
  //Feedback del primer modulo
  const handleFeedback = (info) => {
    let datamodule = info.modules = info.modules.map((module) => {
      return module
    });
    setFeedback(datamodule[0].data.feedback)
  }
  //DATOS PARA EL PROFILE SELECT
  const getCandidateById = async (candidateId) => {
    let success = true;
    try{
      const res = await API.get(`/candidates/${candidateId}`)
      if(res.data.error !== 0){
        throw new Error(res.data.message)
      }
      setCandidateApplicant(res.data.data)
      const dataCandidate = res.data.data
      const resListProfiles = await  API.get("/candidates/profiles")
      if(resListProfiles.data.error !== 0){
        throw new Error(resListProfiles.data.message)
      }
      let perfilesData = []
      const profileList = resListProfiles.data.data
      perfilesData = profileList.map(i =>{
        return {value: i.id, label: i.profile_detail}
      })
     
      setPerfilSelected(perfilesData)
      const profile = profileList.find((x) => x.id === dataCandidate.profile_id )
      if(profile){
        setPerfilSelected2({value: profile.id, label: profile.profile_detail})
      } else {
        setPerfilSelected2({value: 0, label: "Selecionar un perfil"})
      }
      setlazyloading(false)

    }
    catch(e){
      console.log(e)
    }

  } 
  
  const selectChange = (e) => {
    setPerfilSelectedTaking(e.value)
  }


  const handleSaveForLater = () => {
    API.post(
      "applications/timeline/" + info.application.id + "/save-for-later",{
        profile_id:perfilSelectedTaking
      })
      .then((response) => {
        onDataChange()
        setModalProcessShow(false)
        // setDataTableChanged(true);
      })
      .catch();
    // setAvailable(false);
  }
  
  const handleOutstand = () => {
    API.post(
      "applications/timeline/" + info.application.id + "/highlight/",{highlight: 1}
    )
      .then((response) => {
        onDataChange()
        setModalProcessShow(false)
        // setDataTableChanged(true);
      })
      .catch();
    // setAvailable(false);
  };

  const handleDeclane = () => {
    API.post("/applications/timeline/"+info.application.id+"/discard", {
      id_search: idSearch,
      id_user: info.application.candidate_id,
    })
      .then((response) => {
        onDataChange()
        setModalProcessShow(false)
        // setDataTableChanged(true);
      })
      .catch();
    // setAvailable(false);
  };
  useEffect(() => {
    getCandidateById(candidateId)
   
  }, []);
  useEffect(() => {
    getApplicant()
  }, [])
  return (
    <>
      <Modal
        size="xl"
        show={show}
        onHide={onHide}
        backdrop={'static'}
        aria-labelledby="example-modal-sizes-title-lg"
        className='modal_edit_applicant'
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body className='content-edit'>
          <Modal.Title className='titulo'>
            Proceso de aplicante
          </Modal.Title>


          {loading && <LoadingList />}

          {!loading && errorFTP && (
            <>
            {(info.modules && info.application.status_id !== 1) ? (
              <ApplicantEvaluation disableEdit={disableEdit} setModalProcessShow={setModalProcessShow} onDataChange={onDataChange} available={show} setAvailable={onHide} idSearch={idSearch} modules={info.modules} candidate={info.user}/>
            ) : (
              <div className="content-time-line">
                <div className="vista-candidato text-center">
                  {
                    feedback ? (
                      <div className="vista-candidato text-center">
                    <h5>El candidato fue guardado en Base de Datos, éste es su último feedback:</h5>
                  <div className="content-feedback text-feedback">
                  {feedback}
                    </div>
                   </div>
                    )
                    :(
               
                   <p>Todavía no hay datos para mostrar porque el candidato no fue destacado.</p>
                    )
                  }
                  
                    {Constants.RRHH.includes(userData.role_id) && (
                      <div className="content-btn-destacados justify-content-center">
                      <button
                          className="btn-database"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          type="button"
                          onClick={() => $("#database-busqueda-1").modal("show")}
                        >
                          Bases de datos
                        </button>

                        <button
                          className="btn-destacados"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          type="button"
                          onClick={() => handleOutstand()}
                        >
                          Destacar
                        </button>
                        <button
                          className="btn-rechazado"
                          data-toggle="tooltip"
                          data-placement="bottom"
                          type="button"
                          onClick={() => handleDeclane()}
                        >
                          Descartar
                        </button>
                      </div>
                    )}
                </div>
             
              </div>
            ) }
            </>
          )} 
          {
            !errorFTP && (
              <>
                   <div className="content-time-line">
                <div className="vista-candidato text-center">
                    <p>Ocurrió un error al obtener los datos, por favor intente nuevamente</p>
                </div>
              </div>
              </>
            )
          }


        </Modal.Body>
        <div
    className="modal fade"
    id="database-busqueda-1"
    tabIndex="-1"
    role="dialog"
    aria-labelledby="basicModal"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <div className="modal-header">
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-hidden="true"
          >
            &times;
          </button>
        </div>
        <div className="modal-body row">
        <Container >
                <Row className='with-line aling-items-center bsPrefix'>
                    <Col md="6" lg="6" sm="12" xs="12" >
                    <span className=""> <strong>Seleccionar un perfil para el usuario</strong></span>
                    {
                      lazyloading && (
                        <Select
                        onChange={selectChange}
                        defaultValue={perfilSelected2 ? perfilSelected2 : 'Seleccionar perfil'}
                        options={perfilSelected}
                        />
                      )
                    }
                    {
                      !lazyloading && (
                        <Select
                        onChange={selectChange}
                        defaultValue={perfilSelected2 ? perfilSelected2 : 'Seleccionar perfil'}
                        options={perfilSelected}
                        />
                      )
                    }
                       
                       
                        
                    </Col>
                </Row>
                <span className="texto-general">
                  ¿Estas seguro que deseas pasarlo a bases de datos? <br />
                  Esta acción descartará el feedback escrito
              </span>
          </Container>
          
        </div>
        <div className="modal-footer">
        <button
            type="button"
            className="btn btn-primary"
            id="database"
            data-dismiss="modal"
            onClick={() => handleSaveForLater()}
          >
            Ok
          </button>
          <button
                type="button"
                className="btn btn-primary"
                id="cancel-destacar"
                data-dismiss="modal"
              >
                Cancelar
              </button>
        </div>
      </div>
    </div>
  </div>


        {/* {showApprove &&
          <Modal
            // size="sm"
            show={showApprove}
            onHide={() => setShowApprove(false)}
            className='modal_check_edit_applicant'
            // aria-labelledby="example-modal-sizes-title-sm"
          >
            <Modal.Header className='modal-header' closeButton>
            </Modal.Header>
            <Modal.Body>
              <Modal.Title className='titulo'>
                ¿Estás seguro de realizar estos cambios?
              </Modal.Title>
              <Row className='content-buttons'>
                <button id='guardar' onClick={handleSubmit}>GUARDAR</button>
                <button onClick={() => setShowApprove(false)} id='cancelar'>CANCELAR</button>
              </Row>
            </Modal.Body>
          </Modal>
        } */}
      </Modal>

    </>
  )
}

export default ModalProcessApplicant