import moment from "moment";
import ReactExport from "react-export-excel";
import download from '../../../../images/menu/download.png'
import '../report.css'
import optionsList from '../../NewHunt/OptionsList.json'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExcelHunteoReport = ({data}) =>{
  data = data.map(d => {
    let country = optionsList.countries.find(option => option.code === d.country)
    if(typeof country !== 'undefined') {
      d.country = country.name
    }
    return d
  })

  return (
    <ExcelFile filename='Registro de hunteo' element={<button className='boton-descargar-excel'><img src={download} width='20px' height='20px' alt='download'/></button>}>
      <ExcelSheet data={data} name="Registro de hunteo">
        <ExcelColumn label="Nombre" value="Nombre"/>
        <ExcelColumn label="Perfil de linkedin" value="Perfil de linkedin"/>
        <ExcelColumn label="Perfil de contrato" value="Perfil de contrato"/>
        <ExcelColumn label="Empresa" value="Empresa"/>
        <ExcelColumn label="Cargo/Rol" value="Cargo/Rol"/>
        <ExcelColumn label="Estado" value="Estado"/>
        <ExcelColumn label="Primera fecha de contacto" value="Primera fecha de contacto"/>
        <ExcelColumn label="Última fecha de contacto" value="Última fecha de contacto"/>
        <ExcelColumn label="País" value="country"/>
        <ExcelColumn label="Correo electónico" value="Correo electónico"/>
        <ExcelColumn label="Teléfono" value="Teléfono"/>
        <ExcelColumn label="Notas" value="Notas"/>
      </ExcelSheet>
    </ExcelFile>
  );
}

export default ExcelHunteoReport