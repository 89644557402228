import React, { useContext, useEffect, useState } from "react";
import favorito from "../../../../../../images/menu/favorito.png";
import favorito_resaltado from "../../../../../../images/menu/resaltado.png";
import "../../../timeLineRRHH.css";
import $, { data } from "jquery";
import { DataContext } from "../../../../../../context/dataContext";
import { API } from "../../../../../../http-common";
import { UserContext } from "../../../../../../context/userContext";
import moment from "moment";
import {Col, Container, Row} from "react-bootstrap";
import Select from 'react-select'
/*Edit modules imports*/
import EditModule from "../EditModules";
import EditFeedback from "../EditFeedback";
import EditDate from "../EditDate"

const Accordion2 = ({
  available,
  setAvailable,
  idSearch,
  module,
  applicant,
  disableEdit,
  onDataChange = null
}) => {
  const { userData, setUserData } = useContext(UserContext);
  const {
    dataTableChanged,
    setDataTableChanged,
    currentSearchStatus,
    setCurrentSearchStatus,
  } = useContext(DataContext);
  const [submit, setSubmit] = useState(false);
  const [outstanding, setOutstanding] = useState(applicant.outstanding);
  const [attributes, setAttributes] = useState(applicant.attribute)
  const [attributeTaking, setAttributeTaking] = useState(0)
  const [highlighted, setHighlighted] = useState(false);
  const [feedback, setFeedback] = useState(module.data.feedback);
  const [moduleStatus, setModuleStatus] = useState(module.status);
  const [acceptedDate, setAcceptedDate] = useState(module.admission_date ? module.admission_date: null)
  const [addmissionDate, setAdmissionDate] = useState(module.admission_date)
  const [errorDate, setErrorDate] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [date, setDate] = useState(
    module.data.date !== ""
    ? moment(module.data.date, "DD/MM/YYYY").format("YYYY-MM-DD")
    : moment(new Date()).format("YYYY-MM-DD")
  );

  const [feedbackError, setFeedbackError] = useState(false);

  /*EditModal states & function*/
  const [showModal, setShowModal] = useState(false);
  const [moduleWritable, setModuleWritable] = useState(module.writable);
  const [dataModule, setDataModule] = useState(module);
  const [showModalDate, setshowModalDate] = useState(false); //Nuevo estado para editar la fecha de entrevista
  const [loading, setLoading] = useState(false)

  const handleCloseDate = () => setshowModalDate(false); //Nueva funcion para editar la fecha de entrevista

  const editionMode = () => {
    setModuleWritable(1);
    //module.writable = 1;
    setShowModal(false);
  };
  const changeAttributesValues = (attribute) => {
    let filterAttributes = attribute.filter((module) => module.module_id === 2)
     let SelectAttributes = filterAttributes.map((attribute) => {
      return {value:attribute.id_attributes   , label:attribute.attributes_name}
     })
     setAttributes(SelectAttributes)
    }
  const editionModeDate = () => { //Nueva funcion para editar la fecha de entrevista

    const dataForm = {
      search_id: idSearch,
      candidate_id: applicant.candidate_id,
      applicant_id: applicant.applicant_id,
      module_id: 1,
      employees_id: userData.id,
      feedback_date: date,
      feedback: feedback,
      accepted: null,
      link_note: null,
      admission_date: acceptedDate,
      status: 4,
    }

    setErrorDate(false)
    if(!acceptedDate){
        setErrorDate(true)
    }else{
      const module_id = dataModule.id ?? 0;

        API.post("/applications/timeline/"+applicant.applicant_id+"/module/"+module_id, dataForm)
        .then((response) => {
            setAdmissionDate(acceptedDate)
            setDataModule({
              ...dataModule,
              id: response.data.id
            })
            if(null !== onDataChange) onDataChange()
          toggleClass()

        })
        .catch()
    }
    setshowModalDate(false);

  }


  const toggleClass = () => {
    let modal = document.getElementsByClassName("modal_edit_applicant");
    if(modal.length > 0){
      setTimeout(() => {
        document.body.classList.add("modal-open");
      }, 500)
    }
  }

  //const isModal = document.getElementsByClassName("modal_edit_applicant");

  // const getCandidate = async () => {
  //   await API.get("/applications/timeline/" + applicant.applicant_id).then(
  //     function (response) {
  //       if (response.data.error === 0) {

  //         setDataModule(response.data.data.modules[1]);
  //         setFeedback(response.data.data.modules[1].data.feedback);
  //         setModuleStatus(response.data.data.modules[1].status);
  //       } else {
  //         //todo
  //       }
  //     }
  //   );
  // };

  useEffect(async () => {
    if (submit && !feedbackError) {
      sendData();
    }
    //getCandidate();
    setSubmit(false);
    changeAttributesValues(applicant.attributes)
  }, [submit]);

  const sendData = () => {
    setIsLoading(true)
    const dataForm = {
      search_id: idSearch,
      candidate_id: applicant.candidate_id,
      applicant_id: applicant.applicant_id,
      module_id: 2,
      employees_id: userData.id,
      feedback_date: date,
      feedback: feedback,
      accepted: null,
      link_note: null,
      admission_date: addmissionDate,
      status: moduleStatus, 
      attributes: moduleStatus === 2 ? attributeTaking : 0
    };

    const module_id = dataModule.id ?? 0;

   try {
    API.post("/applications/timeline/"+applicant.applicant_id+"/module/"+module_id, dataForm)
    .then((response) => {
      API.put("/candidates/toggle_approve", {
        value: outstanding,
        id_user: applicant.candidate_id,
      })
        .then((respornse) => {
          //  setDataTableChanged(true)
          toggleClass()
        })
        .catch();
      setModuleWritable(0);
      setDataModule({
        ...dataModule,
        id: response.data.id
      })
      if(null !== onDataChange) onDataChange()
      toggleClass()
      setIsLoading(false)
    })
   }
      catch(error){
        console.log(error)
        setIsLoading(false)
      }
  };

  const handleChange = e => {
    const { value } = e.target;
    setAcceptedDate(value)
  }

  const sendAcceptedDate = () => {
    const dataForm = {
      search_id: idSearch,
      candidate_id: applicant.candidate_id,
      applicant_id: applicant.applicant_id,
      module_id: 2,
      employees_id: userData.id,
      feedback_date: date,
      feedback: feedback,
      accepted: null,
      link_note: null,
      admission_date: acceptedDate,
      status: 4,
    }
    setLoading(true)
    setErrorDate(false)
    if(!acceptedDate){
        setErrorDate(true)
    }else{
      try{
        const module_id = dataModule.id ?? 0;

        API.post("/applications/timeline/"+applicant.applicant_id+"/module/"+module_id, dataForm)
        .then((response) => {
            setAdmissionDate(acceptedDate)
            setDataModule({
              ...dataModule,
              id: response.data.id
            })
            if(null !== onDataChange) onDataChange()
          toggleClass()
          setLoading(false)
        })
      }
      catch(error){
        console.log(error)
        setLoading(false)
      }
    }
  }

  const handleInterview2 = (e) => {
    let status = e.currentTarget.id;
    switch (status) {
      case "ok_avanza":
        setModuleStatus(1);
        break;
      case "ok_no_avanza":
        setModuleStatus(2);
        break;
      case "ok_guarda":
        setModuleStatus(3);
        break;
      default:
        setModuleStatus(0);
    }
    setSubmit(true);
    checkInputs(status);
  };

  const checkInputs = (status) => {
    if (feedback.length === 0 && status !== 'ok_dicidir') {
      setFeedbackError(true);
    } else {
      setFeedbackError(false);
    }
  };

  return (
    <>
      <div className="card">
        <div className="card-header" id="headingTwo">
          <h5 className="mb-0">
            <button
              className="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              2° Entrevista
            </button>
            <span
              className="mas"
              data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              +
            </span>
          </h5>
        </div>
        {/* <div id="collapseTwo" className={1 === 1 ? "collapse" : "collapse disabled-accordion"} aria-labelledby="headingTwo" > */}
        <div
          id="collapseTwo"
          className={
            disableEdit
              ? "collapse disabled-accordion show"
              : moduleWritable === 1
              ? "collapse show"
              : "collapse disabled-accordion"
          }
          aria-labelledby="headingTwo"
        >
          {/* <div className={`card-body`} > */}
          <div
            className={`card-body ${
              disableEdit ? "disabled" : !(moduleWritable === 1) && "disabled"
            }`}
          >
            {/*//Nueva funcion para editar la fecha de entrevista */}
            {(null !== addmissionDate || module.completed === 1) && (
              <>
                    { (null !== addmissionDate || module.completed === 1) ? (
                  <>
                    {dataModule.completed === 1 &&
                      dataModule.editable === "all" && moduleWritable === 1 ? (
                        <p> Agendado para el día {moment(addmissionDate).format('DD/MM/YYYY')}  <EditDate
                        editionModeDate={editionModeDate}
                        showModalDate={showModalDate}
                        handleCloseDate={handleCloseDate}
                        setshowModalDate={setshowModalDate}
                        handleChange={handleChange}
                        acceptedDate={acceptedDate}
                        errorDate={errorDate}
                        date={date}
                        >
                        </EditDate>
                        </p>
                    ): <>

                      <p> Agendado para el día {moment(addmissionDate).format('DD/MM/YYYY')}</p>
                    </>
                    }

                  </>
                )  : <p>Sin fecha de entrevista</p>}

                <div className="barra-superior">
                  <div className="left">
                    {moduleWritable === 1 ? (
                      <div className="item">
                        <span>Fecha</span>
                        <input max={moment().format("YYYY-MM-DD")} style={{margin: '15px 0'}} type="date" value={date} onChange={(e) => {
                          setDate(e.target.value)
                        }} />
                      </div>
                    ) : (
                      <div className="item">
                        <span>Fecha</span>
                        {/*<Moment format="DD/MM/YYYY">{new Date()}</Moment>*/}
                        <label>
                          {moment(date).format("DD/MM/YYYY")}
                        </label>
                      </div>
                    )}
                    <div className="item">
                      <span>Entrevista realizada por</span>
                      <label>
                        {dataModule.data.interviewer.username != ""
                          ? dataModule.data.interviewer.username
                      : [4, 7].includes(userData.role_id)
                          ? userData.username
                          : ""}
                      </label>
                      {/*<label>{userData.username}</label>*/}
                      {/* <label>{module.data.interviewer.username}</label> */}
                    </div>
                  </div>
                  <div className="right">
                    <button
                      className={outstanding ? "highlighted" : ""}
                      onClick={() => {
                        if (outstanding === 0) {
                          setOutstanding(1);
                        } else {
                          setOutstanding(0);
                        }
                      }}
                    >
                      Resaltar Candidato{" "}
                      <img
                        src={outstanding ? favorito_resaltado : favorito}
                        alt=""
                      />
                    </button>
                  </div>
                </div>
                <div className="content-feedback">
                  <span>Feedback</span>
                  <textarea
                    id="feedback"
                    cols="30"
                    rows="10"
                    placeholder=""
                    className={feedbackError ? "input-error" : ""}
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    readOnly={moduleWritable === 0 ? true : false}
                    disabled={false}
                  ></textarea>
                  <span className={feedbackError ? "span-error" : "d-none"}>
                    Campo Vacio
                  </span>
                </div>
                {
                  // {1 === 1 &&
                  <div className="botones-vista">
                    <button
                      id="aprobar"
                      onClick={() => $("#avanza-busqueda-2").modal("show")}
                      data-toggle="modal"
                      data-target="#avanza-busqueda-2"
                       disabled={isLoading ? true : false}
                    >
                      AVANZA
                    </button>
                    <button
                      id="pausar"
                      onClick={() => $("#guardar-busqueda-2").modal("show")}
                      data-toggle="modal"
                      data-target="#guardar-busqueda-2"
                       disabled={isLoading ? true : false}
                    >
                      NO AVANZA, GUARDAR PERFIL PARA OTRA BÚSQUEDA
                    </button>
                    <button
                      id="negar"
                      onClick={() => $("#no-avanza-busqueda-2").modal("show")}
                      data-toggle="modal"
                      data-target="#no-avanza-busqueda-2"
                       disabled={isLoading ? true : false}
                    >
                      NO AVANZA
                    </button>
                    <button
                      id="decidir"
                      onClick={() => $("#decidir-busqueda-2").modal("show")}
                      data-toggle="modal"
                      data-target="#decidir-busqueda-2"
                       disabled={isLoading ? true : false}
                    >
                      DECIDIR MÁS TARDE
                    </button>
                  </div>
                }

                {(disableEdit || moduleWritable === 0) && (
                  <div className="estado">
                    {(() => {
                      switch (dataModule.status) {
                        case 0:
                          return <span className="pausado">PENDIENTE</span>;
                          break;
                        case 1:
                          return <span className="aprobado">AVANZA</span>;
                          break;
                        case 2:
                          return <span className="negado">NO AVANZA</span>;
                          break;
                        case 3:
                          return (
                            <span className="pausado">
                              GUARDADO PARA OTRA BÚSQUEDA
                            </span>
                          );
                          break;
                        default:
                          return <span className="pausado">PENDIENTE</span>;
                      }
                    })()}
                    {dataModule.completed === 1 &&
                      dataModule.editable === "feedback" && (
                        <EditFeedback
                          setModuleWritable={setModuleWritable}
                          sendData={sendData}
                          feedbackError={feedbackError}
                        ></EditFeedback>
                      )}
                    {dataModule.completed === 1 &&
                      dataModule.editable === "all" && (
                        <EditModule
                          editionMode={editionMode}
                          showModal={showModal}
                          setShowModal={setShowModal}
                        ></EditModule>
                      )}
                  </div>
                )}
              </>

            )}

            {(module.completed === 0 && null === addmissionDate) && (
              <>
                <div className="barra-superior">
                    <div className="left">
                        <div className="item">
                            <span>Fecha</span>
                            <input type="date" onChange={handleChange} value={acceptedDate} />
                            <span className={errorDate ? "span-error" : "d-none"}>
                                Campo Vacio
                            </span>
                        </div>
                    </div>
                </div>

                {
                  loading && (
                <div className="botones-vista">
                  <button onClick={sendAcceptedDate} id="aceptar" disabled={true}>Cargando...</button>
                </div>
                  )
                }
                  {
                    !loading && (
                <div className="botones-vista">
                  <button onClick={sendAcceptedDate} id="aceptar">Entrevista agendada</button>
                </div>
                    )
                  }
              </>
            )}

          </div>
        </div>
      </div>

      {/* Modal de avanza candidato */}
      <div
        className="modal fade"
        id="avanza-busqueda-2"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="basicModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body ">
              <span className="texto-general">
                ¿Estas seguro de avanzar con este candidato?
              </span>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                id="ok_avanza"
                data-dismiss="modal"
                onClick={(e) => handleInterview2(e)}
              >
                Ok
              </button>
              <button
                type="button"
                className="btn btn-primary"
                id="cancel"
                data-dismiss="modal"
                onClick={() => {
                  toggleClass()
                }}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal de avanza candidato */}
      <div
        className="modal fade"
        id="guardar-busqueda-2"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="basicModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body ">
              <span className="texto-general">
                ¿Estas seguro que deseas guardar este perfil para otra búsqueda?
              </span>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                id="ok_guarda"
                data-dismiss="modal"
                onClick={(e) => handleInterview2(e)}
              >
                Ok
              </button>
              <button
                type="button"
                className="btn btn-primary"
                id="cancel"
                data-dismiss="modal"
                onClick={() => {
                  toggleClass()
                }}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal de no avanza candidato */}
      <div
        className="modal fade"
        id="no-avanza-busqueda-2"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="basicModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body ">
            <Container >
                    <Row className='with-line aling-items-center bsPrefix'>
                        <Col md="6" lg="6" sm="12" xs="12" >
                        <span className=""> <strong>Seleccionar un motivo</strong></span>
                        {
                          loading && (
                            <Select
                            onChange={(e) => setAttributeTaking(e.value)}
                            className="select"
                            defaultValue={{value:0 , label:'Seleccionar un motivo'}}
                            options={attributes}
                          />
                          )
                        }
                        {
                          !loading && (
                            <Select
                          onChange={(e) => setAttributeTaking(e.value)}
                          className="select"
                          defaultValue={{value:0 , label:'Seleccionar un motivo'}}
                          options={attributes}
                          />
                          )
                        }



                        </Col>
                    </Row>
                    <span className="texto-general">
                      ¿Estas seguro que deseas rechazar el candidato? <br />
                     Por favor seleccione el motivo por el cual lo rechaza
                  </span>
              </Container>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                id="ok_no_avanza"
                data-dismiss="modal"
                onClick={(e) => handleInterview2(e)}
              >
                Ok
              </button>
              <button
                type="button"
                className="btn btn-primary"
                id="cancel"
                data-dismiss="modal"
                onClick={() => {
                  toggleClass()
                }}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal de decidir mas tarde */}
      <div
        className="modal fade"
        id="decidir-busqueda-2"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="basicModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body ">
              <span className="texto-general">
                ¿Estas seguro que deseas decidir mas tarde?
              </span>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                id="ok_dicidir"
                data-dismiss="modal"
                onClick={(e) => handleInterview2(e)}
              >
                Ok
              </button>
              <button
                type="button"
                className="btn btn-primary"
                id="cancel"
                data-dismiss="modal"
                onClick={() => {
                  toggleClass()
                }}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Accordion2;
