// import css from '@emotion'
import ClipLoader from "react-spinners/ClipLoader";
import FadeLoader from "react-spinners/FadeLoader";

const Loading = () => {
  const style = {
    width: '100%',
    height: '100%',
    backgroundColor: '#004b80',
    position: 'fixed',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center'
    // top: '50%', 
    // left: '50%',
    // transform: 'translate(-50%, -50%)'
  }

  return (
    <div style={style} >
      {/* <ClipLoader color='#000' loading={true} css={"position: absolute; top: 0; left: 0; right: 0; bottom: 0; margin: auto;"} size={100} /> */}
      < FadeLoader color='#fff' loading={true} css={""} height={25} width={5} radius={2} margin={2} />
    </div>
  )
}

export default Loading