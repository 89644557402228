import '../../timeLineRRHH.css'
import Accordion1 from './Accordion1/Accordion1'
import Accordion2 from './Accordion2/Accordion2'
import Accordion3 from './Accordion3/Accordion3'
import Accordion4 from './Accordion4/Accordion4'
import Accordion5 from './Accordion5/Accordion5'
import Accordion6 from './Accordion6/Accordion6'
import Accordion7 from './Accordion7/Accordion7'
import * as Constants from '../../../../../constants'
import { useContext, useEffect } from 'react'
import { UserContext} from "../../../../../context/userContext";
import {DataContext} from "../../../../../context/dataContext";
import Accordion8 from './Accordion8/Accordion8'

const ApplicantEvaluation = ({available, setAvailable, idSearch, modules, candidate, disableEdit = false, setModalProcessShow= null, onDataChange = null}) => {
  const { userData, setUserData} = useContext(UserContext)
  const {dataTableChanged, setDataTableChanged, currentSearchStatus, setCurrentSearchStatus} = useContext(DataContext);
  const role = userData.role_id;
  /*useEffect(() => {

    return () => {

    }
  }, [])*/

  
  
  /*const renderManagerSecctions = () => {
    return (
      <div>
        { (Constants.Manager.includes(role) && Constants.SEE === modules[0].visible) && <Accordion1 module={modules[0]} /> }
        { (Constants.Manager.includes(role) && Constants.SEE === modules[1].visible) ? <Accordion2 module={modules[1]}/> : <Accordion2 module={modules[1]}/>}
        { (Constants.Manager.includes(role) && Constants.SEE === modules[2].visible) ? <Accordion3 module={modules[2]}/> : <Accordion3 module={modules[2]}/>}
        { (Constants.Manager.includes(role) && Constants.SEE === modules[3].visible) ? <Accordion4 /> : <Accordion4 />}
        { (Constants.Manager.includes(role) && Constants.SEE === modules[4].visible) ? <Accordion5 module={modules[4]}/> : <Accordion5 module={modules[4]}/>}
        { (Constants.Manager.includes(role) && Constants.SEE === modules[5].visible) ? <Accordion6 module={modules[5]}/> : <Accordion6 module={modules[5]}/>}
      </div>
    )
  }

  const renderDirectorSecctions = () => {
    return (
      <div>
        { (Constants.Director.includes(role) && Constants.SEE === modules[0].visible) && <Accordion1 module={modules[0]} /> }
        { (Constants.Director.includes(role) && Constants.SEE === modules[1].visible) ? <Accordion2 module={modules[1]}/> : <Accordion2 module={modules[1]}/>}
        { (Constants.Director.includes(role) && Constants.SEE === modules[2].visible) ? <Accordion3 module={modules[2]}/> : <Accordion3 module={modules[2]}/>}
        {/!* { (Constants.Director.includes(role) && Constants.SEE === modules[3].visible) ? <Accordion4  /> : <Accordion4 />} *!/}
        { (Constants.Director.includes(role) && Constants.SEE === modules[4].visible) ? <Accordion5 module={modules[4]}/> : <Accordion5 module={modules[4]}/>}
        { (Constants.Director.includes(role) && Constants.SEE === modules[5].visible) ? <Accordion6 module={modules[5]}/> : <Accordion6 module={modules[5]}/>}
      </div>
    )
  }

  const renderRHSecctions = () => {
    return (
      <div>
        { (Constants.Analyst.includes(role) && Constants.SEE === modules[0].visible) && <Accordion1 module={modules[0]} /> }
        { (Constants.Analyst.includes(role) && Constants.SEE === modules[1].visible) ? <Accordion2 module={modules[1]}/> : <Accordion2 module={modules[1]}/>}
        { (Constants.Analyst.includes(role) && Constants.SEE === modules[2].visible) ? <Accordion3 module={modules[2]}/> : <Accordion3 module={modules[2]}/>}
        { (Constants.Analyst.includes(role) && Constants.SEE === modules[3].visible) ? <Accordion4 module={modules[3]}/> : <Accordion4 module={modules[3]}/>}
        { (Constants.Analyst.includes(role) && Constants.SEE === modules[4].visible) ? <Accordion5 module={modules[4]}/> : <Accordion5 module={modules[4]}/>}
        { (Constants.Analyst.includes(role) && Constants.SEE === modules[5].visible) ? <Accordion6 module={modules[5]}/> : <Accordion6 module={modules[5]}/>}
      </div>
    )
  }*/
  return (
    <>
      <div className="content-time-line">
        <div className="vista-candidato">

          <div id="accordion">
            { Constants.SEE === modules[0].visible && <Accordion1 setModalProcessShow={setModalProcessShow} onDataChange={onDataChange} available={available} setAvailable={setAvailable} idSearch={idSearch} module={modules[0]} applicant={candidate} disableEdit={disableEdit}/> }
            { Constants.SEE === modules[1].visible && <Accordion2 onDataChange={onDataChange} available={available} setAvailable={setAvailable} idSearch={idSearch} module={modules[1]} applicant={candidate} disableEdit={disableEdit}/> }
            { Constants.SEE === modules[2].visible && <Accordion3 onDataChange={onDataChange} available={available} setAvailable={setAvailable} idSearch={idSearch} module={modules[2]} applicant={candidate} disableEdit={disableEdit}/> }
            { Constants.SEE === modules[3].visible && <Accordion4 onDataChange={onDataChange} available={available} setAvailable={setAvailable} idSearch={idSearch} module={modules[3]} applicant={candidate} disableEdit={disableEdit}/> }
            { Constants.SEE === modules[4].visible && <Accordion5 onDataChange={onDataChange} available={available} setAvailable={setAvailable} idSearch={idSearch} module={modules[4]} applicant={candidate} disableEdit={disableEdit}/> }
            { Constants.SEE === modules[5].visible && <Accordion6 onDataChange={onDataChange} available={available} setAvailable={setAvailable} idSearch={idSearch} module={modules[5]} applicant={candidate} disableEdit={disableEdit}/> }
            { Constants.SEE === modules[6].visible && <Accordion7 onDataChange={onDataChange} available={available} setAvailable={setAvailable} idSearch={idSearch} module={modules[6]} applicant={candidate} disableEdit={disableEdit}/> }
            { Constants.SEE === modules[7].visible && <Accordion8 onDataChange={onDataChange} available={available} setAvailable={setAvailable} idSearch={idSearch} module={modules[7]} applicant={candidate} disableEdit={disableEdit}/> }
          </div>
        </div>
      </div>
    </>
  )
}
export default ApplicantEvaluation