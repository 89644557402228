import { useState } from "react"
import { Col, Modal, Row } from "react-bootstrap"
import { API } from "../../../../http-common"
import moment from "moment";
import { useSnackbar } from 'react-simple-snackbar'
import '../applicants.css'
let OptionsList = require("../../NewApplicant/OptionsList.json");
const ModalInfoApplicant = ({data, show, onHide, listUniversity, setListUniversity, listCompanies}) => {

  const optionsSnackWellDone = {
    style: {
      backgroundColor: '#0078c6',
      color: 'white',
      borderRadius: '10px',
      fontSize: '18px',
      textAlign: 'center',
    },
    closeStyle: {
      fontSize: '16px',
    },
    }

  const optionsSnackError = {
    style: {
        backgroundColor: '#c60000',
        color: 'white',
        borderRadius: '10px',
        fontSize: '18px',
        textAlign: 'center',
    },
    closeStyle: {
        fontSize: '16px',
    },
    }

  const [id, setId] = useState(data.id)
  const [name, setName] = useState(data.username)
  const [firstName, setFirstName] = useState(data.first_name)
  const [lastName, setLastName] = useState(data.last_name)
  const [title, setTitle] = useState(data.title)
  const [dni, setDni] = useState(data.dni)
  const [university, setUniversity] = useState(data.university)
  const [universityCode, setUniversityCode] = useState(data.university_code)
  const [email, setEmail] = useState(data.email)
  const [experience, setExperience] = useState(data.experience)
  const [phoneNumber, setPhoneNumber] = useState(data.phone_number)
  const [lastJobTitle, setLastJobTitle] = useState(data.last_job_title)
  const [business, setBusiness] = useState(data.business)
  const [business_id, setBusiness_id] = useState(data.business_id)
  const [businessOther, setBusinessOther] = useState(data.business)
  const [businessLabel, setBusinessLabel] = useState('')
  const [birthday, setBirthday] = useState(() => {
    let date = new Date(data.birthday)
    let birthday = ((date.getUTCDate() < 10) ? '0' + date.getUTCDate() : date.getUTCDate()) + '/' +  (date.getUTCMonth() + 1) + "/" + date.getUTCFullYear()
    return (birthday)
  })
  const [personInCharge, setPersonInCharge] = useState(data.person_in_charge)
  const [education, setEducation] = useState(data.education)
  const [status, setStatus] = useState(data.status)
  const [outstanding, setOutstanding] = useState(data.outstanding)


  const [nameWarning, setNameWarning] = useState('')
  const [firstNameWarning, setFirstNameWarning] = useState('')
  const [lastNameWarning, setLastNameWarning] = useState('')
  const [titleWarning, setTitleWarning] = useState('')
  const [dniWarning, setDniWarning] = useState('')
  const [universityWarning, setUniversityWarning] = useState('')
  const [emailWarning, setEmailWarning] = useState('')
  const [experienceWarning, setExperienceWarning] = useState('')
  const [phoneNumberWarning, setPhoneNumberWarning] = useState('')
  const [lastJobTitleWarning, setLastJobTitleWarning] = useState('')
  const [businessWarning, setBusinessWarning] = useState('')
  const [birthdayWarning, setBirthdayWarning] = useState('')
  const [personInChargeWarning, setPersonInChargeWarning] = useState('')
  const [educationWarning, setEducationWarning] = useState('')
  const [educationError, setEducationError] = useState(false);
  const [universityLabel, setUniversityLabel] = useState("");
  // const [idSearchWarning, setIdSearchWarning] = useState('');
  const [isLoading, setIsLoading] = useState(false)
  const [showApprove, setShowApprove] = useState(false)

  const [openWellDone, closeWellDone] = useSnackbar(optionsSnackWellDone)
  const [openError, closeError] = useSnackbar(optionsSnackError)

  const regExDate = /^(?:(?:(?:0?[1-9]|1\d|2[0-8])[/](?:0?[1-9]|1[0-2])|(?:29|30)[/](?:0?[13-9]|1[0-2])|31[/](?:0?[13578]|1[02]))[/](?:0{2,3}[1-9]|0{1,2}[1-9]\d|0?[1-9]\d{2}|[1-9]\d{3})|29[/]0?2[/](?:\d{1,2}(?:0[48]|[2468][048]|[13579][26])|(?:0?[48]|[13579][26]|[2468][048])00))$/
  const regExName = /^[a-zA-Z]+[a-zA-Z]+$/
  const regExLetters = /^[a-z A-Z]+$/;
  const regExEmail = /\S+@\S+\.\S+/
  const formInitial = [{
    "id"             : id,
    'name'           : `${firstName} ${lastName}`,
    'firstName'      : firstName,
    'lastName'       : lastName,
    'title'          : title,
    'dni'            : dni,
    'university'     : university,
    'universityCode' : universityCode,
    'email'          : email,
    'experience'     : experience,
    'phoneNumber'    : phoneNumber,
    'lastJobTitle'   : lastJobTitle,
    'business'       : business,
    'business_id'    : business_id,
    'birthday'       : birthday,
    'personInCharge' : personInCharge,
    'education'      : education,
    'status'         : status,
    'outstanding'    : outstanding,
}]

const [formData, setFormData] = useState(formInitial)

  const checkvalue = (nameInput, value, label) => {
    if((value === '' || /^[0-9\b]+$/.test(value)) && nameInput === 'dni') {
        setDni(value)
    } else if ((value === '' || /^[0-9\b]+$/.test(value)) && nameInput === 'experience') {
        setExperience(value)
    } else if ((value === '' || /^[0-9\b]+$/.test(value)) && nameInput === 'phoneNumber') {
        setPhoneNumber(value)
    } else if(nameInput === 'email'){
        setEmail(value)
    } else if(nameInput === 'firstName'){
      setFirstName(value)
    } else if(nameInput === 'lastName'){
      setLastName(value)
    } else if(nameInput === 'birthday'){
        setBirthday(value)
    }else if ((value === '' || /^[0-9\b]+$/.test(value)) && nameInput === 'personInCharge') {
        setPersonInCharge(value)
    }else if(nameInput === 'name') {
        setName(value)
    } else if (nameInput === 'title') {
        setTitle(value)
    } else if (nameInput === 'university') {
      setUniversity(label)
      setUniversityCode(value)
    } else if (nameInput === 'lastJobTitle') {
        setLastJobTitle(value)
    } else if (nameInput === 'business') { 
      setBusinessLabel(value)
      setBusiness(value)
    } 
      else if (nameInput === 'education') {
    } 
    else if (nameInput === 'business_id') {  
      setBusiness_id(value)
      setBusinessOther(label)
      setBusiness(label)
    } else if (nameInput === 'education') {
        setEducation(value)
    }
    updateFormData(nameInput, value)
  }
  const form = new FormData();

  const updateFormData = (nameInput, value) => {
    const newForm = formData.map((item) => {
        if(nameInput === 'birthday'){
          const updatedItem = {...item, [nameInput]: moment(value,'DD/MM/YYYY').format('YYYY-MM-DD'),}
          return updatedItem
        } else {
          const updatedItem = {...item, [nameInput]: value,}
          return updatedItem
        }
    });
    setFormData(newForm)
  }

  const handleSubmit = e => {
    e.preventDefault()
    setShowApprove(false)
    if(checkEmpty() === 0){
      sendData()
    }
  }

  const checkEmpty = () => {
    
    let flag = 0;

    if(firstName.length === 0) {
      flag++
      setFirstNameWarning('El campo no puede estar vacío')
    } else if (!regExLetters.test(firstName)) {
      flag++
      setFirstNameWarning('Ingrese solo letras')
    } else {
      setFirstNameWarning('')
    }

    if(lastName.length === 0) {
      flag++
      
      setLastNameWarning('El campo no puede estar vacío')
    } else if (!regExLetters.test(lastName)) {
        flag++
        setLastNameWarning('Ingrese solo letras')
    } else {
      setLastNameWarning('')
    }

    if(title.length === 0) {
      flag++
      setTitleWarning('El campo no puede estar vacío')
    } else if (title.length > 0 && title.length <= 4) {
      flag++
      setTitleWarning('Debe tener mas de 4 caracteres')
    } else {
      setTitleWarning('')
    }

    if (dni.length === 0) {
      flag++
      setDniWarning('El campo no puede estar vacío')
    } else if (name.length < 8) {
      flag++
      setDniWarning('Ingrese un DNI valido')
    } else {
      setDniWarning('')
    }

    if (university.length === 0) {
      flag++
      setUniversityWarning('El campo no puede estar vacío')
    // } else if (university.length > 0 && university.length <= 2) {
    //   flag++
    //   setUniversityWarning('Ingrese más caracteres')
    } else {
      setUniversityWarning('')
    }

    if (email.length === 0) {
      flag++
      setEmailWarning('El campo no puede estar vacío')
    } else if (!regExEmail.test(email)) {
      flag++
      setEmailWarning('Ingrese un correo valido')
    } else {
      setEmailWarning('')
    }

    if (experience.length === 0) {
      flag++
      setExperienceWarning('El campo no puede estar vacío')
    } else if (experience.length > 2) {
      flag++
      setExperienceWarning('Ingrese una cantidad permitida')
    } else {
      setExperienceWarning('')
    }

    if (phoneNumber.length === 0) {
      flag++
      setPhoneNumberWarning('El campo no puede estar vacío')
    } else if (phoneNumber.length <= 5) {
      flag++
      setPhoneNumberWarning('Ingrese un numero valido')
    } else {
      setPhoneNumberWarning('')
    }

    if (lastJobTitle.length === 0) {
      flag++
      setLastJobTitleWarning('El campo no puede estar vacío')
    } else if (lastJobTitle.length > 0 && lastJobTitle.length < 3) {
      flag++
      setLastJobTitleWarning('Ingrese más caracteres')
    } else {
      setLastJobTitleWarning('')
    }

    if (business_id.length === 0) {
      flag++
      setBusinessWarning('El campo no puede estar vacío')
    } else {
      setBusinessWarning('')
    }
    if(business_id === "1" && businessLabel.length === 0) {
      flag++
      setBusinessWarning('El campo no puede estar vacío')
    } else {
      setBusinessWarning('')
    }
    if (birthday.length === 0) {
      flag++
      setBirthdayWarning('El campo no puede estar vacío')
    } else if (!regExDate.test(birthday)) {
      flag++
      setBirthdayWarning('Ingrese el formato correcto de fecha')
    } else {
      setBirthdayWarning('')
    }

    if (personInCharge.length === 0) {
      flag++
      setPersonInChargeWarning('El campo no puede estar vacío')
    } else {
      setPersonInChargeWarning('')
    }

    if (education.length === 0) {
      flag++
      setEducationError(true);
      setEducationWarning('El campo no puede estar vacío')
    } else {
      setEducationError(false);
      setEducationWarning('')
    }
    return flag
  }

  const sendData = async (e) => {
    formData[0].birthday = moment(birthday,'DD/MM/YYYY').format('YYYY-MM-DD')
    formData[0].name = `${firstName} ${lastName}`
    formData[0].university = university
    formData[0].universityCode = universityCode
      setIsLoading(true)
      try {
    API.put('/candidates/update_candidate', formData[0])
    .then(res => {
      onHide(false)
      openWellDone('Se ha modificado el candidato de manera exitosa.')
    })
      }
      catch (error) {
          openError('Ha ocurrido un error, vuelve a intentarlo más tarde.');
          console.log(error)
          setIsLoading(false)
      }
  }

  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={onHide}
        backdrop={'static'}
        aria-labelledby="example-modal-sizes-title-lg"
        className='modal_edit_applicant'
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body className='content-edit'>
          <Modal.Title className='titulo'>
            Editar candidato
          </Modal.Title>
          {/* <form onSubmit={handleSubmit}> */}
            <Row className='content-input'>
              <Col sm={6}>
                <label>Nombre</label>
                <input name='firstName' type="text" id="primer_nombre" value={firstName} onChange={e => checkvalue(e.target.name, e.target.value)}/>
                {firstNameWarning && <span className='error-message'>{firstNameWarning}</span>}
              </Col>
              <Col sm={6}>
                <label>Apellido</label>
                <input name='lastName' type="text" id="primer_apellido" value={lastName} onChange={e => checkvalue(e.target.name, e.target.value)}/>
                {lastNameWarning && <span className='error-message'>{lastNameWarning}</span>}
              </Col>
            </Row>
            <Row className='content-input'>
              <Col sm={6}>
                <label>Titulo</label>
                <input name='title' type="text" id="titulo" value={title} onChange={e => checkvalue(e.target.name, e.target.value)} />
                {titleWarning && <span className='error-message'>{titleWarning}</span>}
              </Col>
              <Col sm={6}>
                <label>DNI</label>
                <input name='dni' type="text" id="dni" value={dni} onChange={e => checkvalue(e.target.name, e.target.value)} />
                {dniWarning && <span className='error-message'>{dniWarning}</span>}
              </Col>
              
            </Row>
            <Row className='content-input'>
              <Col sm={6}>
                <label>Universidad</label>
                {/* <input name='university' type="text" id="universidad" value={university} onChange={e => checkvalue(e.target.name, e.target.value)} /> */}
                <select
                        style={{ marginTop: "5px" }}
                        name="university"
                        value={university}
                        id="universidad"
                        onChange={(e) => checkvalue(e.target.name, e.target.value, e.target.options[e.target.selectedIndex].dataset.value )}
                      > <>
                        <option value="">{university}</option>
                        {
                          listUniversity.map((element, key) => {
   
                            return (
                              <option value={element.value} key={element.value} data-value={element.name}>
                                {element.name}
                              </option>
                            )
                            
                          })
                        }
                        </>
                      </select>
                {universityWarning && <span className='error-message'>{universityWarning}</span>}
              </Col>
              <Col sm={6}>
                <label>Email</label>
                <input name='email' type="text" id="mail" value={email} onChange={e => checkvalue(e.target.name, e.target.value)} />
                {emailWarning && <span className='error-message'>{emailWarning}</span>}
              </Col>
              
            </Row>
            <Row className='content-input'>
              <Col sm={6}>
                <label>Experiencia</label>
                <input name='experience' type="text" id="experiencia" value={experience} onChange={e => checkvalue(e.target.name, e.target.value)} />
                {experienceWarning && <span className='error-message'>{experienceWarning}</span>}
              </Col>
              <Col sm={6}>
                <label>Télefono</label>
                <input name='phoneNumber' type="text" id="telefono" value={phoneNumber} onChange={e => checkvalue(e.target.name, e.target.value)}  />
                {phoneNumberWarning && <span className='error-message'>{phoneNumberWarning}</span>}
              </Col>
              
            </Row>
            <Row className='content-input'>
              <Col sm={6}>
                <label>Posición</label>
                <input name='lastJobTitle' type="text" id="ultima-pocision" value={lastJobTitle} onChange={e => checkvalue(e.target.name, e.target.value)} />
                {lastJobTitleWarning && <span className='error-message'>{lastJobTitleWarning}</span>}
              </Col>
              <Col sm={6}>
                <label>Empresa</label>
                {/* 
                {businessWarning && <span className='error-message'>{businessWarning}</span>} */}

                <select
                  name="business_id"
                  style={{ marginTop: "5px" }}
                  value={business}
                  onChange={(e) => checkvalue(e.target.name, e.target.value, e.target.options[e.target.selectedIndex].text)}
                > 
                  <option value="">{businessOther}</option>
                  {
                    listCompanies.map((element, key) => {
                      return (
                        <option value={element.id} key={element.id}>
                          {element.name}
                        </option>
                      )
                    })
                  }
                </select>
                {
                  business_id === "1" && (
                    <input name='business' type="text" id="empresa" value={businessLabel} onChange={e => checkvalue(e.target.name, e.target.value)} />
                  )
                }
                {businessWarning && <span className='error-message'>{businessWarning}</span>}
              </Col>
              
            </Row>
            <Row className='content-input'>
              <Col sm={6}>
                <label>Fecha de nacimiento</label>
                <input name='birthday' type="text" id="fecha-nac" value={birthday} onChange={e => checkvalue(e.target.name, e.target.value)} />
                {birthdayWarning && <span className='error-message'>{birthdayWarning}</span>}
              </Col>
              <Col sm={6}>
                <label>Personas a Cargo</label>
                <input name='personInCharge' type="text" id="a-cargo" value={personInCharge} onChange={e => checkvalue(e.target.name, e.target.value)} />
                {personInChargeWarning && <span className='error-message'>{personInChargeWarning}</span>}
              </Col>
              <Col sm={6}>
              <label>Educación</label>
                <div className="input-error">
                      <select
                        name="education"
                        style={{ marginTop: "5px" }}
                        value={education}
                        onChange={(e) => checkvalue(e.target.name, e.target.value)}
                      >
                        {OptionsList.Grado.map((element, key) => {
                          return (
                            <option value={key === 0 ? "" : element} key={key}>
                              {element}
                            </option>
                          );
                        })}
                      </select>
    
                      <div
                        className={`alert alert-danger mt-3 ${
                          educationError ? "" : "d-none"
                        }`}
                      >
                        {educationWarning}
                      </div>
                    </div>
              </Col>
            </Row>
            <Row className='content-buttons'>
              <button id='guardar' disabled={isLoading ? true : false} onClick={() => setShowApprove(true)}>GUARDAR</button>
              <button onClick={() => onHide(false)} disabled={isLoading ? true : false} id='cancelar'>CANCELAR</button>
            </Row>
          {/* </form> */}
        </Modal.Body>
        {showApprove && 
          <Modal
            // size="sm"
            show={showApprove}
            onHide={() => setShowApprove(false)}
            className='modal_check_edit_applicant'
            // aria-labelledby="example-modal-sizes-title-sm"
          >
            <Modal.Header className='modal-header' closeButton>
            </Modal.Header>
            <Modal.Body>
              <Modal.Title className='titulo'>
                ¿Estás seguro de realizar estos cambios?
              </Modal.Title>
              <Row className='content-buttons'>
                <button id='guardar' onClick={handleSubmit}>GUARDAR</button>
                <button onClick={() => setShowApprove(false)} id='cancelar'>CANCELAR</button>
              </Row>
            </Modal.Body>
          </Modal>
        }
      </Modal>
    </>
  )
}

export default ModalInfoApplicant