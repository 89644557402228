import React, { useContext } from "react"
import { UserContext} from "../../../context/userContext";
import './header.css'
import './hamburguesas.css'
import exit from '../../../images/header/salir.png'
import { Link, useHistory } from 'react-router-dom'
import {deleteToken} from "../../../http-common";
import MetaTags from 'react-meta-tags';
import imagen_promo from '../../../images/menu/busqueda_Mesa_de_trabajo.jpg'

const Header = () => {
  const { userData, setUserData} = useContext(UserContext)
  let history = useHistory();

  const onLogOut = async () => {
    try {
        let user_id = userData.id;
       await deleteToken(); 
      setUserData(null);
      history.push("/login");
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
    }
  }

  return (
    <>
      <MetaTags>
        <title>Balanz</title>
        {/* <meta name="description" content="Some description." /> */}
          {/* <meta property="og:url" content={`${PRODUCTION_URL}/${search.id}`} /> */}
          <meta property="og:type" content="website" />
          {/* <meta property="og:title" content={`Buscamos`} /> */}
          {/* <meta property="og:description" content={} /> */}
          <meta property="og:image" content={imagen_promo} />
      </MetaTags>
      <div className="banner">
        <div className="content-banner">
          <div className="left">
            <span className="titulo"><Link to="/panel">BALANZ</Link></span>
            <span className="hr"><Link to="/panel">HR panel</Link></span>
          </div>
          <div className="right">
            <span className="nombre">
              { userData.username } ({ userData.sector})
              <span class="position">{ userData.role_name }</span>
            </span>
            <button style={{background:'none', border:'none'}} onClick={onLogOut} ><img src={exit} alt="salir" /></button>
          </div>
        </div>
      </div>
    </>
  )

}

export default Header