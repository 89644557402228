import { useContext, useEffect, useState } from "react";
import { Modal, Row } from "react-bootstrap";
import { UserContext } from "../../../../context/userContext";
import './ModalNotes.css';
import arrow_display from "../../../../images/panel/chevron-down.png";
import delete_icon from "../../../../images/panel/delete.png";
import { API } from "../../../../http-common";
import moment from 'moment';
import 'moment/locale/es-mx';
moment.locale('es-mx');

const getCurrentDate = () => {
    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${date<10?`0${date}`: `${date}`}/${month<10?`0${month}`:`${month}`}/${year}`
}


const ModalNotes = ({ id, show, onHide, type = 'list', searchSelected = null }) => {
    const selected = null !== searchSelected;
    const {userData} = useContext(UserContext);
    const [noteType, setNoteType] = useState(type);
    const [search, setSearch] = useState(selected ? searchSelected.id : '0');
    const [notes, setNotes] = useState('');
    const [SearchHistory, SetSearchHistory] = useState([]);
    const [data, setData] = useState([]);
    const [error_search, setErrorSearch] = useState('');
    const [error_search_report, setErrorSearchReport] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [noteId, setNoteId] = useState(0)

    const [error_notes, setErrorNotes] = useState('')
    const [error_notes_report, setErrorNotesReport] = useState(false)


    const toggleDisplay = (index) => {
        let newArray = [];
        data.forEach((d, i) => {
            if(i === index) {
                d.display = !d.display;
            }
            newArray.push(d);
        })
        setData(newArray);
    }

    const checksEmpty = () => {
        let flag = 0;
        let findSearch = SearchHistory.find(s => search);

        if(search.toString() !== '0' && typeof findSearch === 'undefined') {
            setErrorSearch('Seleccione una busqueda');
            setErrorSearchReport(true);
            flag++;
        }else{
            setErrorSearch('');
            setErrorSearchReport(false);
        }


        if(notes === '') {
            setErrorNotes('Escriba una nota');
            setErrorNotesReport(true);
            flag++;
        }else{
            setErrorNotes('');
            setErrorNotesReport(false);
        }

        return flag > 0
    }

    function getCandidates(candidate_id) {
        API.get("candidates/"+candidate_id+"/notes").then(function (
          response
        ) {
            const { notes } = response.data;
            notes.map(n => {
                n.display = false;
                return n;
            })
            setData(notes)
        });
    }

    function getSearches(candidate_id){
        API.get("candidates/"+candidate_id+"/positions-list").then(function (
            response
        ) {
            const { data } = response;
            SetSearchHistory(data)
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if(checksEmpty()){
            return false;
        }

        API.post("candidates/"+id+"/notes", {
            search_id: search,
            content: notes
        }).then(function (
            response
        ) {
            const { notes } = response.data;
            if(!selected) {
                setSearch('0');
            }
            setNotes('');
            setNoteType('list');

            notes.map(n => {
                n.display = false;
                return n;
            })
            setData(notes)
        });
    }

    const deleteNoteModal = (event, id_note) => {
        event.preventDefault();
        setShowModal(true);
        setNoteId(id_note)
    }

    const deleteNote = () => {
        API.delete("candidates/"+id+"/notes/"+noteId).then(function (
            response
        ) {
            const { notes } = response.data;
            notes.map(n => {
                n.display = false;
                return n;
            })
            setData(notes)
            setShowModal(false);
        });
    }


    useEffect(() => {
        if(typeof id !== 'undefined'){
            getSearches(id)
            getCandidates(id)
        }

        setNoteType(type)
    }, [type, setNoteType, id]);

    return (
        <>
            <Modal
                size="xl"
                show={show}
                onHide={onHide}
                backdrop={'static'}
                aria-labelledby="example-modal-sizes-title-lg"
                className='modal_edit_applicant'
            >
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body className='content-edit'>
                <Modal.Title className='titulo'>
                    Notas
                </Modal.Title>
                    <div className="notes-section">


                        {noteType === 'add' && (
                            <form onSubmit={handleSubmit}>

                                <div className="d-flex align-items-center justify-content-center">
                                    <div className="btn-group" role="group" aria-label="Basic example">
                                        <button onClick={() => setNoteType('list')} type="button" className="btn btn-primary btn-action">VER NOTAS</button>
                                    </div>
                                </div>

                                <div className="form-notas">
                                    <div className="row">
                                    <div className="col-12 col-md-4 mb-3 content-datos">
                                        <select
                                            name=""
                                            onChange={(evt) => setSearch(evt.target.value)}
                                            disabled={selected}
                                        >
                                        {!selected ? (
                                            <>
                                                <option value="0">Sin busqueda específica</option>
                                                {SearchHistory.map((item, index) => (
                                                    <option selected={searchSelected === item.id} key={index} value={item.id}>{item.id} - {item.name}</option>
                                                ))}
                                            </>
                                        ) : (
                                            <>
                                                <option value={searchSelected.id}>{searchSelected.id} - {searchSelected.name}</option>
                                            </>
                                        )}


                                        </select>

                                        <div className={`alert alert-danger mt-3 ${error_search_report ? "" : "d-none"}`}>{error_search}</div>
                                    </div>

                                    <div className="col-12 col-md-4 mb-3 content-datos">
                                        <input value={userData.username} disabled type="text"/>
                                    </div>

                                    <div className="col-12 col-md-4 mb-3 content-datos">
                                        <input value={getCurrentDate()} disabled type="text"/>
                                    </div>
                                    <div className="col-md-12 content-datos">
                                        <textarea onChange={(evt) => setNotes(evt.target.value)} name="description_task" id="description_task" cols="30" rows="10" placeholder="Escribe la nota...">{notes}</textarea>
                                        <div className={`alert alert-danger mt-3 ${error_notes_report ? "" : "d-none"}`}>{error_notes}</div>
                                    </div>
                                    </div>
                                </div>

                                <div className="text-right">
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-action"
                                        data-dismiss="modal"
                                    >
                                        Guardar
                                    </button>
                                </div>
                            </form>
                        )}

                        {noteType === 'list' && (
                            <>
                            <div className="d-flex align-items-center justify-content-center">
                                <div className="btn-group" role="group" aria-label="Basic example">
                                    <button onClick={() => setNoteType('add')} type="button" className="btn btn-primary btn-action">AGREGAR NOTA</button>
                                </div>
                            </div>
                            <table className="default table table-striped table_view_process">
                                <thead>
                                <tr>
                                    <th scope="col">&nbsp;</th>
                                    <th scope="col">Búsqueda</th>
                                    <th scope="col">Usuario</th>
                                    <th style={{ width: '15%' }} scope="col text-left">Fecha</th>
                                </tr>
                                </thead>

                                <tbody>

                                {data.length > 0 ? (
                                    <>
                                    {data.map((d, i) => (
                                        <>
                                            <tr key={i}>
                                                <td className="align-middle">
                                                    <div className="truncate">{d.content}</div>
                                                </td>
                                                <td className="align-middle">{d.search_id} - {d.search_title}</td>
                                                <td className="align-middle">{d.author_name} </td>
                                                <td className="align-middle">
                                                    <div className="d-flex align-items-center">
                                                        {moment(d.created_at).format('DD/MM/YYYY HH:mm')}
                                                        <div className="icon-action ml-2">
                                                            <img className={d.display ? 'arrow_up' : ''} onClick={() => toggleDisplay(i)} src={arrow_display} />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            {d.display && (
                                                <tr key={'tr_'+i}>
                                                    <td colSpan="5">
                                                        <div className="details-column">
                                                            <p className="lntobr">{d.content}</p>
                                                            {d.allow_delete && (
                                                                <>
                                                                <a href="#" onClick={(event) => deleteNoteModal(event, d.id)} className="delete-icon">
                                                                    <img src={delete_icon} />
                                                                    Eliminar
                                                                </a>
                                                                </>
                                                            )}

                                                        </div>

                                                    </td>
                                                </tr>
                                            )}

                                        </>

                                        )
                                    )}
                                    </>
                                ) : (
                                    <>
                                        <tr>
                                            <td colSpan={4}>
                                                El candidato aún no tiene notas.
                                            </td>
                                        </tr>

                                    </>
                                )}


                                </tbody>
                            </table>
                            </>
                        )}
                    </div>
                </Modal.Body>
            </Modal>


            <Modal
                // size="sm"
                show={showModal}
                className="modal_check_edit_applicant modal-delete"
                onHide={() => {setShowModal(false)}}
                backdropClassName="delete-modal-backgrop"
                // aria-labelledby="example-modal-sizes-title-sm"
            >
                <Modal.Header className="modal-header" closeButton></Modal.Header>
                <Modal.Body>
                <Modal.Title className="titulo">¿Estás seguro que deseas Eliminar esta nota?</Modal.Title>
                <Row className="content-buttons">
                    <button
                        id="guardar"
                        onClick={deleteNote}
                    >
                        ACEPTAR
                    </button>

                    <button
                    onClick={() => {
                        setShowModal(false);
                    }}
                    id="cancelar"
                    >
                    CANCELAR
                    </button>
                </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalNotes;