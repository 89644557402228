import HeaderPublic from "../../includes/Header/HeaderPublic"
import './accessError.css'
import { Link } from "react-router-dom";

const AccessError = ({ errorMessage, buttonLink }) => {
    return (
        <>
        <div className="container text-center mt-5">
            <h1 className="text-muted">Error</h1>
            <p className="text-muted">
               {errorMessage}
            </p>

            <Link to={ buttonLink }>VOLVER AL LISTADO</Link>
        </div>
        </>
    )
}

export default AccessError