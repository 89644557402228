import React, { useEffect, useState, useContext, useRef,useMemo } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useSortBy,
} from "react-table";
import Footer from "../../includes/Footer/Footer";
import Header from "../../includes/Header/Header";
import Menu from "../../includes/Menu/Menu";
import "./searches.css";
import ver from "../../../images/menu/ver.png";
import { API } from "../../../http-common";
import LoadingList from "../Loading/LoadingList";
import { filtroSinAcentos } from '../../../helpers/customFilters'
const FILTER_NAME = {
  departament: "Depto Corporativo",
}



// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  setTableRelativePage
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setTableRelativePage('first');
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <input
      className="form-control search"
      value={value || ""}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      placeholder={`Buscar`}
    />
  );
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      className="form-control"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

const Styles = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;

  /* This will make the table scrollable when it gets too small */
  .tableWrap {
    display: block;
    max-width: 100%;
    overflow-y: hidden;
    border-bottom: 1px solid black;
  }

  table {
    /* Make sure the inner table is always as wide as needed */
    width: 100%;
    border-spacing: 0;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      /* The secret sauce */
      /* Each cell should grow equally */
      width: 1%;
      /* But "collapsed" cells should be as small as possible */
      &.collapse {
        width: 0.0000000001%;
      }

      :last-child {
        border-right: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`;

function Table({ columns, data, loading }) {
  const currentStateId = 'ListSearches_0';

  let initialState = {};
  if (localStorage.getItem('SearchesTableStateId') === currentStateId) {
    try {
      const lastState = JSON.parse(localStorage.getItem('SearchesTableStateData'));
      initialState = lastState || {};
    } catch {
      initialState = {};
    }
  }

  const storeTableStateReducer = (newState, action, prevState) => {
    localStorage.setItem('SearchesTableStateId', currentStateId);
    localStorage.setItem('SearchesTableStateData', JSON.stringify(newState));
    return newState;
  };

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      globalFilter:filtroSinAcentos,
      columns,
      data,
      initialState,
      stateReducer: storeTableStateReducer
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );

  const setTableRelativePage = (page) => {
    switch (page) {
      case 'prev':
        previousPage();
        break;
      case 'next':
        nextPage();
        break;
      case 'first':
        gotoPage(0);
        break;
      case 'last':
        gotoPage(pageCount - 1);
        break;
    }
  }

  const setTablePageNumber = pageNumber => {
    const pageIndex = pageNumber - 1;
    if (pageIndex >= 0 && pageIndex < pageCount) gotoPage(pageIndex);
  }

  // Render the UI for your table
  return (
    <Styles>
      <div className="tableWrap">
        <GlobalFilter
          setTableRelativePage={setTableRelativePage}
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        <table className="table table_busqueda" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    id={column.id}
                    data-sortby={column.isSortedDesc}
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : " 🔼"
                        : ""}
                    </span>
                    {/* Render the columns filter UI */}
                    <div>
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {loading && (
              <tr>
                <td colSpan={10}>
                  <LoadingList />
                </td>
              </tr>
            )}
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr className={row.original.status} {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <ul className="pagination">
          <li
            className="page-item"
            onClick={() => {
              setTableRelativePage("first");
            }}
            disabled={!canPreviousPage}
          >
            <a className="page-link">{"Primera"}</a>
          </li>
          <li
            className="page-item"
            onClick={() => {
              setTableRelativePage("prev");
            }}
            disabled={!canPreviousPage}
          >
            <a className="page-link">{"< Anterior"}</a>
          </li>
          <li
            className="page-item"
            onClick={() => {
              setTableRelativePage("next");
            }}
            disabled={!canNextPage}
          >
            <a className="page-link">{"Siguiente >"}</a>
          </li>
          <li
            className="page-item"
            onClick={() => {
              setTableRelativePage("last");
            }}
            disabled={!canNextPage}
          >
            <a className="page-link">{"Ultimo"}</a>
          </li>
          <li>
            <a className="page-link no-hover">
              Pagina{" "}
              <strong>
                {pageIndex + 1} de {pageCount}
              </strong>{" "}
            </a>
          </li>
          <li className="page-item">
            <span>
              <input
                className="page-link no-hover"
                type="number"
                defaultValue={pageIndex + 1}
                min="1"
                max={pageCount}
                value={pageIndex + 1}
                onChange={(e) => {
                  const pageNumber = e.target.value ? Number(e.target.value) : 1;
                  setTablePageNumber(pageNumber);
                }}
                style={{ width: "80px", textAlign: "center" }}
              />
            </span>
          </li>{" "}
          <select
            className="page-link no-hover"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            style={{ fontSize: "15px", width: "120px", padding: "7px" }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Mostrar {pageSize}
              </option>
            ))}
          </select>
        </ul>
      </div>
    </Styles>
  );
}

// This is a custom filter UI for selecting a unique option from a list
function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">Todos</option>
      {options.map((option, i) => (
        <option key={i} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
}

function SelectStatusFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  // Calculate the options for filtering using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <>
      <div className={"content-filtrar "+id}>
        <span className="filtrar-span">{FILTER_NAME[id]}</span>
        <select
          name="filter"
          value={filterValue}
          onChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
        >
          <option value="">Todos</option>
          {options.map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    </>
  );
}
const SelectColumnIntranetFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);
  // Render a multi-select box
  return (
    <>
      <div className="content-filtrar visto">
        <span className="filtrar-span">Depto Intranet</span>
        <select
          name="filter"
          // className='form-control'
          value={filterValue}
          onChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
        >
          <option value="">Todos</option>
          {options.map((option, i) => (
            <option key={i} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
    </>
  );
};

const SelectFilterBy = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  // Calculate the options for filtering using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      if(row.values[id] !== null){
        options.add(row.values[id]);
      }
    });
    return [...options.values()];
  }, [id, preFilteredRows]);
  // Render a multi-select box
  return (
    <>
      <div className={"content-filtrar "+id}>
        <span className="filtrar-span">Filtrado Por</span>
        <select
          name="filter"
          value={filterValue}
          onChange={(e) => {
            setFilter(e.target.value || undefined);
          }}
        >
          <option value="">Todos</option>
          {options.map((option, i) => (
           <option key={i} value={option}>
           {option}
         </option>
          ))}
        </select>
      </div>
    </>
  );
};
const Searches = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true)
  const backUrl = "testing/567";
  const columns = React.useMemo(
    () => [
      {
        Header: "NRO.",
        accessor: "id",
        disableFilters: true,
      },
      {
        Header: "NOMBRE DEL PUESTO",
        accessor: "position_name",
        disableFilters: true,
        Cell: ({ row }) => (
          <span className="puesto">{row.original.position_name}</span>
        ),
      },
      {
        Header: "FECHA DE CARGA",
        accessor: "timestamp",
        disableFilters: true,
      },
      {
        Header: "FECHA DE APROBACIÓN",
        accessor: "timestamp_approve",
        disableFilters: true,
      },
      {
        Header: "DIRECTOR QUE APRUEBA",
        accessor: "director_username",
        disableFilters: true,
        Cell: ({ row }) => (
          <div className="contet-director">
            {row.original.director_username}
          </div>
        ),
      },
      {
        Header: "GERENTE",
        accessor: "gerente_username",
        disableFilters: true,
        Cell: ({ row }) => (
          <div className="contet-director">{row.original.gerente_username}</div>
        ),
      },
      {
        Header: "ESTADO DIRECTOR",
        accessor: "status_director",
        disableFilters: true,
        Cell: ({ row }) => (
          <div
            className="color-estado"
            data-estado={row.original.status_director}
          ></div>
        ),
      },
      {
        Header: "RRHH",
        accessor: "status_rrhh",
        disableFilters: true,
        Cell: ({ row }) => (
          <div
            className="color-estado"
            data-estado={row.original.status_rrhh}
          ></div>
        ),
      },
      {
        Header: "DEPTO CORPORATIVO",
        accessor: "departament",
        Filter: SelectStatusFilter
      },
      {
        Header: "DEPTO INTRANET",
        accessor: "departament_intranet",
        Filter: SelectColumnIntranetFilter
      },
      {
        Header: "ESTADO",
        accessor: "status",
        Filter: SelectFilterBy,
        Cell: ({ row }) => (
          <div className="estado_general">
            <span className={row.original.status}>{row.original.status}</span>
          </div>
        ),
      },
      {
        Header: "",
        accessor: "link",
        disableFilters: true,
        Cell: ({ row }) => (
          <div>
            {/*<Link
              to={
                row.original.status_id === 1 || row.original.status_id === 2
                  ? "/compensacion/" + row.original.id
                  : row.original.status_id === 3
                  ? "/time_line_rrhh/" + row.original.id
                  : "/time_line_rrhh/" + row.original.id

              }

            >
              <img style={{ width: "22px" }} src={ver} alt="" />
            </Link>*/}

            <Link
              to={{
                pathname: `${
                  row.original.status_id === 1 || row.original.status_id === 2
                    ? "/compensacion/" + row.original.id
                    : row.original.status_id === 3
                    ? "/time_line_rrhh/" + row.original.id
                    : "/time_line_rrhh/" + row.original.id
                }`,
                query: { backUrl: `${"/time_line_rrhh/" + row.original.id}` },
              }}
            >
              <img style={{ width: "22px" }} src={ver} alt="" />
            </Link>
          </div>
        ),
      },
    ],
    []
  );

  const data_truncated = [
    {
      id: "21",
      position_name: "Director de cuentas",
      timestamp: "2020/12/4",
      timestamp_approve: "2020/12/1",
      director_id: 75,
      director_username: 75,
      status_director_id: 3,
      status_director: "rechazada",
      status_rrhh_id: 3,
      status_rrhh: "rechazada",
      status_id: 4,
      status: 5,
    },
    {
      id: "22",
      position_name: "Director de cuentas",
      timestamp: "2020/12/4",
      timestamp_approve: "2020/12/1",
      director_id: 75,
      director_username: 75,
      status_director_id: 1,
      status_director: "pendiente",
      status_rrhh_id: 1,
      status_rrhh: "pendiente",
      status_id: "pendiente",
      status: 1,
    },
    {
      id: "21",
      position_name: "Director de cuentas",
      timestamp: "2020/12/4",
      timestamp_approve: "2020/12/1",
      director_id: 75,
      director_username: 75,
      status_director_id: 3,
      status_director: "rechazada",
      status_rrhh_id: 3,
      status_rrhh: "rechazada",
      status_id: 4,
      status: 5,
    },
    {
      id: "22",
      position_name: "Director de cuentas",
      timestamp: "2020/12/4",
      timestamp_approve: "2020/12/1",
      director_id: 75,
      director_username: 75,
      status_director_id: 1,
      status_director: "pendiente",
      status_rrhh_id: 1,
      status_rrhh: "pendiente",
      status_id: "pendiente",
      status: 1,
    },
  ];

  function getSearches() {
    API.get("/positions").then(function (response) {
      if (response.data.error === 0) {
        setData(response.data.data);
      } else {
        //todo
      }
      setLoading(false)
    });
  }

  useEffect(() => {
    getSearches();
  }, []);

  return (
    <>
      <Header />
      <Menu />
      <div className="content-busqueda">
        <div className="contenido">
          <div className="content-table">
            <Table columns={columns} data={data} loading={loading} />
          </div>
        </div>
      </div>
      {/* <Footer/> */}
    </>
  );
};

export default Searches;
