import React, { useContext, useState } from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { UserContext } from "../context/userContext";

function ProtectedRoute({
  isLogged: isLogged,
  component: Component,
  allowedRoles: allowedRoles,
  ...rest
}) {
  let history = useHistory();
  const { userData, setUserData } = useContext(UserContext);

  const isAuth = allowedRoles.includes(userData ? userData.role_id : false);
  const query = window.location.hash;

  if (query) {
    localStorage.setItem("candidateUrl", window.location.href)
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        isLogged ? (
          isAuth ? (
            <Component {...props} />
          ) : (
            history.goBack()
          )
        ) : (
          <Redirect to={{ pathname: "/login" }} />
        )
      }
    />
  );
}

export default ProtectedRoute;
