import moment from "moment";
import ReactExport from "react-export-excel";
import download from '../../../../images/menu/download.png'
import '../report.css'


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExcelAreaAdmissionsReport = ({data}) =>{

  if(Array.isArray(data)) {
    data.forEach(d => {
      d['Fecha de Ingreso'] = moment(d['Fecha de Ingreso']).format('YYYY-MM-DD');
    })
  }

  return (
    <ExcelFile filename='Ingresos por Area' element={<button className='boton-descargar-excel'><img src={download} width='20px' height='20px' alt='download'/></button>}>
      <ExcelSheet data={data} name="Ingresos" >
        <ExcelColumn label="Nombre y Apellido" value="Nombre y Apellido"/>
        <ExcelColumn label="DNI" value="DNI"/>
        <ExcelColumn label="Area" value="Area"/>
        <ExcelColumn label="Búsqueda" value="Busqueda"/>
        <ExcelColumn label="Estado" value="Estado"/>
        <ExcelColumn label="HM" value="HM"/>
        <ExcelColumn label="Director" value="Director"/>
        <ExcelColumn label="Centro de Costos" value="Centro de Costos"/>
        <ExcelColumn label="Fecha de Ingreso" value="Fecha de Ingreso"/>
      </ExcelSheet>
    </ExcelFile>
  );
}

export default ExcelAreaAdmissionsReport