import {useContext, useEffect, useState} from "react"
import '../../../timeLineRRHH.css'
import $ from 'jquery'
import { API } from "../../../../../../http-common"
import {DataContext} from "../../../../../../context/dataContext";
import moment from "moment";
import {UserContext} from "../../../../../../context/userContext";
import * as constants from "../../../../../../constants";

const Accordion4 = ({available, setAvailable,idSearch, module, applicant, disableEdit, onDataChange = null})  => {
    const { userData, setUserData} = useContext(UserContext);
    const {dataTableChanged, setDataTableChanged, currentSearchStatus, setCurrentSearchStatus} = useContext(DataContext);
    const [submit, setSubmit] = useState(false)
    const [moduleStatus, setModuleStatus] = useState(module.status)
    const [feedback, setFeedback] = useState(module.data.feedback || '')
    const [accepted, setAccepted] = useState(module.data.accepted)
    const [feedbackError, setFeedbackError] = useState(false)
    const [acceptedDate, setAcceptedDate] = useState()
    const [errorDate, setErrorDate] = useState(false)
    const [addmissionDate, setAdmissionDate] = useState(module.admission_date)
    const [dataModule, setDataModule] = useState(module);
    const [isLoading, setIsLoading] = useState(false)
    const [loading, setLoading] = useState(false)
    // const [showModalAvanza, setShowModalAvanza] = useState(false)
    // const [showModalRechazo, setShowModalRechazo] = useState(false)
    const toggleClass = () => {
        let modal = document.getElementsByClassName("modal_edit_applicant");
        if(modal.length > 0){
          setTimeout(() => {
            document.body.classList.add("modal-open");
          }, 500)
        }
      }

    useEffect(() => {
        if (submit && !feedbackError) {
            sendData()
        }
        setSubmit(false)
    }, [submit])

    const sendData = () => {
        setIsLoading(true)
        const fecha = moment(new Date()).format("YYYY-MM-DD")
        const dataForm = {
            search_id: idSearch,
            candidate_id: applicant.candidate_id,
            applicant_id: applicant.applicant_id,
            module_id: 4,
            employees_id: userData.id,
            feedback_date: fecha,
            feedback: feedback,
            accepted: accepted,
            link_note: null,
            status: moduleStatus
        }
        const module_id = dataModule.id ?? 0;

        try {
            API.post("/applications/timeline/"+applicant.applicant_id+"/module/"+module_id, dataForm)
            .then((response) => {
                setAvailable(!available)
                // setDataTableChanged(true)
                toggleClass()
                setDataModule({
                    ...dataModule,
                    id: response.data.id
                })
                if(null !== onDataChange) onDataChange()
                setIsLoading(false)
            })
        }
          catch(error){
            console.log(error)
            setIsLoading(false)
          }
    }
    const handleChange = e => {
        const { value } = e.target;
        setAcceptedDate(value)
    }
    const handleRBChange = e => {
        const { name, value } = e.target;
        setFeedback(value)
    }

    const handleInterview4 = () => {
        setAccepted(1)
        setModuleStatus(1)
        setSubmit(true)
        checkInputs()
    }

    const handleRejectedInterview4 = () => {
        setAccepted(2)
        setModuleStatus(2)
        setSubmit(true)
        checkInputs()
    }

    const checkInputs = () => {
        if(accepted == 2 && feedback.length === 0) {
            setFeedbackError(true)
        } else {
            setFeedbackError(false)
        }
    }

    const sendAcceptedDate = () => {
       
        const fecha = moment(new Date()).format("YYYY-MM-DD")
        const dataForm = {
            search_id: idSearch,
            candidate_id: applicant.candidate_id,
            applicant_id: applicant.applicant_id,
            module_id: 4,
            employees_id: userData.id,
            feedback_date: fecha,
            feedback: feedback,
            accepted: null,
            link_note: null,
            admission_date: acceptedDate,
            status: 3
        }
        setLoading(true)
        setErrorDate(false)
        if(!acceptedDate){
            setErrorDate(true)
        }else{
            try{
                const module_id = dataModule.id ?? 0;
                API.post("/applications/timeline/"+applicant.applicant_id+"/module/"+module_id, dataForm)
                .then((response) => {
                    setAdmissionDate(acceptedDate)
                    setDataModule({
                        ...dataModule,
                        id: response.data.id
                    })
                    if(null !== onDataChange) onDataChange()
                    setLoading(false)
                })
                
            }
            catch(e){
                console.log(e)
                setLoading(false)
              }
        }
    }

   console.log(loading)

    return (
        <>
            <div className="card">
                <div className="card-header" id="propuesta-heading">
                    <h5 className="mb-0">
                        <button className="btn btn-link collapsed" data-toggle="collapse" data-target="#propuesta" aria-expanded="false" aria-controls="propuesta">
                            Propuesta
                        </button>
                        <span className="mas" data-toggle="collapse" data-target="#propuesta" aria-expanded="false" aria-controls="propuesta">+</span>
                    </h5>
                </div>
                {/* <div id="propuesta" className={1 === 1 ? "collapse" : "collapse disabled-accordion"} aria-labelledby="propuesta-heading"> */}
                <div id="propuesta" className="collapse" aria-labelledby="propuesta-heading">
                    <div className="card-body">

                        {(module.completed === 0 && addmissionDate === null && constants.RRHH.includes(userData.role_id)) && (
                            <>
                                <div className="barra-superior">
                                    <div className="left">
                                        <div className="item">
                                            <span>Fecha</span>
                                            <input type="date" onChange={handleChange} value={acceptedDate} />

                                            <span className={errorDate ? "span-error" : "d-none"}>
                                                Campo Vacio
                                            </span>
                                        </div>
                                    </div>
                                </div>

                               { !loading && (
                                 <div className="botones-vista">
                                 <button onClick={sendAcceptedDate} id="aceptar">Propuesta enviada</button>
                                </div>
                               )}
                               { loading && (
                                 <div className="botones-vista">
                                 <button onClick={sendAcceptedDate} disabled={true} id="aceptar">Cargando...</button>
                                </div>
                               )}
                            </>
                        )}

                        {/* {(addmissionDate !== null && constants.ROLE_HR === userData.role_id) && <p>Propuesta enviada el {moment(addmissionDate).format('DD/MM/YYYY')}</p>} */}
                        {addmissionDate !== null && <p>Propuesta enviada el {moment(addmissionDate).format('DD/MM/YYYY')}</p>}

                        <div className="botones-vista">

                            {(module.completed === 0 && addmissionDate !== null) && (
                            <>
                                <div>
                                    <button id="aceptar" {... (module.writable === 0) } disabled={isLoading ? true : false} onClick={() => $('#avanza-busqueda-4').modal('show')} data-toggle="modal" data-target="#avanza-busqueda-4">Acepta propuesta</button>
                                    <button id="no_acepta" {... module.writable === 0 } disabled={isLoading ? true : false} onClick={() => $('#no-acepto').modal('show')} data-toggle="modal" data-target="#no-acepto">No acepta propuesta</button>
                                </div>
                            </>
                            )}

                            {/*<button id="no_acepta" {... disableEdit && {disabled: 'disabled'}} onClick={() => $('#no-acepto').modal('show')} data-toggle="modal" data-target="#no-acepto">No acepta propuesta</button>*/}


                        </div>
                        <div className="botones-vista">

                            {module.status === 1 && (
                                <>
                                    <button id="aceptar" disabled='disabled'>ACEPTÓ PROPUESTA</button>
                                    <button id="no_acepta" {... module.writable === 0 && {disabled: 'disabled'}} onClick={() => $('#no-acepto').modal('show')} data-toggle="modal" data-target="#no-acepto">No acepta propuesta</button>
                                </>
                            )}
                            {module.status === 2 && (
                                <>
                                    <button id="aceptar" {... (module.writable === 0) && {disabled: 'disabled'}}  onClick={() => $('#avanza-busqueda-4').modal('show')} data-toggle="modal" data-target="#avanza-busqueda-4">Acepta propuesta</button>
                                    <button id="no_acepta" disabled='disabled'>NO ACEPTÓ PROPUESTA</button>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal de avanza candidato */}
            <div className="modal fade" id="avanza-busqueda-4" tabIndex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
                        </div>
                        <div className="modal-body ">
                            <span className="texto-general">¿Confirmas la aceptación de la propuesta?</span>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" id="ok_avanza" data-dismiss="modal" onClick={() => handleInterview4()}>Ok</button>
                            <button type="button" className="btn btn-primary" id="cancel" data-dismiss="modal" onClick={() => { toggleClass() }}>Cancelar</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Modal de no acepta propuesta */}
            <div className="modal fade" id="no-acepto" tabIndex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-body">
                            <span className="titulo">Selecione el motivo por el cual <br /><span>No Avanza el candidato:</span></span>
                            <form>
                                <div className="content">
                                    <div>
                                        <input type="radio" id="salario"
                                               name="contact" value="salario" onChange={handleRBChange} />
                                        <label htmlFor="salario">SALARIO BAJO</label>
                                    </div>

                                    <div>
                                        <input type="radio" id="retienen"
                                               name="contact" value="retienen" onChange={handleRBChange} />
                                        <label htmlFor="retienen">LO RETIENEN EN SU EMPRESA ACTUAL</label>
                                    </div>

                                    <div>
                                        <input type="radio" id="oferta"
                                               name="contact" value="oferta" onChange={handleRBChange} />
                                        <label htmlFor="oferta">OTRA OFERTA</label>
                                    </div>

                                    <div>
                                        <input type="radio" id="fit"
                                               name="contact" value="fit" onChange={handleRBChange} />
                                        <label htmlFor="fit">NO HAY FIT CULTURAL</label>
                                    </div>
                                    <div>
                                        <input type="radio" checked id="otros"
                                               name="contact" value="otros" onChange={handleRBChange} />
                                        <label htmlFor="otros">OTROS</label>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary" id="guardar" data-dismiss="modal" onClick={() => handleRejectedInterview4()}>GUARDAR</button>
                            <button type="button" className="btn btn-primary" id="cancelar" data-dismiss="modal" onClick={() => { toggleClass() }}>CANCELAR</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Accordion4