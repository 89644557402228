// import css from '@emotion'
import ClipLoader from "react-spinners/ClipLoader";
import BarLoader from "react-spinners/BarLoader";

const LoadingList = ({ color = '#004b7f', padding = '70px 0', size=70 }) => {
  const style = {
    width: '100%',
    // height: '100%',
    // backgroundColor: '#004b7f11',
    textAlign: 'center',
    padding: padding
    // position: 'fixed',
    // top: '50%', 
    // left: '50%',
    // transform: 'translate(-50%, -50%)'
  }

  return (
    <div style={style} >
      <ClipLoader color={color} loading={true} css={""} size={size} />
      {/* <ClipLoader color='#004b7f' loading={true} css={""} size={150} /> */}
      {/* <BarLoader color='#111' loading={true} css={""} height={4} width={100} /> */}
    </div>
  )
}

export default LoadingList