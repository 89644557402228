// import { useEffect, useState } from "react";
// import { Button } from "react-bootstrap";
import ReactExport from "react-export-excel";
import download from '../../../../images/menu/download.png'
import '../report.css'
// import axios from 'axios'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const ExcelSearchesReport = ({data}) =>{
  return (
    <ExcelFile filename='Busquedas' element={<button className='boton-descargar-excel'><img src={download} width='20px' height='20px' alt='download'/></button>}>
      <ExcelSheet data={data} name="Busquedas" >
        <ExcelColumn label="ID de Busqueda" value="ID de Busqueda"/>
        <ExcelColumn label="Nombre de la busqueda" value="Nombre de la busqueda"/>
        <ExcelColumn label="Centro de Costos" value="Centro de Costos"/>
        <ExcelColumn label="Descripción de la Tarea" value="Descripción de la Tarea"/>
        <ExcelColumn label="Descripción del Perfil" value="Descripción del Perfil"/>
        <ExcelColumn label="Motivo de la Busqueda" value="Motivo de la Busqueda"/>
        <ExcelColumn label="Nombre del Creador" value="Nombre del Creador"/>
        <ExcelColumn label="Nombre del Director" value="Nombre del Director"/>
        <ExcelColumn label="Nombre del HM" value="Nombre del HM"/>
        <ExcelColumn label="Sector" value="Sector"/>
        <ExcelColumn label="Seniority" value="Seniority"/>
        <ExcelColumn label="Status Director" value="Status Director"/>
        <ExcelColumn label="Status RRHH" value="Status RRHH"/>
        <ExcelColumn label="Status de la Busqueda" value="Status de la Busqueda"/>
      </ExcelSheet>
    </ExcelFile>
  );
}

export default ExcelSearchesReport