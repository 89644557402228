

import { normalizeText } from "normalize-text";


export const filtroSinAcentos = (rows, columnIds, globalFilterValue) => {
    // Normalizar valor a buscar
    const normalizedGlobalFilterValue = normalizeText(globalFilterValue).toLowerCase(); 
    console.log(globalFilterValue)
    // Recorrer y filtrar todas las filas
    return rows.filter((row) => {
  
        // Recorrer las columnas indicadas en columnIds
        for (const columnId of columnIds) {
            const cellValue = normalizeText(String(row.values[columnId]));
  
            // Acá lo ideal sería comparar usando la función original de filtro, por ahora usamos esto
            if (cellValue.toLowerCase().includes(normalizedGlobalFilterValue)) return true;
        }
  
        // Si llegamos acá es porque ninguna de las celdas de la fila coincide con la búsqueda
        return false;
    });
  }

