import React, { useEffect, useRef, useState, useContext } from "react";
import curriculum from "../../../../../../images/menu/curriculum.png";
import { UserContext } from "../../../../../../context/userContext";
import { DataContext } from "../../../../../../context/dataContext";
import { API } from "../../../../../../http-common";
import $ from "jquery";
import moment from "moment";
import "../../../timeLineRRHH.css";
import LoadingList from "../../../../Loading/LoadingList";
/*Edit modules imports*/
import EditModule from "../EditModules";
import EditFeedback from "../EditFeedback";
import {Col, Container, Row} from "react-bootstrap";
import Select from "react-select";

const Accordion5 = ({
  available,
  setAvailable,
  idSearch,
  module,
  applicant,
  disableEdit,
  onDataChange = null
}) => {
  const {
    dataTableChanged,
    setDataTableChanged,
    currentSearchStatus,
    setCurrentSearchStatus,
  } = useContext(DataContext);
  const [submit, setSubmit] = useState(false);
  const { userData, setUserData } = useContext(UserContext);
  const [attributes, setAttributes] = useState(applicant.attribute)
  const [attributeTaking, setAttributeTaking] = useState(0)
  const [nameFile, setNameFile] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [feedback, setFeedback] = useState(module.data.feedback);
  const [moduleStatus, setModuleStatus] = useState(module.status);
  const [feedbackError, setFeedbackError] = useState(false);
  const [selectedFileError, setSelectedFileError] = useState(false);
  const [acceptedDate, setAcceptedDate] = useState()
  const [errorDate, setErrorDate] = useState(false)
  const [addmissionDate, setAdmissionDate] = useState(module.admission_date)
  const [loading, setLoading] = useState(false);
  /*EditModal states & function*/
  const [showModal, setShowModal] = useState(false);
  const [moduleWritable, setModuleWritable] = useState(module.writable);
  const [dataModule, setDataModule] = useState(module);
  const [feedbackDate, setFeedbackDate] = useState(
    dataModule.data.feedback_date !== '' ?
    moment(dataModule.data.feedback_date).format("YYYY-MM-DD") :
    moment(new Date()).format("YYYY-MM-DD")
);
const [isLoading, setIsLoading] = useState(false)

  const editionMode = () => {
    setModuleWritable(1);
    setShowModal(false);
  };

  useEffect(() => {
    changeAttributesValues(applicant.attributes)
  }, []);
  const changeAttributesValues = (attribute) => {
    let filterAttributes = attribute.filter((module) => [1, 2].includes(module.module_id))
    let SelectAttributes = filterAttributes.map((attribute) => {
      return {value:attribute.id_attributes   , label:attribute.attributes_name}
    })
    setAttributes(SelectAttributes)
  }

  const toggleClass = () => {
    let modal = document.getElementsByClassName("modal_edit_applicant");
    if(modal.length > 0){
      setTimeout(() => {
        document.body.classList.add("modal-open");
      }, 500)
    }
  }


  const isModal = document.getElementsByClassName("modal_edit_applicant");

  const file = useRef(null);

  const checkvalue = (nameInput, value) => {
    setLoading(true)
    setTimeout(() => {
      if (nameInput === "selectedFile") {
        setSelectedFile(value);
        setNameFile(value.name);
      }
      setLoading(false)
    }, 500);
    
  };

  /*const getCandidate = async () => {
    await API.get("/applications/timeline/" + applicant.applicant_id).then(
      function (response) {
        if (response.data.error === 0) {
          setDataModule(response.data.data.modules[4]);
          setFeedback(response.data.data.modules[4].data.feedback);
          setModuleStatus(response.data.data.modules[4].status);
        } else {
          //todo
        }
      }
    );
  };*/

  useEffect(async () => {
    if (submit && !feedbackError && !selectedFileError) {
      sendData();
    }
    // getCandidate();
    setSubmit(false);
  }, [submit]);

  const handleClick = (e) => {
    file.current.click();
  };

  // const onFileChange = file => {
  //   // Update the state
  //   setSelectedFile(file);
  // };

  // const form = new FormData();

  const sendData = () => {
    setIsLoading(true)
    const dataForm = {
      search_id: idSearch,
      candidate_id: applicant.candidate_id,
      applicant_id: applicant.applicant_id,
      module_id: 5,
      employees_id: userData.id,
      feedback_date: feedbackDate,
      feedback: feedback,
      accepted: null,
      link_note: selectedFile,
      status: moduleStatus,
      attributes: moduleStatus === 2 ? attributeTaking : 0
    };

    const formData = new FormData();
    if (selectedFile !== "") {
      const ext = selectedFile.name.substr(selectedFile.name.lastIndexOf("."));
      const fileName =
        `NOTAS_PSICOLOGO_CANDIDATO_${applicant.dni}_${applicant.username}${ext}`
          .split(" ")
          .join("_")
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
      formData.append("selectedFile", dataForm.link_note, fileName);

      dataForm.link_note = fileName;
    }
    // formData.append('selectedFile', selectedFile, selectedFile.name)
    const module_id = dataModule.id ?? 0;
    try {
      API.post("/applications/timeline/"+applicant.applicant_id+"/module/"+module_id, dataForm)
      .then((response) => {
        // Petición para el envío de documento
        API.post("/applications/file", formData)
          .then((response) => {})
          .catch((error) => {});
        setModuleWritable(0);
        setDataModule({
          ...dataModule,
          id: response.data.id
        })
        if(null !== onDataChange) onDataChange()
        toggleClass()
        setIsLoading(false)

      })
    }
    catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  };

  const handleInterview5 = (e) => {
    let status = e.currentTarget.id;
    switch (status) {
      case "ok_avanza":
        setModuleStatus(1);
        break;
      case "ok_no_avanza":
        setModuleStatus(2);
        break;
      case "ok_guarda":
        setModuleStatus(3);
        break;
      case "avanza-con-reserva5":
        setModuleStatus(8);
        break;
      default:
        setModuleStatus(0);
    }
    setSubmit(true);
    checkInputs();
  };

  const checkInputs = () => {
    if (feedback.length === 0) {
      setFeedbackError(true);
    } else {
      setFeedbackError(false);
    }

    /*if (module.completed === 0 && selectedFile.length === 0) {
      setSelectedFileError(true);
    } else {
      setSelectedFileError(false);
    }*/
  };

  const handleChange = e => {
    const { value } = e.target;
    setAcceptedDate(value)
  }

  const sendAcceptedDate = () => {
    const dataForm = {
        search_id: idSearch,
        candidate_id: applicant.candidate_id,
        applicant_id: applicant.applicant_id,
        module_id: 5,
        employees_id: userData.id,
        feedback_date: feedbackDate,
        feedback: feedback,
        accepted: null,
        link_note: null,
        admission_date: acceptedDate,
        status: 3
    }
    setLoading(true)
    setErrorDate(false)
    if(!acceptedDate){
        setErrorDate(true)
    }else{
        try {
        const module_id = dataModule.id ?? 0;
        API.post("/applications/timeline/"+applicant.applicant_id+"/module/"+module_id, dataForm)
        .then((response) => {
            setAdmissionDate(acceptedDate)
            setDataModule({
              ...dataModule,
              id: response.data.id
            })
            if (null !== onDataChange) onDataChange()
            toggleClass()
            setLoading(false)
        })
        }
        catch(e){
          console.log(e)
          setLoading(false)
        }
    }
  }

  const handdleDateChange = (e) => {
    const {value} = e.target;
    setFeedbackDate(value)
  }

  return (
    <>
      <div className="card">
        <div className="card-header" id="psicotecnica-heading">
          <h5 className="mb-0">
            <button
              className="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#psicotecnica"
              aria-expanded="false"
              aria-controls="psicotecnica"
            >
              Psicotecnico
            </button>
            <span
              className="mas"
              data-toggle="collapse"
              data-target="#psicotecnica"
              aria-expanded="false"
              aria-controls="psicotecnica"
            >
              +
            </span>
          </h5>
        </div>

        <div
          id="psicotecnica"
          className={"collapse show"}
          aria-labelledby="psicotecnica-heading"
        >

          <div
            className={`card-body ${
              (disableEdit || moduleWritable === 0) && "disabled"
            }`}
          >
          {(null !== addmissionDate || module.completed === 1) && (
            <>
              
                {null !== addmissionDate ? <p>Agendado para el día {moment(addmissionDate).format('DD/MM/YYYY')}</p> : <p>Sin fecha de examen</p>}

                {/* <div className={`card-body`} > */}
                <div className="barra-superior">
                  <div
                    className={
                      disableEdit ||
                      (moduleWritable === 0 && dataModule.completed === 1)
                        ? "left disabled-accordion"
                        : "left"
                    }
                  >
                    {/* <div className="left"> */}
                    {moduleWritable === 1 ? (
                      <div className="item">
                        <span>Fecha</span>
                        <input style={{margin: '15px 0'}} type="date" onChange={handdleDateChange} value={feedbackDate} />
                      </div>
                    ) : (
                      <div className="item">
                        <span>Fecha</span>
                        <label>{moment(feedbackDate).format("DD/MM/YYYY")}</label>
                      </div>
                    )}

                    <div className="item">
                      <span>Entrevista realizada por</span>
                      <label>
                        {dataModule.data.interviewer.username !== ""
                          ? dataModule.data.interviewer.username
                          : userData.role_id === 5
                          ? userData.username
                          : ""}
                      </label>
                    </div>
                  </div>
                  <div className="right">
                    {disableEdit ||
                    (moduleWritable === 0 && dataModule.completed === 1) ? (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={
                          dataModule.data.link.length > 0
                            ? dataModule.data.link
                            : "#"
                        }
                      >
                        <div className="curriculum notas">
                          <img src={curriculum} alt="CV" />
                          VER NOTAS
                        </div>
                      </a>
                    ) : (
                      <>
                        <input
                          accept=".pdf,.docx,.doc"
                          className="d-none"
                          ref={file}
                          name="selectedFile"
                          type="file"
                          onChange={(e) =>
                            checkvalue(e.target.name, e.target.files[0])
                          }
                        />
                        {
                          loading ? (
                            <>
                            <div
                          onClick={(e) => handleClick()}
                          className="curriculum notas"
                        >
                          <LoadingList size={15} padding="0" color="#fff"/>
                        </div>
                        <div className="mensajes">
                          <span
                            className={selectedFileError ? "span-error" : "d-none"}
                          >
                            Campo Vacio
                          </span>
                        </div>
                            </>
                          ) : (
                            <>
                            <div
                          onClick={(e) => handleClick()}
                          className="curriculum notas"
                        >
                          <img src={curriculum} alt="CV" />
                          ADJUNTAR NOTAS
                        </div>
                        <div className="mensajes">
                          <span>{nameFile}</span>
                          <span
                            className={selectedFileError ? "span-error" : "d-none"}
                          >
                            Campo Vacio
                          </span>
                        </div> 
                            </>
                          )
                        }  
                      </>
                    )}
                  </div>
                </div>
                <div
                  className="content-feedback">
                  <span>Feedback</span>
                  <textarea
                    id="feedback-psicotecnica"
                    cols="30"
                    rows="10"
                    placeholder=""
                    value={feedback}
                    onChange={(e) => setFeedback(e.target.value)}
                    readOnly={moduleWritable === 0 ? true : false}
                    disabled={false}
                  ></textarea>
                  <span className={feedbackError ? "span-error" : "d-none"}>
                    Campo Vacio
                  </span>
                </div>
                {moduleWritable === 1 && (
                  <div className="botones-vista">
                    <button
                      id="aprobar"
                      onClick={() => $("#avanza-busqueda-5").modal("show")}
                      disabled={isLoading ? true : false}
                    >
                      AVANZA
                    </button>

                    <button
                        id="aprobar_reserva"
                        onClick={() => $("#avanza-reserva-busqueda-5").modal("show")}
                        data-toggle="modal"
                        data-target="#avanza-reserva-busqueda-5"
                        disabled={isLoading ? true : false}
                    >
                      AVANZA CON RESERVA
                    </button>

                    <button
                        id="negar_1"
                        onClick={() => $("#no-avanza-busqueda-5").modal("show")}
                        data-toggle="modal"
                        data-target="#no-avanza-busqueda-5"
                        disabled={isLoading ? true : false}
                    >
                      NO AVANZA
                    </button>
                    
                    {/* <button
                      id="decidir"
                      onClick={() => $("#decidir-busqueda-5").modal("show")}
                    >
                      DECIDIR MÁS TARDE
                    </button> */}
                  </div>
                )}
                {moduleWritable === 0 && (
                  <div className="estado">
                    {(() => {
                      switch (dataModule.status) {
                        case 0:
                          return <span className="pausado">PENDIENTE</span>;
                          break;
                        case 1:
                          //return (<span className="aprobado">AVANZA</span>);
                          return (
                            <span className="aprobado">AVANZA PSICOTECNICO</span>
                          );
                          break;
                        case 2:
                          return <span className="negado">NO AVANZA PSICOTECNICO</span>;
                          break;
                        case 3:
                          return (
                            <span className="pausado">
                              GUARDADO PARA OTRA BÚSQUEDA
                            </span>
                          );
                          break;
                        case 8:
                          return <span className="aprobado">AVANZA CON RESERVAS PSICOTECNICO</span>;
                          break;
                        default:
                          return <span className="pausado">PENDIENTE</span>;
                      }
                    })()}
                  </div>
                )}
                {dataModule.completed === 1 &&
                  dataModule.editable === "feedback" && (
                    <EditFeedback
                      setModuleWritable={setModuleWritable}
                      sendData={sendData}
                      feedbackError={feedbackError}
                    />
                  )}
                {dataModule.completed === 1 && dataModule.editable === "all" && (
                  <EditModule
                    editionMode={editionMode}
                    showModal={showModal}
                    setShowModal={setShowModal}
                  />
                )}
            </>
          )}


          {(module.completed === 0 && null === addmissionDate) && (
            <>
              <div className="barra-superior">
                  <div className="left">
                      <div className="item">
                          <span>Fecha</span>
                          <input type="date" onChange={handleChange} value={acceptedDate} />
                          <span className={errorDate ? "span-error" : "d-none"}>
                              Campo Vacio
                          </span>
                      </div>
                  </div>
              </div>

              {
                  loading && (
                <div className="botones-vista">
                  <button onClick={sendAcceptedDate} id="aceptar" disabled={true}>Cargando...</button>
                </div>
                  )
                }
                  {
                    !loading && (
                <div className="botones-vista">
                  <button onClick={sendAcceptedDate} id="aceptar">Entrevista agendada</button>
                </div>
                    )
                  }
            </>
          )}
          </div>

        </div>
      </div>

      <div
        className="modal fade"
        id="avanza-busqueda-5"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="basicModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body ">
              <span className="texto-general">
                Al tocar el botón OK se enviará el feedback de la entrevista.
                ¿Estás seguro de proseguir con el envío?
              </span>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                id="ok_avanza"
                data-dismiss="modal"
                onClick={handleInterview5}
              >
                Ok
              </button>
              <button
                type="button"
                className="btn btn-primary"
                id="cancel"
                data-dismiss="modal"
                onClick={() => {
                  toggleClass()
                }}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal de avanza candidato pero con reserva*/}
      <div
          className="modal fade"
          id="avanza-reserva-busqueda-5"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="basicModal"
          aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body ">
              <span className="texto-general">
                ¿Estas seguro de avanzar con este candidato con reserva?
              </span>
            </div>
            <div className="modal-footer">
              <button
                  type="button"
                  className="btn btn-primary"
                  id="avanza-con-reserva5"
                  data-dismiss="modal"
                  onClick={handleInterview5}
              >
                Ok
              </button>
              <button
                  type="button"
                  className="btn btn-primary"
                  id="cancel"
                  data-dismiss="modal"
                  onClick={() => {
                    toggleClass()
                  }}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal de no avanza candidato */}
      <div
          className="modal fade"
          id="no-avanza-busqueda-5"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="basicModal"
          aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body ">
              <Container>
                <span className="texto-general">
                    ¿Estas seguro que deseas rechazar el candidato? <br />
                    {/* Por favor seleccione el motivo por el cual lo rechaza*/}
                  </span>
              </Container>
            </div>
            <div className="modal-footer">
              <button
                  type="button"
                  className="btn btn-primary"
                  id="ok_no_avanza"
                  data-dismiss="modal"
                  onClick={(e) => handleInterview5(e)}
              >
                Ok
              </button>
              <button
                  type="button"
                  className="btn btn-primary"
                  id="cancel"
                  data-dismiss="modal"
                  onClick={() => {
                    toggleClass()
                  }}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* <div
        className="modal fade"
        id="decidir-busqueda-5"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="basicModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body ">
              <span className="texto-general">
                ¿Estás seguro que deseas decidir mas tarde?
              </span>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                id="ok_dicidir"
                data-dismiss="modal"
                onClick={handleInterview5}
              >
                Ok
              </button>
              <button
                type="button"
                className="btn btn-primary"
                id="cancel"
                data-dismiss="modal"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Accordion5;
