import React from "react";
import editar from "../../../../../images/menu/editar.png";

const EditFeedback = ({ setModuleWritable, sendData, feedbackError }) => {

  const editFeedback = () => {
    let editButton = document.getElementById("button-text");
    if (editButton.innerHTML.includes("EDITAR FEEDBACK")) {
      editButton.innerHTML = "GUARDAR CAMBIOS";
      setModuleWritable(1);
    } else {
      editButton.innerHTML = "EDITAR FEEDBACK";
      setModuleWritable(0);
      if (!feedbackError) {
        sendData();
      }
    }
  };

  return (
    <div className="botones-vista edit-button">
      {" "}
      <button id="edit" onClick={() => editFeedback()}>
        <img src={editar} alt="pen" />{" "}
        <span style={{ margin: "0 10px 0 0;" }} id="button-text">EDITAR FEEDBACK</span>
      </button>{" "}
    </div>
  );
};

export default EditFeedback;
