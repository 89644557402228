import React from "react";
import { Modal, Button } from "react-bootstrap";
import editar from "../../../../../images/menu/editar.png";

const EditModules = ({ editionMode, showModal, setShowModal, buttonStyle }) => {

  const handleClose = () => setShowModal(false);
  return (
    <>
      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        onHide={handleClose}
        className="modal-edit"
      >
        <Modal.Header className="modal-edit-header" closeButton />

        <Modal.Body>
          <div className="edit-modal-content">
            <p>
              ¿Estás seguro que vas a modificar los datos? Esta acción no se
              puede deshacer.
            </p>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button onClick={handleClose}>
            Cancelar
          </Button>
          <Button onClick={editionMode}>
            Editar
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="edit-button">
        {" "}
        <button id="edit" style={buttonStyle} onClick={() => setShowModal(true)}>
          <img style={{ margin: "0 0 0 10px" }} src={editar} alt="pen" /> {"  "}
          EDITAR{" "}
        </button>{" "}
      </div>
    </>
  );
};

export default EditModules;
