import React, { useContext, useState } from "react"
import { NavLink, Link } from "react-router-dom"
import {UserContext} from "../../../context/userContext";
import * as Constants from '../../../constants'

const Menu = () => {
  const { userData, setUserData} = useContext(UserContext)

  const role = userData.role_id;

  const [menuMobile, setmenuMobile] = useState(true)
  const toggle = React.useCallback(() => setmenuMobile(!menuMobile));
  return (
    <div className="relative-menu">
      <div className="menu">
        <div className="content-menu">
          <ul className={`lista ${menuMobile ? '' : 'mobile'}`}>
            { Constants.Manager.includes(role) &&<li><NavLink exact={true} activeClassName='is-active' to="/busqueda" >BÚSQUEDAS</NavLink></li> }
            { Constants.Manager.includes(role) && <li><NavLink exact={true} activeClassName='is-active' to="/crear_busqueda">CREAR NUEVA BÚSQUEDA</NavLink></li> }
            { Constants.Director.includes(role) && <li><NavLink exact={true} activeClassName='is-active' to="/aprobar_busqueda">APROBAR BÚSQUEDA</NavLink></li> }
            { Constants.RoleAll.includes(role) && <li><NavLink exact={true} activeClassName='is-active' to="/candidatos">CANDIDATOS</NavLink></li> }
            { Constants.Manager.includes(role) && <li><NavLink exact={true} activeClassName='is-active' to="/crear_candidato">CREAR NUEVO CANDIDATO</NavLink></li> }
            { Constants.RRHH.includes(role) && <li><NavLink exact={true} activeClassName='is-active' to="/hunt">HUNTEO</NavLink></li> }
          </ul>
          <button className="hamburger hamburger--squeeze"  type="button" onClick={toggle}>
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>
        </div>
      </div>
    </div>
  )

}

export default Menu
