import { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { API, setToken } from '../../http-common';
import './login.css'
import { useHistory } from 'react-router-dom'
import { UserContext } from '../../context/userContext';

const LoginCallback = (req) => {
  const params = new URLSearchParams(req.location.search);
  const user = params.get('user')
  const nonce = params.get('nonce')
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const history = useHistory()

  const { setUserData, setIsLogged } = useContext(UserContext)

  useEffect(() => {
      API.post('/auth/login', {user, nonce})
      .then(function (response) {
        setToken(response.data.accessToken); 
        setUserData(response.data.data);
        setIsLogged(true);
        history.push('/')
      })
      .catch(e => {
        setLoading(false)
        setError(true)
      })
  }, [user, nonce, setLoading, setError])

  return (
    <div className="content-login">
      <div className="loading-wrapper">
        {loading && (
          <>
            <div className="loading"></div>
            <div className='content'>
                <h3>Iniciando sesión</h3>
            </div>
          </>
        )}

        {!loading && error &&  (
          <>
            <div className='content'>
                <h3>Ocurrió un error al iniciar sesión</h3>
                <div className='text-center mt-3'>
                  <button type="button" class="btn-ingresar" onClick={() => {
                    history.push('/login')
                  }}>Reintentar</button>
                </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
};

export default LoginCallback;
