// import Footer from "../../includes/Footer/Footer"
import Header from "../../includes/Header/Header";
import Menu from "../../includes/Menu/Menu";
import "./timeLineRRHH.css";
import derecha from "../../../images/menu/derecha.png";
import { Link, useLocation } from "react-router-dom";
import React, { useState, useEffect, useContext } from "react";
import TimeLineSearch from "./TimeLineSearch/TimeLineSearch";
import ShowApplicants from "./ShowApplicants/ShowApplicants";
import { API } from "../../../http-common";
import { DataContext } from "../../../context/dataContext";
import { UserContext } from "../../../context/userContext";
import SnackbarProvider from "react-simple-snackbar";
import Loading from "../Loading/Loading";
import AccessError from "../../Error/AccessError/AccessError";

const TimeLineRRHH = (req) => {
  //const { query } = useLocation();

  const { currentSearchStatus, setCurrentSearchStatus } =
    useContext(DataContext);
  const [isLoading, setIsLoading] = useState(true);
  const [phase1, setPhase1] = useState(true);
  const [phase2, setPhase2] = useState(false);
  const { userData, setUserData } = useContext(UserContext);
  const [preselected, setPreselected] = useState(null);

  const [search, setSearch] = useState([]);
  const idSearch = req.match.params.id;
  const [departaments, setDepartaments] = useState([]);
  const [departaments_intranet, setDepartaments_intranet] = useState([]);

  const [applicants, setApplicants] = useState({});
  //*ojo highlighted es Destacado y outstanding es resaltado ...  lo dejamos asi porq ya esta pero recuerden la propiedad correcta para enviar la data
  const [applicantsOutstanding, setApplicantsOutstanding] = useState({});
  const [applicantsPreselected, setApplicantsPreselected] = useState({});

  const [error, setError] = useState({error:0, message:""});

  // function getApplicant(id) {
  //   API.get('/applications/'+idSearch+'/'+id)
  //     .then(function (response) {
  //       if (response.data.error === 0) {
  //         setInfo(response.data.data)
  //       } else {
  //           //todo
  //       }
  //     })
  // }

  function getApplicants() {
    API.get("/applications/" + idSearch).then(function (response) {

      if (response.data.error === 0) {
        setApplicants(response.data.data.tabs[0]);
        setApplicantsOutstanding(response.data.data.tabs[1]);
        setApplicantsPreselected(response.data.data.tabs[2]);
        setCurrentSearchStatus(response.data.data.timeline.status);
        // setDataTableChanged(false)
      } else {
        //TODO
      }
      setIsLoading(false);
    });
  }

  function getSearche() {
    setIsLoading(true);
    API.get("/positions/" + idSearch).then(function (response) {
      if (response.data.error === 1 && response.data.status === 403) {
        setIsLoading(false)
        setError({error: response.data.error, message: response.data.message});
      }
      else if (response.data.error === 0) {
        setSearch(response.data.data);
        getApplicants();
      } else {
        setIsLoading(false)
        setError({error: response.data.error});
      }
    });

    // setIsLoading(false)
  }

  function getDepartaments() {
    API.get('/positions/departaments')
        .then(function (response) {
            if (response.data.error === 0) {
                setDepartaments(response.data.data)
            } else {
                //todo
            }
        })
  }
  function getDepartaments_intranet() {
    API.get('/positions/departaments_intranet')
        .then(function (response) {
            if (response.data.error === 0) {
                setDepartaments_intranet(response.data.data)
            } else {
                //todo
            }
        })
  }

  // useEffect(() => {
  //   getSearche()
  // }, [dataTableChanged]);

  const clearTableState = () => {
    localStorage.removeItem('TimelineTableStateId');
  }

  useEffect(() => {
    clearTableState();
    getSearche();
    getDepartaments();
    getDepartaments_intranet()
  }, []);

  const setPhase = (phase) => {
    if (phase === "paso_1") {
      setPhase1(true);
      setPhase2(false);
    } else {
      setPhase1(false);
      setPhase2(true);
    }
  };

  useEffect(() => {
    const query = window.location.hash;
    if (query) {
      let candidateUrl = localStorage.getItem("candidateUrl");
      if (candidateUrl) {
        localStorage.removeItem("candidateUrl");
      }
      let candidateNum = query.split("=")[1];
      setPreselected(candidateNum);
      setPhase("paso_2");
    }
  }, [applicantsPreselected]);

  if (isLoading === true) {
    return <Loading />;
  } else {
    return (
      <>
        <Header />
        <Menu />
        {
        error.error ? <AccessError errorMessage={error.message} buttonLink='/busqueda'/> :
        (
          <div className="contenido-principal">
            <div className="content-time-line">
              <div className="time-line-header">
                <span className="ruta">
                  BÚSQUEDA {search.id}{" "}
                  <b className="cargo">{search.position_name}</b>
                </span>
                {userData.role_id === 5 ? null : (
                  <span className="volver">
                    <img src={derecha} alt="" />
                    <Link to="/busqueda">VOLVER AL LISTADO</Link>
                  </span>
                )}
              </div>
              {userData.role_id === 5}
              <div className="time-line">
                <div className="time-line-absolute">
                  <div
                  //TODO: id duplicado
                    id="itemscreening"
                    className="item completo"
                    id="paso_1"
                    onClick={() => setPhase("paso_1")}
                  >
                    <div className="numero">1</div>
                    <span className="seccion">Screening</span>
                  </div>
                  <div className="separcion"></div>
                  <div
                    className={
                      currentSearchStatus > 2 ? "item completo" : "item active"
                    }
                    id="paso_2"
                    onClick={() => setPhase("paso_2")}
                  >
                    <div className="numero ">2</div>
                    <span className="seccion">Selección</span>
                  </div>
                  <div className="separcion"></div>
                  <div
                    className={
                      currentSearchStatus < 3
                        ? "item"
                        : currentSearchStatus > 3
                        ? "item completo"
                        : "item active"
                    }
                    id="paso_3"
                    onClick={() => setPhase("paso_3")}
                  >
                    <div className="numero">3</div>
                    <span className="seccion">Destacados</span>
                  </div>
                  <div className="separcion"></div>
                  <div
                    id="paso_4"
                    className={
                      currentSearchStatus < 4
                        ? "item"
                        : currentSearchStatus > 4
                        ? "item completo"
                        : "item active"
                    }
                  >
                    <div className="numero">4</div>
                    <span className="seccion">Hiring Manager</span>
                  </div>
                  <div className="separcion"></div>
                  <div
                    className={
                      currentSearchStatus < 5
                        ? "item"
                        : currentSearchStatus > 5
                        ? "item completo"
                        : "item active"
                    }
                    id="paso_5"
                  >
                    <div className="numero">5</div>
                    <span className="seccion">Propuesta</span>
                  </div>
                  <div className="separcion"></div>
                  <div
                    className={
                      currentSearchStatus < 6
                        ? "item"
                        : currentSearchStatus > 6
                        ? "item completo"
                        : "item active"
                    }
                    id="paso_6"
                  >
                    <div className="numero">6</div>
                    <span className="seccion">Contratación</span>
                  </div>
                </div>
              </div>
              <div style={{ marginTop: "60px" }}>
                <div className="content-pestana-busqueda">
                  <div
                    className="pestana"
                    id="pestana-candidatos"
                    onClick={() => setPhase("paso_1")}
                  >
                    <span>INFORMACIÓN</span>
                  </div>
                  <div
                    className="pestana"
                    id="pestana-destacados"
                    onClick={() => setPhase("paso_2")}
                  >
                    <span>ACTIVIDAD</span>
                  </div>
                </div>
                <div className="busqueda timeline_seleccion">
                  {phase1 ? (
                    // <SnackbarProvider>
                    <div>
                      <TimeLineSearch departaments={departaments} search={search} departaments_intranet={departaments_intranet} />
                    </div>
                  ) : // </SnackbarProvider>
                  null}
                  {phase2 ? (
                    <ShowApplicants
                      applicants={applicants}
                      applicantsOutstanding={applicantsOutstanding}
                      applicantsPreselected={applicantsPreselected}
                      // req={req}
                      search={idSearch}
                      searchName={search.position_name}
                      preselected={preselected}
                      setPreSelected={setPreselected}
                      departaments={departaments}
                      departaments_intranet={departaments_intranet}
                      generic={search.corporate === 1 && search.id_departament === 0}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        )
        }
        {/* <Footer /> */}
      </>
    );
  }
};

export default TimeLineRRHH;
