import React, {useState, useEffect } from 'react';

export const DataContext = React.createContext([]);

export const DataProvider = (props) => {
    const [dataTableChanged, setDataTableChanged] = useState([]);
    const [currentSearchStatus, setCurrentSearchStatus] = useState(2);
    const [currentPage, setCurrentPage] = useState(0)

    return (
        <DataContext.Provider value={{dataTableChanged, setDataTableChanged, currentSearchStatus, setCurrentSearchStatus, currentPage, setCurrentPage}}>
            {props.children}
        </DataContext.Provider>
    )
}