// import '../timeLineRRHH.css'
import { useState, useEffect, useContext, useRef } from "react";
// import Footer from '../../../includes/Footer/Footer'
import ListApplicants from "./ListApplicants/ListApplicants";
import ListApplicantsOutstanding from "./ListApplicantsOutstanding/ListApplicantsOutstanding";
import ListApplicantsPreselected from "./ListApplicantsPreselected/ListApplicantsPreselected";
import { API, deleteToken, getToken, setToken } from "../../../../http-common";
import { DataContext } from "../../../../context/dataContext";
import LoadingList from "../../Loading/LoadingList";

// const ShowApplicants = ({applicants, applicantsOutstanding, applicantsPreselected, req}) => {
const ShowApplicants = ({
  applicants,
  applicantsOutstanding,
  applicantsPreselected,
  search,
  preselected,
  setPreSelected,
  searchName,
  departaments,
  generic
}) => {
  const firstUpdate = useRef(true);
  const {
    dataTableChanged,
    setDataTableChanged,
    currentSearchStatus,
    setCurrentSearchStatus,
  } = useContext(DataContext);
  const [isLoading, setIsLoading] = useState(false);

  const [applicantsList, setApplicantsList] = useState({});
  //*ojo highlighted es Destacado y outstanding es resaltado ...  lo dejamos asi porq ya esta pero recuerden la propiedad correcta para enviar la data
  const [applicantsOutstandingList, setApplicantsOutstandingList] = useState(
    {}
  );
  const [applicantsPreselectedList, setApplicantsPreselectedList] = useState(
    {}
  );

  const [optionApplicant, setOptionApplicant] = useState(false);
  //*ojo highlighted es Destacado y outstanding es resaltado ...  lo dejamos asi porq ya esta pero recuerden la propiedad correcta para enviar la data
  const [optionApplicantOutstanding, setOptionApplicantOutstanding] =
    useState(false);
  const [optionApplicantPreselected, setOptionApplicantPreselected] =
    useState(false);
  const idSearch = search;

  function getApplicants() {
    setIsLoading(true);
    API.get("/applications/" + idSearch).then(function (response) {
      if (response.data.error === 0) {
        setApplicantsList(response.data.data.tabs[0]);
        setApplicantsOutstandingList(response.data.data.tabs[1]);
        setApplicantsPreselectedList(response.data.data.tabs[2]);
        setCurrentSearchStatus(response.data.data.timeline.status);
        if (firstUpdate.current) {
          firstUpdate.current = false;
          setDefaultTab();
        }
        setDataTableChanged(false);

        if (preselected && applicantsPreselectedList?.data) {
          setOptionApplicantPreselected(true);
          setOptionApplicant(false);

          let candidateRow = document.getElementsByClassName(preselected)[0];
          candidateRow.click();
        }
      } else {
        //todo
      }
      setIsLoading(false);
    });
  }

  useEffect(() => {
    getApplicants();
    // setDefaultTab()
  }, [dataTableChanged]);

  const setDefaultTab = () => {
    if (applicants.visible === 1) {
      setOptionApplicant(true);
    } else if (applicants.visible === 0) {
      setOptionApplicantPreselected(true);
    }
  };

  const onShowOption = (option) => {
    if (option === "applicants") {
      setOptionApplicant(true);
      setOptionApplicantOutstanding(false);
      setOptionApplicantPreselected(false);
    } else if (option === "outstanding") {
      setOptionApplicant(false);
      setOptionApplicantOutstanding(true);
      setOptionApplicantPreselected(false);
    } else if (option === "preselected") {
      setOptionApplicant(false);
      setOptionApplicantOutstanding(false);
      setOptionApplicantPreselected(true);
    }
    setDataTableChanged(true);
  };

  // if (isLoading) { return (<div style={{textAlign: 'center', marginLeft: '5vw'}}> 'Loading...' </div>)
  // } else {
  //   return (
  //     <>
  //       <div className="candidatos" >
  //         <div className="content-pestana">
  //           <div className={`${optionApplicant ? "pestana active" : "pestana"} ${applicants.visible === 1 ? '' : 'd-none'}`} id="pestana-candidatos" onClick={() => onShowOption('applicants')} >
  //             <span>CANDIDATOS</span>
  //           </div>
  //           <div className={`${optionApplicantOutstanding ? "pestana active" : "pestana"} ${applicantsOutstanding.visible === 1 ? '' : 'd-none'}`} id="pestana-destacados" onClick={() => onShowOption('outstanding')} >
  //             <span>CANDIDATOS DESTACADOS</span>
  //           </div>
  //           <div className={`${optionApplicantPreselected ? "pestana active" : "pestana"} ${applicantsPreselected.visible === 1 ? '' : 'd-none'}`} id="pestana-preseleccionados" onClick={() => onShowOption('preselected')} >
  //             <span>CANDIDATOS PRESELECCIONADOS</span>
  //           </div>
  //         </div>

  //         {optionApplicant ? <ListApplicants applicants={applicants.data} idSearch={idSearch} /> : null}
  //         {optionApplicantOutstanding ? <ListApplicantsOutstanding applicantsOutstanding={applicantsOutstanding.data} idSearch={idSearch} /> : null}
  //         {optionApplicantPreselected ? <ListApplicantsPreselected applicantsPreselected={applicantsPreselected.data} idSearch={idSearch} /> : null}
  //       </div>
  //     </>
  //   )
  // }

  return (
    <>
      <div className="candidatos">
        <div className="content-pestana">
          <div
            className={`${optionApplicant ? "pestana active" : "pestana"} ${
              applicantsList.visible === 1 ? "" : "d-none"
            }`}
            id="pestana-candidatos"
            onClick={() => onShowOption("applicants")}
          >
            <span>POSTULANTES</span>
          </div>
          <div
            className={`${
              optionApplicantOutstanding ? "pestana active" : "pestana"
            } ${applicantsOutstandingList.visible === 1 ? "" : "d-none"}`}
            id="pestana-destacados"
            onClick={() => onShowOption("outstanding")}
          >
            <span>POSTULANTES DESTACADOS</span>
          </div>
          <div
            className={`${
              optionApplicantPreselected ? "pestana active" : "pestana"
            } ${applicantsPreselectedList.visible === 1 ? "" : "d-none"}`}
            id="pestana-preseleccionados"
            onClick={() => onShowOption("preselected")}
          >
            <span>POSTULANTES PRESELECCIONADOS</span>
          </div>
        </div>
        {/* {isLoading && <LoadingList />} */}

        {optionApplicant ? (
          <ListApplicants
            applicants={applicantsList.data}
            idSearch={idSearch}
            searchName={searchName}
            departaments={departaments}
            generic={generic}
          />
        ) : null}
        {optionApplicantOutstanding ? (
          <ListApplicantsOutstanding
            applicantsOutstanding={applicantsOutstandingList.data}
            idSearch={idSearch}
            searchName={searchName}
            departaments={departaments}
            generic={generic}
          />
        ) : null}
        {optionApplicantPreselected ? (
          <ListApplicantsPreselected
            applicantsPreselected={applicantsPreselectedList.data}
            idSearch={idSearch}
            searchName={searchName}
            setPreselected={setPreSelected}
            departaments={departaments}
            generic={generic}
          />
        ) : null}
      </div>
    </>
  );
};

export default ShowApplicants;
