import React,{ useEffect, useState, useRef, useContext } from "react"
import './compensation.css'
import {UserContext} from '../../../../context/userContext';
import derecha from "../../../../images/menu/derecha.png"
import Header from '../../../includes/Header/Header'
import Menu from '../../../includes/Menu/Menu'
import Footer from '../../../includes/Footer/Footer'
import { Link } from 'react-router-dom'
import {API, deleteToken, getToken, setToken} from '../../../../http-common'
import Modal from '../../../../../node_modules/react-bootstrap/Modal'
import Button from '../../../../../node_modules/react-bootstrap/Button'
import { useHistory } from '../../../../../node_modules/react-router-dom';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { PRODUCTION_URL, ApproveRRHH, ApproveDIRECTOR, RRHH } from "../../../../constants";
import {DropdownButton, Dropdown} from 'react-bootstrap'
import { Alert } from "react-bootstrap";
import AccessError from "../../../Error/AccessError/AccessError";

const Compensation = (req) => {
  const idSearch = req.match.params.id
  const [search, setSearch] = useState([])
  const {userData, setUserData} = useContext(UserContext)
  const [isLoading, setIsLoading] = useState(false)
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const [showrechazar, setShowrechazar] = useState(false);
  const handleCloseRechzar = () => setShowrechazar(false);
  const handleShowRechazar = () => setShowrechazar(true);

  const [error, setError] = useState({error:0, message:""});

  
  const history = useHistory()

  function getSearche() {
    API.get('/positions/'+idSearch + '?compensation=true') 
      .then(function (response) {
        if (response.data.error === 1 && response.data.status === 403) {
          setError({error: response.data.error, message: response.data.message});
        }
          else if (response.data.error === 0) {
            setSearch(response.data.data)
          } else {
            setError({error: response.data.error});
          }
      })
  }

  function approve() {
    setIsLoading(true)
    try {
      API.put('/positions/approve/'+idSearch)
      .then(function (response) {
        handleShow()
        setIsLoading(false)
      })
    }
    catch (e) {
      console.log(e)
      setIsLoading(false)
    }
  }

  function disapprove() {
    setIsLoading(true)
    try {
      API.put('/positions/disapprove/'+idSearch)
      .then(function (response) {
        handleShowRechazar()
        setIsLoading(false)
      })
    }
    catch (e) {
      console.log(e)
      setIsLoading(false)
    }
  }

  const setCorporateStatus = () => {
    const data = {
      corporate: 0,
      id_departament: ''
    }
    API.post(`positions/corporate/status/${search.id}`, data)
      .then(response => {
        if(response.data.error === 0){
          setSearch(old => ({
            ...old,
            corporate: 0
          }))
        }
      })
  }
  const setIntranetStatus = () => {
    const data = {
      intranet: 0,
      id_departament_intranet: ''
    }
    API.post(`positions/intranet/status/${search.id}`, data)
      .then(response => {
        if(response.data.error === 0){
          setSearch(old => ({
            ...old,
            intranet: 0
          }))
        }
      })
  }

  useEffect(() => {
      getSearche()
  }, []);



  return (
    <>
      <Header />
      <Menu />
      {
        error.error ? <AccessError errorMessage={error.message} buttonLink='/busqueda'/> :
        (
      <div className="content-compensacion">
          <div className="compesacion-header">
              <span className="ruta">BÚSQUEDA  &nbsp;&nbsp;<b className="cargo">{search.position_name}</b><span>/Compensación</span></span>
              <span className="volver"><img src={derecha} alt="" /><Link to='/aprobar_busqueda'>VOLVER AL LISTADO</Link></span>
          </div>
          <div className="formulario-compensacion">
              <span className="editar">EDITAR BÚSQUEDA <img src="images/menu/editar.png" alt="" /></span>
              <span className="datos">BÚSQUEDA {search.id} &nbsp;&nbsp;<b>{search.position_name}</b></span>
              <hr className="doble" />
              <span className="datos">e-mail <b>{search.started_email}</b></span>
              <hr />
              <span className="datos">Reportará a <b>{search.him_name}</b></span>
              <hr />
              <span className="datos">Director que aprueba la vacante <b>{search.director_username}</b></span>
              <hr />
              <span className="datos">Sector <b>{search.him_sector}</b></span>
              <hr />
              <span className="datos">Centro de costos asociado a la vacante <b>{search.him_cost_center}</b></span>
              <hr />
              <span className="datos">Nombre del puesto <b>{search.position_name}</b></span>
              <hr />
              <span className="datos">Seniority <b>{search.sniority_name}</b></span>
              <hr />
              <div className="descripcion">
                  <span>Descripción de las tareas a realizar</span>
                  <p>{search.description_task}</p>
              </div>
              <hr />
              <div className="descripcion">
                  <span>Descripción del perfil requerido: estudios, experiencia previa, etc.</span>
                  <p>{search.profile_description}</p>
              </div>
              <hr />
              <span className="datos">La vacante se genera a partir de <b>{search.vacancy_name}</b></span>
              <hr />
              <div className="botones">
                  <button className={ApproveRRHH.includes(userData.role_id) && (search.status_rrhh == 3 || search.status_rrhh == 6) ? 'd-none' : (ApproveDIRECTOR.includes(userData.role_id) && (search.status_director == 3 || search.status_director == 6)? 'd-none' : '')} 
                  id="aprobar"
                  disabled={isLoading ? true : false}
                  onClick={() => approve()}
                  >
                   {isLoading ? 'Cargando...' : ' APROBAR BÚSQUEDA'}
                  </button>
                  <DropdownButton id="copiar" title="COPIAR LINK DE BÚSQUEDA" className={RRHH.includes(userData.role_id) && (search.status_rrhh == 3 || search.status_rrhh == 6) ? '' : (userData.role_id == 3 && (search.status_director == 3 || search.status_director == 6)? '' : 'd-none')}>
                      <CopyToClipboard text={`${PRODUCTION_URL}/aplicar/${search.id}`} >
                    <Dropdown.Item id="copiar" className={ApproveRRHH.includes(userData.role_id) && (search.status_rrhh == 3 || search.status_rrhh == 6) ? '' : (ApproveDIRECTOR.includes(userData.role_id) && (search.status_director == 3 || search.status_director == 6)? '' : 'd-none')}>COPIAR LINK DE BÚSQUEDA</Dropdown.Item>
                      </CopyToClipboard>
                      <CopyToClipboard text={`${PRODUCTION_URL}/aplicar/${search.id}?origen=Linkedin`} >
                        <Dropdown.Item>COPIAR LINK DE BÚSQUEDA PARA LINKEDIN</Dropdown.Item>
                      </CopyToClipboard>
                  </DropdownButton>

                  {/* <button id="pausar">PAUSAR BÚSQUEDA</button> */}
                  <button className={ApproveRRHH.includes(userData.role_id) && (search.status_rrhh == 3 || search.status_rrhh == 6)? 'd-none' : (ApproveDIRECTOR.includes(userData.role_id) && (search.status_director == 3 || search.status_director == 6) ? 'd-none' : '')} 
                  id="negar"
                  disabled={isLoading ? true : false}
                  onClick={() => disapprove()}
                  >
                    {isLoading ? 'Cargando...' : 'NO APROBAR BÚSQUEDA'}
                  </button>

              </div>


              {((search.status !== 3 || search.status_rrhh !== 3 || search.status_director !== 3) && search.corporate === 1 && RRHH.includes(userData.role_id)) && (
                <Alert variant="warning" >
                  <Alert.Heading as="h5" className='mb-3'>Publicar en balanz.com</Alert.Heading>
                  <p>La búsqueda estará visible en balanz.com cuando esté aprobada y en proceso.</p>
                  <p><b>Departamento: </b>{search.departament}</p>
                  <Button onClick={() => setCorporateStatus()} variant="outline-warning">
                    Anular publicación
                  </Button>
                </Alert>
              )}
               {((search.status !== 3 || search.status_rrhh !== 3 || search.status_director !== 3) && search.intranet === 1 && RRHH.includes(userData.role_id) ) && (
                <Alert variant="warning" >
                  <Alert.Heading as="h5" className='mb-3'>Publicar en Intranet</Alert.Heading>
                  <p>La búsqueda estará visible en la Intranet cuando esté aprobada y en proceso.</p>
                  <p><b>Departamento: </b>{search.departament_intranet}</p>
                  <Button onClick={() => setIntranetStatus()} variant="outline-warning">
                    Anular publicación
                  </Button>
                </Alert>
              )}
          </div>
      </div>
        )
      }

      <Modal show={show} backdrop={'static'} className="modal_approve" onHide={handleClose}>
        <Modal.Header>
        </Modal.Header>
        <Modal.Body><span>Búsqueda aprobada exitosamente</span></Modal.Body>
        <Modal.Footer>
            <Link to='/busqueda'>
                <Button variant="primary">
                    Ir a Búsquedas
                </Button>
            </Link>
        </Modal.Footer>
      </Modal>

      <Modal show={showrechazar} backdrop={'static'} className="modal_approve" onHide={handleCloseRechzar}>
        <Modal.Header>
        </Modal.Header>
        <Modal.Body><span>Busqueda rechazada exitosamente</span></Modal.Body>
        <Modal.Footer>
            <Link to='/busqueda'>
                <Button variant="primary">
                    Ir a Busquedas
                </Button>
            </Link>
        </Modal.Footer>
      </Modal>
      <Footer />
    </>
  )
}

export default Compensation