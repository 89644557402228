import React, { useState, useContext, useEffect } from "react";
import { API } from "../../../../http-common";
import curriculum from "../../../../images/menu/curriculum.png";
import "../timeLineRRHH.css";
import $ from "jquery";
import { DataContext } from "../../../../context/dataContext";
import * as Constants from "../../../../constants";
import { UserContext } from "../../../../context/userContext";
import ModalNotes from "../../Applicants/ModalNotes/ModalNotes";
import {Col, Container, Row} from "react-bootstrap";
import Select from 'react-select'

const InfoTimeLine = ({
  idSearch,
  searchName,
  available,
  setAvailable,
  destacated,
  applicant,
  setPreselected,
  statusDetail
}) => {
  const {
    dataTableChanged,
    setDataTableChanged,
    currentSearchStatus,
    setCurrentSearchStatus,
  } = useContext(DataContext);
  const { userData, setUserData } = useContext(UserContext);
  const [modalNotes, setModalNotes] = useState(false);
  const [typeNotes, setTypeNotes] = useState('');
  const [countNotes, setCountNotes] = useState(0);
  const [perfilSelected, setPerfilSelected] = useState([])
  const [perfilSelectedTaking,setPerfilSelectedTaking] = useState([])
  const [perfilSelected2, setPerfilSelected2] = useState([])
  const [loading, setLoading] = useState(true);


  const getCountNotes = () => {
    API.get("candidates/"+applicant.candidate_id+"/count-notes").then(function (
        response
      ) {
        setCountNotes(response.data.notes)
      });
      
  }

  //DATOS PARA EL PROFILE SELECT
  const getCandidateById = async () => {
    let success = true;
    try{
      const res =  await API.get(`/candidates/${applicant.candidate_id}`)
      if(res.data.error !== 0){
        throw new Error(res.data.message)
      }
      const dataCandidate = res.data.data
      const resListProfiles =  await API.get("/candidates/profiles")
      if(resListProfiles.data.error !== 0){
        throw new Error(resListProfiles.data.message)
      }
      let perfilesData = []
      const profileList = resListProfiles.data.data
      perfilesData = profileList.map(i =>{
        return {value: i.id, label: i.profile_detail}
      })
     
      setPerfilSelected(perfilesData)
      const profile = profileList.find((x) => x.id === dataCandidate.profile_id )
      if(profile){
        setPerfilSelected2({value: profile.id, label: profile.profile_detail})
      } else {
        setPerfilSelected2({value: 0, label: "Selecionar un perfil"})
      }
      setLoading(false)

    }
    catch(e){
      console.log(e)
    }

  } 
  
 
  const selectChange = (e) => {
    setPerfilSelectedTaking(e.value)
  }


  const handleSaveForLater = () => {
    // if(null !== statusDetail && 11 === statusDetail){
       
    // }
    API.post(
      "applications/timeline/" + applicant.applicant_id + "/save-for-later",{
        profile_id:perfilSelectedTaking
      })
        .then((response) => {
          setDataTableChanged(true);
          handleReturn()
        })
        .catch();
      // setAvailable(false);
  }

  const handleOutstand = () => {
    API.post(
      "applications/timeline/" + applicant.applicant_id + "/highlight",
      {highlight: 1}

    )
      .then((response) => {
        setDataTableChanged(true);
      })
      .catch();
    setAvailable(false);
  };

  const handleDeclane = () => {
    API.post("/applications/timeline/"+applicant.applicant_id+"/discard", {
      id_search: idSearch,
      id_user: applicant.candidate_id,
    })
      .then((response) => {
        setDataTableChanged(true);
      })
      .catch();
    setAvailable(false);
  };

  const handleReturn = () => {
    setAvailable(false);
    setDataTableChanged(true);
    if(typeof setPreselected === 'function') {
      setPreselected(null);
    }

    if (window.location.href.indexOf("#") !== -1) {
      const nextURL = window.location.href.split("#")[0];
      const nextTitle = "My new page title";
      const nextState = { additionalInformation: "Updated the URL with JS" };

      window.history.replaceState(nextState, nextTitle, nextURL);
    }
  };


  useEffect(() => {
    getCountNotes()
    getCandidateById()
  }, [applicant])
  
  return (
    <>
      <div className="vista-candidato">
        <div className="content-cerrar">
          <span className="cerraar" onClick={() => handleReturn()}>
            CERRAR VISTA CANDIDATO <b>X</b>
          </span>
        </div>
        <div className="cabecera row">
          <div className="col-md-4 caja">
            <span>{applicant.username}</span>
          </div>
          <div className="col-md-4 caja"></div>
          <div className="col-md-4 caja">
            <span>
              NÚMERO DE POSTULACIONES: <b>{applicant.postulations_cant}</b>
            </span>
          </div>
        </div>
        <div className="datos row">
          <div className="col-md-4 caja">
            <span>
              DNI <b>{applicant.dni}</b>
            </span>
          </div>
          <div className="col-md-4 caja">
            <span>
              E-mail <b>{applicant.email}</b>
            </span>
          </div>
          <div className="col-md-4 caja">
            <span>
              Tel <b>{applicant.phone}</b>
            </span>
          </div>
        </div>
        <div className="datos row">
          <div className="col-md-4 caja">
            <span>
              Fecha de Nacimiento <b>{applicant.birthday}</b>
            </span>
          </div>
          <div className="col-md-4 caja">
            <span>
              Educación <b>{applicant.education_level}</b>
            </span>
          </div>
          <div className="col-md-4 caja">
            <span>
              Titulo <b>{applicant.degree}</b>
            </span>
          </div>
        </div>
        <div className="datos row">
          <div className="col-md-4 caja">
            <span>
              Universidad <b>{applicant.college}</b>
            </span>
          </div>
          <div className="col-md-4 caja">
            <span>
              Nombre de última posición <b>{applicant.last_position}</b>
            </span>
          </div>
          <div className="col-md-4 caja">
            <span>
              Gente a cargo <b>{applicant.subordinates}</b>
            </span>
          </div>
        </div>
        <div className="datos row" style={{ border: "none" }}>
          <div className="col-md-4 caja">
            <span>
              Años de Experiencia <b>{applicant.work_experience}</b>
            </span>
          </div>
          <div className="col-md-4 caja"></div>
          <div className="col-md-4 caja">
            <div className="d-flex align-items-center">
              {applicant.link !== "" && (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={applicant.link}
                >
                  <button className="curriculum">
                    <img src={curriculum} alt="" />
                    VER CV ADJUNTO
                  </button>
                </a>
              )}

              &nbsp;

              {(Constants.RRHH.includes(userData.role_id)) && (
                <div className="button-group-notes">
                  <div className="btn-group" role="group" aria-label="Basic example">
                    <button onClick={() => {
                      setModalNotes(true)
                      setTypeNotes('list')
                    }} type="button" className="btn btn-primary">VER NOTAS {countNotes > 0 && <span className="notes-count">{countNotes}</span>}</button>
                    <button onClick={() => {
                      setModalNotes(true)
                      setTypeNotes('add')
                    }} type="button" className="btn btn-primary">+</button>
                  </div>
                </div>
              )}


            </div>

          </div>
        </div>
        {!destacated && Constants.RRHH.includes(userData.role_id) && (
          <div className="content-btn-destacados">
            {/* {null !== statusDetail && 11 === statusDetail && (
              
            )} */}
            <button
                className="btn-database"
                data-toggle="tooltip"
                data-placement="bottom"
                type="button"
                onClick={() => $("#save_for_later").modal("show")}
              >
                Bases de datos
              </button>
            <button
              className="btn-destacados"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Al destacar un candidato, se selecciona para entrevistar."
              type="button"
              onClick={() => $("#destacar").modal("show")}
            >
              Destacar
            </button>
            <button
              className="btn-rechazado"
              data-toggle="tooltip"
              data-placement="bottom"
              title="Al destacar un candidato, se selecciona para entrevistar."
              type="button"
              onClick={() => $("#rechazar").modal("show")}
            >
              Descartar
            </button>
          </div>
        )}

        {!destacated &&
          (
            Constants.ROLE_DIRECTOR === userData.role_id ||
            Constants.ROLE_HIM === userData.role_id
          ) && (
            <div className="content-btn-destacados">
              <button
                className="btn-destacados"
                data-toggle="tooltip"
                data-placement="bottom"
                title="Al destacar un candidato, se selecciona para entrevistar."
                type="button"
                onClick={() => $("#destacar-HIM-Director").modal("show")}
              >
                Destacar/Avanzar en selección
              </button>
            </div>
          )}
      </div>

      <div
        className="modal fade"
        id="destacar-HIM-Director"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="basicModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body ">
              <span className="texto-modal">
                Se ha destacados a este candidato para notificar a Recursos
                Humanos que usted desea que avancen con el proceso de selección
                y realicen la primera entrevista con prioridad sobre otros
                candidatos
              </span>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                id="ok-destacar"
                data-dismiss="modal"
                onClick={() => handleOutstand()}
              >
                NOTIFICAR A RRHH
              </button>
              <button
                type="button"
                className="btn btn-primary"
                id="cancel-destacar"
                data-dismiss="modal"
              >
                CANCELAR
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="destacar"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="basicModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body ">
              <span className="texto-modal">
                ¿Estas seguro que deseas destacar este candidato?
              </span>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                id="ok-destacar"
                data-dismiss="modal"
                onClick={() => handleOutstand()}
              >
                Ok
              </button>
              <button
                type="button"
                className="btn btn-primary"
                id="cancel-destacar"
                data-dismiss="modal"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>

          { /* Base de datos con perfil */ }
    <div
    className="modal fade"
    id="save_for_later"
    tabIndex="-1"
    role="dialog"
    aria-labelledby="basicModal"
    aria-hidden="true"
  >
    <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
            &times;
          </button>
        </div>
        <div className="modal-body row">
        <Container >
                <Row className='with-line aling-items-center bsPrefix'>
                    <Col md="6" lg="6" sm="12" xs="12" >
                    <span className="texto-modal"> <strong>Seleccionar un perfil para el usuario</strong></span>
                    {
                      loading && (
                        <Select
                        onChange={selectChange}
                        defaultValue={perfilSelected2 ? perfilSelected2 : 'Seleccionar perfil'}
                        options={perfilSelected}
                        />
                      )
                    }
                    {
                      !loading && (
                        <Select
                        onChange={selectChange}
                        defaultValue={perfilSelected2 ? perfilSelected2 : 'Seleccionar perfil'}
                        options={perfilSelected}
                        />
                      )
                    }
                       
                       
                        
                    </Col>
                </Row>
                <span className="texto-modal">
                  ¿Estas seguro que deseas pasarlo a bases de datos? <br />
              </span>
          </Container>
          
        </div>
        <div className="modal-footer">
        <button
                type="button"
                className="btn btn-primary"
                id="ok-destacar"
                data-dismiss="modal"
                onClick={() => handleSaveForLater()}
              >
                Ok
              </button>
          <button
                type="button"
                className="btn btn-primary"
                id="cancel-destacar"
                data-dismiss="modal"
              >
                Cancelar
              </button>
        </div>
      </div>
    </div>
  </div>
      

      <div
        className="modal fade"
        id="rechazar"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="basicModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body ">
              <span className="texto-modal">
                ¿Estas seguro que deseas rechazar este candidato?
              </span>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                id="ok-destacar"
                data-dismiss="modal"
                onClick={() => handleDeclane()}
              >
                Ok
              </button>
              <button
                type="button"
                className="btn btn-primary"
                id="cancel-destacar"
                data-dismiss="modal"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>


      {ModalNotes && Constants.RRHH.includes(userData.role_id) && (
        <ModalNotes
          show={modalNotes}
          onHide={() => setModalNotes(false)}
          type={typeNotes}
          searchSelected={{ id: idSearch, name: searchName }}
          id={applicant.candidate_id}
        />
      )}
    </>
  );
};

export default InfoTimeLine;
