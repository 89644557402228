// import { useEffect, useState } from "react";
// import { Button } from "react-bootstrap";
import ReactExport from "react-export-excel";
import download from '../../../../images/menu/download.png'
import '../report.css'
// import axios from 'axios'
import moment from 'moment'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExcelRefusedReport = ({data}) =>{
  if(Array.isArray(data)) {
    data.forEach(d => {
      d['Fecha de Nacimiento'] = moment(d['Fecha de Nacimiento']).format('YYYY-MM-DD');
    })
  }
  return (
    <ExcelFile filename='Rechazos' element={<button className='boton-descargar-excel'><img src={download} width='20px' height='20px' alt='download'/></button>}>
      <ExcelSheet data={data} name="Candidatos">
        <ExcelColumn label="Nombre" value="Nombre"/>
        <ExcelColumn label="DNI" value="DNI"/>
        <ExcelColumn label="Motivo de Rechazo" value="Motivo de Rechazo"/>
        <ExcelColumn label="Status" value="Status"/>
        <ExcelColumn label="Teléfono" value="Teléfono"/>
        <ExcelColumn label="Título" value="Título"/>
        <ExcelColumn label="Universidad" value="Universidad"/>
        <ExcelColumn label="E-mail" value="E-mail"/>
        <ExcelColumn label="Último puesto" value="Último Puesto"/>
        <ExcelColumn label="Fecha de Nacimiento" value="Fecha de Nacimiento"/>
        <ExcelColumn label="Educacion" value="Educación"/>
        <ExcelColumn label="Candidato Resaltado" value="Candidato Resaltado"/>
        <ExcelColumn label="Presentado por" value="Presentado por"/>
        <ExcelColumn label="Busqueda" value="Busqueda"/>
        <ExcelColumn label="Centro de Costos" value="Centro de Costos"/>
      </ExcelSheet>
    </ExcelFile>
  );
}

export default ExcelRefusedReport