import Header from "../../includes/Header/Header";
import Menu from "../../includes/Menu/Menu";
import "./report.css";
import { Doughnut } from "react-chartjs-2";
import { Col, Container, Row } from "react-bootstrap";
import { useEffect, useState, useContext } from "react";
import ExcelSearchesReport from "./ExcelReport/ExcelSearchesReport";
import moment from "moment";
import { API } from "../../../http-common";
import { useSnackbar } from "react-simple-snackbar";
import { UserContext } from "../../../context/userContext";
import ExcelCandidatesReport from "./ExcelReport/ExcelCandidatesReport";
import ExcelAdmissionsReport from "./ExcelReport/ExcelAdmissionsReport";
import ExcelAreaAdmissionsReport from "./ExcelReport/ExcelAreaAdmissionsReport";
import ExcelRefusedReport from "./ExcelReport/ExcelRefusedReport";
import ExcelSearchStatus from "./ExcelReport/ExcelSearchStatus";
import LoadingList from "../Loading/LoadingList";
import * as Constants from "../../../constants";
import ExcelHunteoReport from "./ExcelReport/ExcelHunteoReport";
const Report = () => {
  // ------------------------ User Data ----------------------------
  const { userData, setUserData } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const role = userData.role_id;
  const RRHH = Constants.RRHH;

  const [deviceWidth, setDeviceWidth] = useState([window.innerWidth]);

  useEffect(() => {
    function handleResize() {
      setDeviceWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // ------------------------ State variables ----------------------------

  const [data, setData] = useState([]);
  const [cant, setCant] = useState({});
  const [total, setTotal] = useState(0);

  const [dropdownValue, setDropdownValue] = useState("1");
  const [monthDropdownValue, setMonthDropdownValue] = useState("");
  const [yearDropdownValue, setYearDropdownValue] = useState("");
  const [dateFromValue, setDateFromValue] = useState("");
  const [dateToValue, setDatetoValue] = useState("");

  // -------- Data Charts --------------------------------------

  const [dataSearches, setDataSearches] = useState({});
  const [dataApplicants, setDataApplicants] = useState({});
  const [dataSearchReason, setDataSearchReason] = useState({});
  const [dataReasonRefused, setDataReasonRefused] = useState({});
  const [dataNotAdvancedRrhh, setDataNotAdvancedRrhh] = useState({});
  const [dataNotAdvancedManager, setDataNotAdvancedManager] = useState({});
  const [dataNotAdvancedDirector, setDataNotAdvancedDirector] = useState({});
  const [dataAdmissionArea, setDataAdmissionArea] = useState({});
  const [dataSearchStatus, setDataSearchStatus] = useState({});
  const [reDrawGraphic] = useState(false);

  // -------- Data Charts --------------------------------------

  const [openSnackbar] = useSnackbar();

  //-------- Helper Functions ----------------------------------

  const reducer = (accumulator, currentValue) =>
    parseInt(accumulator) + parseInt(currentValue);

  // ------------------------  end State variables ----------------------------

  const getYears = () => {
    let years = [];
    for (let i = moment().year(); i >= 2020; i--) {
      years.push(i);
    }
    return years;
  };

  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const getData = (value, obj) => {
    let fechaInicial, fechaFinal;

    switch (value) {
      case "1":
        fechaInicial = moment()
          .subtract(1, "weeks")
          .startOf("isoWeek")
          .format("YYYY-MM-DD");
        fechaFinal = moment()
          .subtract(1, "weeks")
          .endOf("isoWeek")
          .format("YYYY-MM-DD");
        break;
      case "2":
        fechaInicial = moment()
          .subtract(1, "months")
          .startOf("month")
          .format("YYYY-MM-DD");
        fechaFinal = moment()
          .subtract(1, "months")
          .endOf("month")
          .format("YYYY-MM-DD");
        break;
      case "3":
        fechaInicial = moment([
          parseInt(obj.year),
          parseInt(obj.month) - 1,
        ]).format("YYYY-MM-DD");
        fechaFinal = moment(fechaInicial).endOf("month").format("YYYY-MM-DD");
        break;
      case "4":
        fechaInicial = obj.fechaInicio;
        fechaFinal = obj.fechaFin;
        break;
      case "5":
        fechaInicial = moment("01/01/2020").format("YYYY-MM-DD");
        fechaFinal = moment().format("YYYY-MM-DD");
        break;
      default:
        break;
    }

    try {

      API.get(`reports/${fechaInicial}/${fechaFinal}`)
        .then((res) => {

          if (res.data.error === 0) {
            setData(res.data.data.detail);
            // setCant(res.data.data.cant)
            const { cant } = res.data.data;

            let orderedList = cant.area_admissions.sort((a, b) => {
              return b.cant - a.cant;
            });

            let cc_report_cant = 0;
            if (Array.isArray(cant.cc_candidates)) {
              cant.cc_candidates.forEach((element) => {
                cc_report_cant += parseInt(element.cant)
              });
            }

            let hunteo = 0;
            if (Array.isArray(cant.hunteo)) {
              cant.hunteo.forEach((element) => {
                hunteo += parseInt(element.cant)
              });
            }

            let areaLabels = [];
            let areaData = [];
            let others = 0;

            for (let i = 0; i < orderedList.length; i++) {
              if (i > 8) {
                others += parseInt(orderedList[i].cant);
              } else {
                areaData.push(orderedList[i].cant);
                areaLabels.push(orderedList[i].name);
              }
              if (i > 8 && i === orderedList.length - 1) {
                areaData.push(others);
                areaLabels.push("Otros");
              }
            }

            setTotal(areaData.reduce(reducer, 0));


            setDataAdmissionArea({
              labels: areaLabels,
              datasets: [
                {
                  label: "Admision por area",
                  data: areaData,
                  backgroundColor: [
                    "rgba(255, 99, 132, 0.6)",
                    "rgba(54, 162, 235, 0.6)",
                    "rgba(255, 206, 86, 0.6)",
                    "rgba(75, 192, 192, 0.6)",
                    "rgba(153, 102, 255, 0.6)",
                    "rgba(195, 41, 80, 0.6)",
                    "rgba(141, 20, 66, 0.6)",
                    "rgba(125, 221, 135, 0.6)",
                    "rgba(197, 225, 103, 0.6)",
                    "rgba(189, 195, 199, 0.6)",
                  ],
                  borderColor: [
                    "rgba(255, 99, 132, 1)",
                    "rgba(54, 162, 235, 1)",
                    "rgba(255, 206, 86, 1)",
                    "rgba(75, 192, 192, 1)",
                    "rgba(153, 102, 255, 1)",
                    "rgba(195, 41, 80, 1)",
                    "rgba(141, 20, 66, 1)",
                    "rgba(125, 221, 135, 1)",
                    "rgba(197, 225, 103, 1)",
                    "rgba(189, 195, 199, 1)",
                  ],
                  borderWidth: 1,
                },
              ],
            });
            setDataSearchStatus({
              datasets: [
                {
                  label: "# of Votes",
                  data: [
                    cant.candidates.acepta_propuesta,
                    cant.candidates.avanza,
                    cant.candidates.contratado,
                    cant.candidates.no_acepta_propuesta,
                    cant.candidates.no_avanza,
                    cant.candidates.pen_entrevista_2,
                    cant.candidates.pendiente,
                  ],
                  backgroundColor: [
                    "rgba(240, 128, 128, 0.6)",
                    "rgba(220, 20, 60, 0.6)",
                    "rgba(199, 21, 133, 0.6)",
                    "rgba(255, 69, 0, 0.6)",
                    "rgba(255, 165, 0, 0.6)",
                    "rgba(238, 130, 238, 0.6)",
                    "rgba(34, 139, 34, 0.6)",
                    "rgba(0, 139, 139, 0.6)",
                  ],
                  borderColor: [
                    "rgba(240, 128, 128, 1)",
                    "rgba(220, 20, 60, 1)",
                    "rgba(199, 21, 133, 1",
                    "rgba(255, 69, 0, 1)",
                    "rgba(255, 165, 0, 1)",
                    "rgba(238, 130, 238, 1)",
                    "rgba(34, 139, 34, 1)",
                    "rgba(0, 139, 139, 1)",
                  ],
                  borderWidth: 1,
                },
              ],
            });

            setDataSearches({
              labels: [
                "En proceso",
                "Finalizada",
                "Incompleta",
                "Pausada",
                "Pendiente",
                "Rechazada",
              ],
              datasets: [
                {
                  label: "# of Votes",
                  data: [
                    cant.searches.en_proceso,
                    cant.searches.finalizada,
                    cant.searches.incompleta,
                    cant.searches.pausada,
                    cant.searches.pendiente,
                    cant.searches.rechazada,
                  ],
                  // data: [1, 1, 1, 1, 1, 1],
                  backgroundColor: [
                    "rgba(255, 99, 132, 0.6)",
                    "rgba(54, 162, 235, 0.6)",
                    "rgba(255, 206, 86, 0.6)",
                    "rgba(75, 192, 192, 0.6)",
                    "rgba(153, 102, 255, 0.6)",
                  ],
                  borderColor: [
                    "rgba(255, 99, 132, 1)",
                    "rgba(54, 162, 235, 1)",
                    "rgba(255, 206, 86, 1)",
                    "rgba(75, 192, 192, 1)",
                    "rgba(153, 102, 255, 1)",
                  ],
                  borderWidth: 1,
                },
              ],
            });

            setDataApplicants({
              labels: [
                "Acepta Prop.",
                "Avanza",
                "Contratado",
                "No Acepta",
                "No Avanza",
                "Pendiente Entr. 1",
                "Pendiente Entr. 2",
                "Pendiente",
                "Guardado"
              ],
              datasets: [
                {
                  label: "# of Votes",
                  data: [
                    cant.candidates.acepta_propuesta,
                    cant.candidates.avanza,
                    cant.candidates.contratado,
                    cant.candidates.no_acepta_propuesta,
                    cant.candidates.no_avanza,
                    cant.candidates.pen_entrevista_1,
                    cant.candidates.pen_entrevista_2,
                    cant.candidates.pendiente,
                    cant.candidates.no_avanza_guardado,
                  ],
                  backgroundColor: [
                    "rgba(240, 128, 128, 0.6)",
                    "rgba(220, 20, 60, 0.6)",
                    "rgba(199, 21, 133, 0.6)",
                    "rgba(255, 69, 0, 0.6)",
                    "rgba(255, 165, 0, 0.6)",
                    "rgba(238, 130, 238, 0.6)",
                    "rgba(34, 139, 34, 0.6)",
                    "rgba(0, 139, 139, 0.6)",
                    "rgba(250, 145, 47, 0.6)",
                  ],
                  borderColor: [
                    "rgba(240, 128, 128, 1)",
                    "rgba(220, 20, 60, 1)",
                    "rgba(199, 21, 133, 1",
                    "rgba(255, 69, 0, 1)",
                    "rgba(255, 165, 0, 1)",
                    "rgba(238, 130, 238, 1)",
                    "rgba(34, 139, 34, 1)",
                    "rgba(0, 139, 139, 1)",
                    "rgba(250, 145, 47, 1)",
                  ],
                  borderWidth: 1,
                },
              ],
            });

            setDataSearchReason({
              labels: [
                "Licencia",
                "Crecimiento",
                "Mov. Interno",
                "Renuncia",
                "Desvinculación",
              ],
              datasets: [
                {
                  label: "# of Votes",
                  data: [
                    cant.searches_reason.licencia,
                    cant.searches_reason.crecimiento,
                    cant.searches_reason.movimiento_interno,
                    cant.searches_reason.renuncia,
                    cant.searches_reason.desvinculacion,
                  ],
                  backgroundColor: [
                    "rgba(255, 99, 132, 0.6)",
                    "rgba(54, 162, 235, 0.6)",
                    "rgba(255, 206, 86, 0.6)",
                    "rgba(75, 192, 192, 0.6)",
                    "rgba(153, 102, 255, 0.6)",
                  ],
                  borderColor: [
                    "rgba(255, 99, 132, 1)",
                    "rgba(54, 162, 235, 1)",
                    "rgba(255, 206, 86, 1)",
                    "rgba(75, 192, 192, 1)",
                    "rgba(153, 102, 255, 1)",
                  ],
                  borderWidth: 1,
                },
              ],
            });
            setDataNotAdvancedRrhh({
              labels: [
                "Candidato no adaptativo a nuestra cultura",
                "Candidato no interesado",
                "Candidato optó por otra propuesta",
                "Candidato sobrecalificado",
                "Entrevista de cortesía",
                "Seleccionamos otro candidato",
                "Candidato no alcanza los requisitos del puesto",
                "La propuesta salarial no cumple su expectativa",
                "Otros",
              ],
              datasets: [
                {
                  label: "# of Votes",
                  data: [
                    cant.notAdvanceRRHH.no_adapta_a_cultura,
                    cant.notAdvanceRRHH.no_interesado,
                    cant.notAdvanceRRHH.opto_por_otra_propuesta,
                    cant.notAdvanceRRHH.sobrecalificado,
                    cant.notAdvanceRRHH.entrevista_de_cortesia,
                    cant.notAdvanceRRHH.select_otro_candidato,
                    cant.notAdvanceRRHH.no_alcanza_requisitos,
                    cant.notAdvanceRRHH.salario_con_cumple_expectativa,
                    cant.notAdvanceRRHH.otros
                  ],
                  backgroundColor: [
                    "rgba(255, 99, 132, 0.6)",
                    "rgba(253, 126, 20)",
                    "rgba(54, 162, 235, 0.6)",
                    "rgba(255, 206, 86, 0.6)",
                    "rgba(75, 192, 192, 0.6)",
                    "rgba(102,16,242)",
                    "rgba(153, 102, 255, 0.6)",
                    "rgba(0, 128, 0, 0.6)",
                    "rgba(128, 0, 128, 0.6)"
                  ],
                  borderColor: [
                    "rgba(255, 99, 132, 0.6)",
                    "rgba(253, 126, 20)",
                    "rgba(54, 162, 235, 0.6)",
                    "rgba(255, 206, 86, 0.6)",
                    "rgba(75, 192, 192, 0.6)",
                    "rgba(102,16,242)",
                    "rgba(153, 102, 255, 0.6)",
                  ],
                  borderWidth: 1,
                },
              ],
            });
            setDataNotAdvancedManager({
              labels: [
                "Candidato no adaptativo a nuestra cultura",
                "Candidato no alcanza los requisitos del puesto",
                "Candidato optó por otra propuesta",
                "La propuesta salarial no cumple su expectativa",
                "Seleccionamos otro candidato",
                "Candidato sobrecalificado",
              ],
              datasets: [
                {
                  label: "# of Votes",
                  data: [
                    cant.notAdvanceMANAGER.no_adapta_a_cultura,
                    cant.notAdvanceMANAGER.no_alcanza_requisitos,
                    cant.notAdvanceMANAGER.opto_por_otra_propuesta,
                    cant.notAdvanceMANAGER.salario_con_cumple_expectativa,
                    cant.notAdvanceMANAGER.select_otro_candidato,
                    cant.notAdvanceMANAGER.sobrecalificado,
                  ],
                  backgroundColor: [
                    "rgba(255, 99, 132, 0.6)",
                    "rgba(253, 126, 20)",
                    "rgba(54, 162, 235, 0.6)",
                    "rgba(255, 206, 86, 0.6)",
                    "rgba(75, 192, 192, 0.6)",
                    "rgba(153, 102, 255, 0.6)",
                  ],
                  borderColor: [
                    "rgba(255, 99, 132, 0.6)",
                    "rgba(253, 126, 20)",
                    "rgba(54, 162, 235, 0.6)",
                    "rgba(255, 206, 86, 0.6)",
                    "rgba(75, 192, 192, 0.6)",
                    "rgba(153, 102, 255, 0.6)",
                  ],
                  borderWidth: 1,
                },
              ],
            });
            setDataNotAdvancedDirector({
              labels: [
                "Candidato no adaptativo a nuestra cultura",
                "Candidato no alcanza los requisitos del puesto",
                "Candidato optó por otra propuesta",
                "La propuesta salarial no cumple su expectativa",
                "Seleccionamos otro candidato",
                "Candidato sobrecalificado",
              ],
              datasets: [
                {
                  label: "# of Votes",
                  data: [
                    cant.notAdvanceDIRECTOR.no_adapta_a_cultura,
                    cant.notAdvanceDIRECTOR.no_alcanza_requisitos,
                    cant.notAdvanceDIRECTOR.opto_por_otra_propuesta,
                    cant.notAdvanceDIRECTOR.salario_con_cumple_expectativa,
                    cant.notAdvanceDIRECTOR.select_otro_candidato,
                    cant.notAdvanceDIRECTOR.sobrecalificado
                  ],
                  backgroundColor: [
                    "rgba(255, 99, 132, 0.6)",
                    "rgba(253, 126, 20)",
                    "rgba(54, 162, 235, 0.6)",
                    "rgba(255, 206, 86, 0.6)",
                    "rgba(75, 192, 192, 0.6)",
                    "rgba(153, 102, 255, 0.6)",
                  ],
                  borderColor: [
                    "rgba(255, 99, 132, 0.6)",
                    "rgba(253, 126, 20)",
                    "rgba(54, 162, 235, 0.6)",
                    "rgba(255, 206, 86, 0.6)",
                    "rgba(75, 192, 192, 0.6)",
                    "rgba(153, 102, 255, 0.6)",
                  ],
                  borderWidth: 1,
                },
              ],
            });
            setDataNotAdvancedManager({
              labels: [
                "Candidato no adaptativo a nuestra cultura",
                "Candidato no alcanza los requisitos del puesto",
                "Candidato optó por otra propuesta",
                "La propuesta salarial no cumple su expectativa",
                "Seleccionamos otro candidato",
                "Candidato sobrecalificado",
              ],
              datasets: [
                {
                  label: "# of Votes",
                  data: [
                    cant.notAdvanceMANAGER.no_adapta_a_cultura,
                    cant.notAdvanceMANAGER.no_alcanza_requisitos,
                    cant.notAdvanceMANAGER.opto_por_otra_propuesta,
                    cant.notAdvanceMANAGER.salario_con_cumple_expectativa,
                    cant.notAdvanceMANAGER.select_otro_candidato,
                    cant.notAdvanceMANAGER.sobrecalificado
                  ],
                  backgroundColor: [
                    "rgba(255, 99, 132, 0.6)",
                    "rgba(253, 126, 20)",
                    "rgba(54, 162, 235, 0.6)",
                    "rgba(255, 206, 86, 0.6)",
                    "rgba(75, 192, 192, 0.6)",
                    "rgba(153, 102, 255, 0.6)",
                  ],
                  borderColor: [
                    "rgba(255, 99, 132, 0.6)",
                    "rgba(253, 126, 20)",
                    "rgba(54, 162, 235, 0.6)",
                    "rgba(255, 206, 86, 0.6)",
                    "rgba(75, 192, 192, 0.6)",
                    "rgba(153, 102, 255, 0.6)",
                  ],
                  borderWidth: 1,
                },
              ],
            });
            setDataReasonRefused({
              labels: [
                "Salario Bajo",
                "Retiene Emp. Actual",
                "Otra oferta",
                "No Fit cultural",
                "Otros",
              ],
              datasets: [
                {
                  label: "# of Votes",
                  data: [
                    cant.reasons_refused.salario,
                    cant.reasons_refused.retienen,
                    cant.reasons_refused.oferta,
                    cant.reasons_refused.fit,
                    cant.reasons_refused.otros,
                  ],
                  backgroundColor: [
                    "rgba(255, 99, 132, 0.6)",
                    "rgba(54, 162, 235, 0.6)",
                    "rgba(255, 206, 86, 0.6)",
                    "rgba(75, 192, 192, 0.6)",
                    "rgba(153, 102, 255, 0.6)",
                  ],
                  borderColor: [
                    "rgba(255, 99, 132, 1)",
                    "rgba(54, 162, 235, 1)",
                    "rgba(255, 206, 86, 1)",
                    "rgba(75, 192, 192, 1)",
                    "rgba(153, 102, 255, 1)",
                  ],
                  borderWidth: 1,
                },
              ],
            });

            setCant({
              busquedas:
                parseInt(cant.searches.en_proceso) +
                parseInt(cant.searches.finalizada) +
                parseInt(cant.searches.incompleta) +
                parseInt(cant.searches.pausada) +
                parseInt(cant.searches.pendiente) +
                parseInt(cant.searches.rechazada),
              candidatos:
                parseInt(cant.candidates.acepta_propuesta) +
                parseInt(cant.candidates.avanza) +
                parseInt(cant.candidates.contratado) +
                parseInt(cant.candidates.no_acepta_propuesta) +
                parseInt(cant.candidates.no_avanza) +
                parseInt(cant.candidates.pen_entrevista_1) +
                parseInt(cant.candidates.pen_entrevista_2) +
                parseInt(cant.candidates.pendiente) +
                parseInt(cant.candidates.no_avanza_guardado),
              ingresos: parseInt(cant.admissions),
              time_fill: parseInt(cant.time_to_fill),
              tiempo_aprobacion: parseInt(cant.time_to_approve),
              motivo_busqueda:
                parseInt(cant.searches_reason.licencia) +
                parseInt(cant.searches_reason.crecimiento) +
                parseInt(cant.searches_reason.movimiento_interno) +
                parseInt(cant.searches_reason.renuncia) +
                parseInt(cant.searches_reason.desvinculacion),
              motivo_rechazo:
                parseInt(cant.reasons_refused.salario) +
                parseInt(cant.reasons_refused.retienen) +
                parseInt(cant.reasons_refused.oferta) +
                parseInt(cant.reasons_refused.fit) +
                parseInt(cant.reasons_refused.otros),
              cc_candidates: parseInt(cc_report_cant),
              not_advance_rrhh:
              parseInt(cant.notAdvanceRRHH.no_adapta_a_cultura) +
              parseInt(cant.notAdvanceRRHH.no_interesado) +
              parseInt(cant.notAdvanceRRHH.opto_por_otra_propuesta)+
              parseInt(cant.notAdvanceRRHH.sobrecalificado) +
              parseInt(cant.notAdvanceRRHH.entrevista_de_cortesia) +
              parseInt(cant.notAdvanceRRHH.select_otro_candidato)+
              parseInt(cant.notAdvanceRRHH.otros),
              not_advance_manager:
              parseInt(cant.notAdvanceMANAGER.no_adapta_a_cultura) +
              parseInt(cant.notAdvanceMANAGER.no_alcanza_requisitos) +
              parseInt(cant.notAdvanceMANAGER.opto_por_otra_propuesta)+
              parseInt(cant.notAdvanceMANAGER.salario_con_cumple_expectativa) +
              parseInt(cant.notAdvanceMANAGER.select_otro_candidato)+
              parseInt(cant.notAdvanceMANAGER.sobrecalificado),
              not_advance_director:
              parseInt(cant.notAdvanceDIRECTOR.no_adapta_a_cultura) +
              parseInt(cant.notAdvanceDIRECTOR.no_alcanza_requisitos) +
              parseInt(cant.notAdvanceDIRECTOR.opto_por_otra_propuesta)+
              parseInt(cant.notAdvanceDIRECTOR.salario_con_cumple_expectativa) +
              parseInt(cant.notAdvanceDIRECTOR.select_otro_candidato)+
              parseInt(cant.notAdvanceDIRECTOR.sobrecalificado) ,
              hunteo: parseInt(hunteo)
            });
            setData(res.data.data.detail);
          }

          setLoading(false)
        })

        .catch((error) => {
          setLoading(false)
          openSnackbar("Ha ocurrido un error. Intente nuevamente");
        });
    } catch (error) {
      openSnackbar("Ha ocurrido un error. Intente nuevamente");
    }

  };

  useEffect(() => {
    getData(dropdownValue);
  }, []);

  return (
    <>
      <Header />
      <Menu />
      <div className="content-reportes">
        <Container>
          <div className="reportes">
            <div className="selector-time">
              <Row>
                <Col>
                  <select
                    className="dropdown"
                    name="time-interval"
                    onChange={(e) => setDropdownValue(e.target.value)}
                    defaultValue="1"
                  >
                    <option value="1">Última semana</option>
                    <option value="2">Último mes</option>
                    <option value="3">Por mes</option>
                    <option value="4">Desde - Hasta</option>
                    <option value="5">Todos los datos</option>
                  </select>
                </Col>
                <Col>
                  <button
                    className="search-button"
                    onClick={() => {
                      setLoading(true)
                      switch (dropdownValue) {
                        case "1":
                          getData(dropdownValue, {});
                          break;
                        case "2":
                          getData(dropdownValue, {});
                          break;
                        case "3":
                          if (
                            dropdownValue === "3" &&
                            monthDropdownValue !== "" &&
                            yearDropdownValue !== ""
                          ) {
                            getData(dropdownValue, {
                              month: monthDropdownValue,
                              year: yearDropdownValue,
                            });
                          } else {
                            setLoading(false)
                            openSnackbar("Ingrese en mes y año correctamente");
                          }
                          break;
                        case "4":
                          if (
                            dropdownValue === "4" &&
                            dateFromValue !== "" &&
                            dateToValue !== "" &&
                            dateFromValue <= dateToValue
                          ) {
                            getData(dropdownValue, {
                              fechaInicio: dateFromValue,
                              fechaFin: dateToValue,
                            });
                          } else if (dateFromValue > dateToValue) {
                            setLoading(false)
                            openSnackbar(
                              "La fecha de inicio no puede ser mayor a la fecha final"
                            );
                          } else {
                            setLoading(false)
                            openSnackbar(
                              "Ingrese la fecha de inicio y fecha final"
                            );
                          }

                          break;
                        case "5":
                          getData(dropdownValue, {});
                          break;
                        default:
                          break;
                      }
                    }}
                    disabled={loading}
                  >
                    {loading ? (<LoadingList size={15} padding="0" color='#fff' />) : 'Buscar' }

                  </button>
                </Col>
              </Row>
              {dropdownValue === "3" && (
                <Row>
                  <Col xs={12} sm={12} md={4} className="time-interval-options">
                    <span>Mes:</span>
                    <select
                      className="selector-month"
                      name="month"
                      defaultValue={""}
                      onChange={(e) => setMonthDropdownValue(e.target.value)}
                    >
                      <option value="" selected>
                        Mes
                      </option>
                      {months.map((item) => (
                        <option key={item} value={`${item}`}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </Col>
                  <Col xs={12} sm={12} md={4} className="time-interval-options">
                    <span>Año:</span>
                    <select
                      className="selector-month"
                      name="year"
                      defaultValue={""}
                      onChange={(e) => setYearDropdownValue(e.target.value)}
                    >
                      <option value="" selected>
                        Año
                      </option>
                      {getYears().map((year) => (
                        <option key={year} value={`${year}`}>
                          {year}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>
              )}
              {dropdownValue === "4" && (
                <Row>
                  <Col xs={12} sm={12} md={4} className="time-interval-options">
                    <span>Desde: </span>
                    <input
                      type="date"
                      max={moment().format("YYYY-MM-DD")}
                      onChange={(e) => setDateFromValue(e.target.value)}
                      required
                    />
                  </Col>
                  <Col xs={12} sm={12} md={4} className="time-interval-options">
                    <span>Hasta: </span>
                    <input
                      type="date"
                      max={moment().format("YYYY-MM-DD")}
                      onChange={(e) => setDatetoValue(e.target.value)}
                      required
                    />
                  </Col>
                </Row>
              )}
            </div>
            <div className="contenido">
              <Row>
                <Col sm={12} md={6} className="margin-t">
                  <div className="card">
                    <div className="content-title">
                      <span className="title">Búsquedas</span>
                      <span className="number">
                        {cant.busquedas === undefined ? 0 : cant.busquedas}
                      </span>
                    </div>
                    {(cant.busquedas !== undefined && cant.busquedas > 0) ? (
                      <>
                        <div className="graph">
                          <Doughnut
                            data={dataSearches}
                            legend={{
                              position: deviceWidth > 1280 ? "left" : "top",
                              align: "start",
                            }}
                            options={{
                              reponsive: true,
                              maintainAspectRatio: false,
                            }}
                            redraw={reDrawGraphic}
                          />
                        </div>
                        <div className="download-section">
                          <ExcelSearchesReport data={data.searches} />
                        </div>
                      </>
                    ): (
                      <div className="graph d-flex align-items-center justify-content-center">
                        <p>No se encontraron datos para el rango de fechas seleccionadas</p>
                      </div>
                    )}

                  </div>
                </Col>
                <Col sm={12} md={6} className="margin-t">
                  <div className="card">
                    <div className="content-title">
                      <span className="title">Candidatos</span>
                      <span className="number">
                        {cant.candidatos === undefined ? 0 : cant.candidatos}
                      </span>
                    </div>

                    {(cant.candidatos !== undefined && cant.candidatos > 0) ? (
                      <>
                        <div className="graph">
                          <Doughnut
                            data={dataApplicants}
                            legend={{
                              position: deviceWidth > 1280 ? "left" : "top",
                              align: "start",
                            }}
                            options={{
                              reponsive: true,
                              maintainAspectRatio: false,
                            }}
                            redraw={reDrawGraphic}
                          />
                        </div>
                        <div className="download-section">
                          <ExcelCandidatesReport data={data.candidates} />
                        </div>
                      </>
                    ): (
                      <div className="graph d-flex align-items-center justify-content-center">
                        <p>No se encontraron datos para el rango de fechas seleccionadas</p>
                      </div>
                    )}

                  </div>
                </Col>

                {RRHH.includes(role) && (
                  <>
                    <Col sm={12} md={6} className="margin-t">
                      <div className="card">
                        <div className="content-title">
                          <span className="title">Admisiones por Area</span>
                          <span className="number">
                            {total === 0 ? 0 : total}
                          </span>
                        </div>

                        {(total > 0) ? (
                          <>
                            <div className="graph">
                              <Doughnut
                                data={dataAdmissionArea}
                                legend={{
                                  position: deviceWidth > 1280 ? "left" : "top",
                                  align: "start",
                                }}
                                options={{
                                  reponsive: true,
                                  maintainAspectRatio: false,
                                }}
                                redraw={reDrawGraphic}
                              />
                            </div>
                            <div className="download-section">
                              <ExcelAreaAdmissionsReport
                                data={data.area_admissions}
                              />
                            </div>
                          </>
                        ): (
                          <div className="graph d-flex align-items-center justify-content-center">
                            <p>No se encontraron datos para el rango de fechas seleccionadas</p>
                          </div>
                        )}

                      </div>
                    </Col>
                    <Col sm={6} md={6}>
                      <div className="card-small">
                        <div className="content">
                          <span className="title">Estado de busquedas</span>
                          <span className="number">
                            {cant.cc_candidates === undefined ||
                            cant.cc_candidates === null ||
                            isNaN(cant.cc_candidates)
                              ? 0
                              : cant.cc_candidates}
                          </span>
                        </div>
                        {
                          (cant.cc_candidates !== undefined && cant.cc_candidates !== null && !isNaN(cant.cc_candidates) && cant.cc_candidates > 0) && (
                            <div className="download-section">
                              <ExcelSearchStatus data={data.cc_candidates} />
                            </div>
                          )
                        }

                      </div>

                      <div className="card-small">
                        <div className="content">
                          <span className="title">HUNTEO</span>
                          <span className="number">
                            {cant.hunteo === undefined ||
                            cant.hunteo === null ||
                            isNaN(cant.hunteo)
                              ? 0
                              : cant.hunteo}
                          </span>
                        </div>
                        {
                          (cant.hunteo !== undefined && cant.hunteo !== null && !isNaN(cant.hunteo) && cant.hunteo > 0) && (
                            <div className="download-section">
                              <ExcelHunteoReport data={data.hunteo} />
                            </div>
                          )
                        }
                      </div>
                    </Col>
                  </>
                )}
              </Row>
              <Row>
                <Col sm={4} md={4}>
                  <div className="card-small">
                    <div className="content">
                      <span className="title">Ingresos</span>
                      <span className="number">
                        {cant.ingresos === undefined ||
                        cant.ingresos === null ||
                        isNaN(cant.ingresos)
                          ? 0
                          : cant.ingresos}
                      </span>
                    </div>
                    {(cant.ingresos !== undefined && cant.ingresos !== null && !isNaN(cant.ingresos) && cant.ingresos > 0) && (
                      <div className="download-section">
                        <ExcelAdmissionsReport data={data.admissions} />
                      </div>
                    )}

                  </div>
                </Col>
                <Col sm={4} md={4}>
                  <div className="card-small">
                    <div className="content">
                      <span className="title">Time to fill</span>
                      <span className="number">
                        {cant.time_fill === undefined ||
                        cant.time_fill === null ||
                        isNaN(cant.time_fill)
                          ? 0
                          : cant.time_fill}{" "}
                        dias
                      </span>
                    </div>
                  </div>
                </Col>
                <Col sm={4} md={4}>
                  <div className="card-small">
                    <div className="content">
                      <span className="title">Tiempo de aprobación</span>
                      <span className="number">
                        {cant.tiempo_aprobacion === undefined ||
                        cant.tiempo_aprobacion === null ||
                        isNaN(cant.tiempo_aprobacion)
                          ? 0
                          : cant.tiempo_aprobacion}{" "}
                        dias
                      </span>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={6}>
                  <div className="card">
                    <div className="content-title">
                      <span className="title">Motivo de busqueda</span>
                      <span className="number">
                        {cant.motivo_busqueda === undefined
                          ? 0
                          : cant.motivo_busqueda}
                      </span>
                    </div>

                    {(cant.motivo_busqueda !== undefined && cant.motivo_busqueda > 0) ? (
                          <>
                        <div className="graph">
                          <Doughnut
                            data={dataSearchReason}
                            legend={{
                              position: deviceWidth > 1280 ? "left" : "top",
                              align: "start",
                            }}
                            options={{
                              reponsive: true,
                              maintainAspectRatio: false,
                            }}
                            redraw={reDrawGraphic}
                          />
                        </div>
                      </>
                    ): (
                      <div className="graph d-flex align-items-center justify-content-center">
                        <p>No se encontraron datos para el rango de fechas seleccionadas</p>
                      </div>
                    )}
                  </div>
                </Col>
                <Col sm={12} md={6}>
                  <div className="card">
                    <div className="content-title">
                      <span className="title">Motivo de rechazo</span>
                      <span className="number">
                        {cant.motivo_rechazo === undefined
                          ? 0
                          : cant.motivo_rechazo}
                      </span>
                    </div>

                    {(cant.motivo_rechazo !== undefined && cant.motivo_rechazo > 0) ? (
                        <>
                          <div className="graph">
                            <Doughnut
                              data={dataReasonRefused}
                              legend={{
                                position: deviceWidth > 1280 ? "left" : "top",
                                align: "start",
                              }}
                              options={{
                                reponsive: true,
                                maintainAspectRatio: false,
                              }}
                              redraw={reDrawGraphic}
                            />
                        </div>
                        <div className="download-section">
                          <ExcelRefusedReport data={data.reasons_refused} />
                        </div>
                      </>
                    ): (
                      <div className="graph d-flex align-items-center justify-content-center">
                        <p>No se encontraron datos para el rango de fechas seleccionadas</p>
                      </div>
                    )}



                  </div>
                </Col>
              </Row>
              <Row>
              <Col sm={12} md={6}>
                  <div className="card">
                    <div className="content-title">
                      <span className="title">NO AVANZA RRHH</span>
                      <span className="number">
                        {cant.not_advance_rrhh === undefined
                          ? 0
                          : cant.not_advance_rrhh}
                      </span>
                    </div>
                    {(cant.not_advance_rrhh !== undefined && cant.not_advance_rrhh > 0) ? (
                          <>
                        <div className="graph">
                          <Doughnut
                            data={dataNotAdvancedRrhh}
                            legend={{
                              position: deviceWidth > 1280 ? "left" : "top",
                              align: "start",
                            }}
                            options={{
                              reponsive: true,
                              maintainAspectRatio: false,
                            }}
                            redraw={reDrawGraphic}
                          />
                        </div>
                      </>
                    ): (
                      <div className="graph d-flex align-items-center justify-content-center">
                        <p>No se encontraron datos para el rango de fechas seleccionadas</p>
                      </div>
                    )}
                  </div>
                </Col>
                <Col sm={12} md={6}>
                  <div className="card">
                    <div className="content-title">
                      <span className="title">NO AVANZA GERENTE</span>
                      <span className="number">
                        {cant.not_advance_manager === undefined
                          ? 0
                          : cant.not_advance_manager}
                      </span>
                    </div>

                    {(cant.not_advance_manager !== undefined && cant.not_advance_manager > 0) ? (
                          <>
                        <div className="graph">
                          <Doughnut
                            data={dataNotAdvancedManager}
                            legend={{
                              position: deviceWidth > 1280 ? "left" : "top",
                              align: "start",
                            }}
                            options={{
                              reponsive: true,
                              maintainAspectRatio: false,
                            }}
                            redraw={reDrawGraphic}
                          />
                        </div>
                      </>
                    ): (
                      <div className="graph d-flex align-items-center justify-content-center">
                        <p>No se encontraron datos para el rango de fechas seleccionadas</p>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
              <Col sm={12} md={6}>
                  <div className="card">
                    <div className="content-title">
                      <span className="title">NO AVANZA DIRECTOR</span>
                      <span className="number">
                        {cant.not_advance_director === undefined
                          ? 0
                          : cant.not_advance_director}
                      </span>
                    </div>

                    {(cant.not_advance_director !== undefined && cant.not_advance_director > 0) ? (
                          <>
                        <div className="graph">
                          <Doughnut
                            data={dataNotAdvancedDirector}
                            legend={{
                              position: deviceWidth > 1280 ? "left" : "top",
                              align: "start",
                            }}
                            options={{
                              reponsive: true,
                              maintainAspectRatio: false,
                            }}
                            redraw={reDrawGraphic}
                          />
                        </div>
                      </>
                    ): (
                      <div className="graph d-flex align-items-center justify-content-center">
                        <p>No se encontraron datos para el rango de fechas seleccionadas</p>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Report;
