import "./login.css";
import logo from "../../images/login/logo.png";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import ComeBack from '../../images/login/come-back.png'

const Login = ({ login, showError, authType, defaultUser = "", backAction }) => {
  const checkAuthType = [0, 1].includes(authType);

  // Variables de formulario Login
  const [user, setUser] = useState(defaultUser);
  const [password, setPassword] = useState("");

  //Variables de comprobacion
  const [errorUser, setErrorUser] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);

  const [submit, setSubmit] = useState(false);
  useEffect(() => {
    setUser(defaultUser)
  }, [defaultUser])
  useEffect(() => {
    if (submit) {
      if (!errorUser && !errorPassword) {
        sendInformation();
      }
      setSubmit(false);
    }
  }, [submit]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await checkInputs();
    setSubmit(true);
  };

  //Metodo para el envío de la información a la
  const sendInformation = () => {

    //seccion donde se debe de poner el try-catch para el envío de variables
    try {
      login(user, password);
    } catch (error) {
      showError(error);
    }

    // setDefaultValues();

  };

  const setDefaultValues = () => {
    setUser("");
    setPassword("");
  };

  const checkInputs = () => {
    if (user.length === 0) {
      setErrorUser(true);
    } else {
      setErrorUser(false);
    }

    if (password.length === 0 && checkAuthType) {
      setErrorUser(true);
    } else {
      setErrorUser(false);
    }
  };

  return (
    <div className="content-login">
      <div className="contenido">
        <div className="content-balanz">
          <img src={logo} alt="Logo" />
          <span className="hr">
            HR panel <span>login</span>
          </span>
        </div>
        <div className="content-form">
          <form method="post" onSubmit={handleSubmit}>
            <div className="username">
              <input
                type="text"
                value={user}
                onChange={(e) => setUser(e.target.value)}
                className={errorUser ? "error" : ""}
                placeholder="usuario"
                id="usuario"
                readOnly={checkAuthType}
              />
              {checkAuthType && (
                <button type="button" onClick={backAction} class="come-back-action">
                  <img src={ComeBack} alt="come back" />
                </button>

              )}
            </div>

            {checkAuthType && (
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className={errorUser ? "error" : ""}
                placeholder="contraseña"
                id="contrasena"
              />
            )}

            <input type="submit" value="INGRESAR" id="ingresar" />
            {/*<Link to='/panel'>
                <input type="submit" value="INGRESAR" id="ingresar" />
              </Link>*/}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
