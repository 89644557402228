// import { useEffect, useState } from "react";
// import { Button } from "react-bootstrap";
import ReactExport from "react-export-excel";
import download from '../../../../images/menu/download.png'
import '../report.css'
import moment from 'moment'
import  {PRODUCTION_URL}  from '../../../../constants'
import { useEffect, useState } from "react";
// import axios from 'axios'

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const checkDateEmpty = (date_str) =>{
  if(date_str !== '-') {
    return moment(date_str).format('DD/MM/YYYY');
  }
  return  '-';
}
const ExcelCandidatesReport = ({data}) =>{
  const [report, setReport] = useState([])

  useEffect(() => {
      if(data === undefined) return
      setReport(data.map(d => {
        d['Fecha de Nacimiento'] = moment(d['Fecha de Nacimiento']).format('YYYY-MM-DD');
        d['Fecha 1er entrevista realizada'] = checkDateEmpty(d['Fecha 1er entrevista realizada']);
        d['Fecha estado actual'] = checkDateEmpty(d['Fecha estado actual']);
        d['Fecha 2da entrevista realizada'] = checkDateEmpty(d['Fecha 2da entrevista realizada']);
        d['Fecha 3ra  entrevista realizada'] = checkDateEmpty(d['Fecha 3ra  entrevista realizada']);
        d['Fecha de Envío de Propuesta'] = checkDateEmpty(d['Fecha de Envío de Propuesta']);
        d['Fecha de Aceptación de Propuesta'] = checkDateEmpty(d['Fecha de Aceptación de Propuesta']);
        d['Fecha Psicotécnico'] = checkDateEmpty(d['Fecha Psicotécnico']);
        d['Fecha Examen médico'] = checkDateEmpty(d['Fecha Examen médico']);
       d['link al perfil'] = `${PRODUCTION_URL}/candidatos/${d['candidate_id']}`
        return d;
      }))
  }, [data, setReport]);



  return (
    <ExcelFile filename='Candidatos' element={<button className='boton-descargar-excel'><img src={download} width='20px' height='20px' alt='download'/></button>}>
      <ExcelSheet data={report} name="Candidatos">
        <ExcelColumn label="Nombre" value="Nombre"/>
        <ExcelColumn label="DNI" value="DNI"/>
        <ExcelColumn label="Teléfono" value="Teléfono"/>
        <ExcelColumn label="Fecha de Nacimiento" value="Fecha de Nacimiento"/>
        <ExcelColumn label="Email" value="Email"/>
        <ExcelColumn label="Educación" value="Educación"/>
        <ExcelColumn label="Universidad" value="Universidad"/>
        <ExcelColumn label="Título" value="Título"/>
        <ExcelColumn label="Empresa" value="Empresa"/>
        <ExcelColumn label="¿Cómo encontró esta postulación?" value="Origen"/>
        <ExcelColumn label="Id Busqueda" value="Id Busqueda"/>
        <ExcelColumn label="Busqueda" value="Busqueda"/>
        <ExcelColumn label="Recomendado por" value="Recomendado por"/>
        <ExcelColumn label="Hiring Manager" value="Hiring Manager"/>
        <ExcelColumn label="Director" value="Director"/>
        <ExcelColumn label="Candidato Resaltado" value="Candidato Resaltado"/>
        <ExcelColumn label="Presentado por" value="Presentado por"/>
        <ExcelColumn label="Fecha 1er entrevista realizada" value="Fecha 1er entrevista realizada"/>
        <ExcelColumn label="Status" value="status"/>
        <ExcelColumn label="Fecha 2da entrevista realizada" value="Fecha 2da entrevista realizada"/>
        <ExcelColumn label="Fecha 3ra  entrevista realizada" value="Fecha 3ra  entrevista realizada"/>
        <ExcelColumn label="Fecha de Envío de Propuesta" value="Fecha de Envío de Propuesta"/>
        <ExcelColumn label="Fecha de Aceptación de Propuesta" value="Fecha de Aceptación de Propuesta"/>
        <ExcelColumn label="Fecha Psicotécnico" value="Fecha Psicotécnico"/>
        <ExcelColumn label="Status Psicotécnico" value="status_psi"/>
        <ExcelColumn label="Fecha Examen médico" value="Fecha Examen médico"/>
        <ExcelColumn label="Area" value="Area"/>
        <ExcelColumn label="Sector" value="Sector"/>
        <ExcelColumn label="Estado actual" value="Status"/>
        <ExcelColumn label="Perfil" value="perfil"/>
        <ExcelColumn label="Motivo No Avanza" value="not_advance_attributes"/>
        <ExcelColumn label="Fecha estado actual" value="Fecha estado actual"/>
        <ExcelColumn label="link al perfil" value="link al perfil"/>
      </ExcelSheet>
    </ExcelFile>
  );
}

export default ExcelCandidatesReport