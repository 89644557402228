import React, { useContext, useEffect, useRef, useState } from "react";
import favorito from "../../../../../../images/menu/favorito.png";
import favorito_resaltado from "../../../../../../images/menu/resaltado.png";
import "../../../timeLineRRHH.css";
import $, { data } from "jquery";
import { DataContext } from "../../../../../../context/dataContext";
import { API } from "../../../../../../http-common";
import { UserContext } from "../../../../../../context/userContext";
import moment from "moment";
import { useSnackbar } from "react-simple-snackbar";
import { CopyToClipboard } from "react-copy-to-clipboard";
import EditModule from "../EditModules";

const Accordion8 = ({
  available,
  setAvailable,
  idSearch,
  module,
  applicant,
  disableEdit,
  onDataChange = null
}) => {
  const options = {
    style: {
      backgroundColor: "#c60000",
      color: "white",
      borderRadius: "10px",
      fontSize: "18px",
      textAlign: "center",
    },
    closeStyle: {
      fontSize: "16px",
    },
  };

  const optionsButton = {
    style: {
      backgroundColor: "#0078c6",
      color: "white",
      borderRadius: "10px",
      fontSize: "18px",
      textAlign: "center",
    },
    closeStyle: {
      fontSize: "16px",
    },
  };

  const [open] = useSnackbar(options);
  const [openLink] = useSnackbar(optionsButton);

  const { userData, setUserData } = useContext(UserContext);
  const {
    dataTableChanged,
    setDataTableChanged,
    currentSearchStatus,
    setCurrentSearchStatus,
  } = useContext(DataContext);
  const [submit, setSubmit] = useState(false);
  // const [dateStart, setDateStart] = useState((module.data.date!=='') ? module.data.date: moment(new Date()).format("YYYY-MM-DD"))
  // const [outstanding, setOutstanding] = useState(applicant.outstanding)
  // const [highlighted, setHighlighted] = useState(false)
  // const [feedback, setFeedback] = useState(module.data.feedback)
  // const [moduleStatus, setModuleStatus] = useState(module.status)

  const firstUpdate = useRef(true);

  // const [gender, setGender] = useState('')
  // const [area, setArea] = useState('')
  // const [sector, setSector] = useState('')
  // const [costs, setCosts] = useState('')
  // const [supervisor, setSupervisor] = useState('')
  // const [director, setDirector] = useState('')

  const [gender, setGender] = useState(module.data.formValues.Genero);
  // const [gender, setGender] = useState(2)
  const [area, setArea] = useState(module.data.formValues.Area);
  const [sector, setSector] = useState(module.data.formValues.Sector);
  const [costs, setCosts] = useState(module.data.formValues.CentroDeCostos);
  const [supervisor, setSupervisor] = useState(
    module.data.formValues.Supervisor
  );
  const [director, setDirector] = useState(module.data.formValues.Director);

  const [genderError, setGenderError] = useState(false);
  const [areaError, setAreaError] = useState(false);
  const [sectorError, setSectorError] = useState(false);
  const [costsError, setCostsError] = useState(false);
  const [supervisorError, setSupervisorError] = useState(false);
  const [directorError, setDirectorError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [genderOptions, setGenderOptions] = useState([]);
  const [areaOptions, setAreaOptions] = useState([]);
  const [sectorOptions, setSectorOptions] = useState([]);
  const [costsOptions, setCostsOptions] = useState([]);
  const [supervisorOptions, setSupervisorOptions] = useState([]);
  const [directorOptions, setDirectorOptions] = useState([]);

  const [feedbackError, setFeedbackError] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [moduleWritable, setModuleWritable] = useState(module.writable);
  const [dataModule, setDataModule] = useState(module);

  const handleClose = () => setShowModal(false);

  const editionMode = () => {
    setModuleWritable(1);
    //module.writable = 1;
    setShowModal(false);
  };

  const toggleClass = () => {
    let modal = document.getElementsByClassName("modal_edit_applicant");
    if(modal.length > 0){
      setTimeout(() => {
        document.body.classList.add("modal-open");
      }, 500)
    }
  }

  const isModal = document.getElementsByClassName("modal_edit_applicant");

  /*const getCandidate = async () => {
    await API.get("/applications/timeline/" + applicant.applicant_id).then(
      function (response) {
        if (response.data.error === 0) {
          setDataModule(response.data.data.modules[7]);
        } else {
          //todo
        }
      }
    );
  };*/

  const sendData = () => {
    setIsLoading(true);
    const fecha = moment(new Date()).format("YYYY-MM-DD");
    const dataForm = {
      candidate_id: applicant.candidate_id,
      admission_date: fecha,
      applicant_id: applicant.applicant_id,
      search_id: idSearch,
      module_id: 8,
      employees_id: userData.id,
      uploaded_date: fecha,
      gender: gender,
      area: area,
      sector: sector,
      costs: costs,
      supervisor: supervisor,
      director: director,
    };

    try {
      API.post("/applications/public/recruitment-form", dataForm)
      .then((response) => {
        if (response.data.error === 1) {
          open("Ha ocurrido un error, intentelo nuevamente.");
        } else {
          
          setModuleWritable(0);
          if(null !== onDataChange) onDataChange()
          toggleClass()
         setIsLoading(false);
        }
      })
    }
    catch (error) {
      console.log(error)
      setIsLoading(false);
    }
      
  };

  // const handleInterview8 = (e) => {
  //   let status = e.currentTarget.id
  //   switch (status) {
  //     case 'ok_avanza':
  //       setModuleStatus(1);
  //       break;
  //     case 'ok_no_avanza':
  //       setModuleStatus(2);
  //       break;
  //     case 'ok_guarda':
  //       setModuleStatus (3);
  //       break;
  //     default:
  //       setModuleStatus(0)
  //   }
  //   setSubmit(true)
  //   checkInputs()
  // }

  const checkInputs = () => {
    let flag = 0;

    if (gender === "") {
      flag++;
      setGenderError(true);
    } else {
      setGenderError(false);
    }

    if (area === "") {
      flag++;
      setAreaError(true);
    } else {
      setAreaError(false);
    }

    if (sector === "") {
      flag++;
      setSectorError(true);
    } else {
      setSectorError(false);
    }

    if (costs === "") {
      flag++;
      setCostsError(true);
    } else {
      setCostsError(false);
    }

    if (supervisor === "") {
      flag++;
      setSupervisorError(true);
    } else {
      setSupervisorError(false);
    }

    if (director === "") {
      flag++;
      setDirectorError(true);
    } else {
      setDirectorError(false);
    }

    return flag;
  };

  const getValues = () => {
    setGenderOptions(module.data.formOptions.Genero);
    setAreaOptions(module.data.formOptions.Area);
    setSectorOptions(module.data.formOptions.Sector);
    setCostsOptions(module.data.formOptions.CentroDeCostos);
    setSupervisorOptions(module.data.formOptions.Supervisor);
    setDirectorOptions(module.data.formOptions.Director);
  };

  const handleClick = () => {
    if (checkInputs() === 0) {
      $("#avanza-busqueda-8").modal("show");
      // sendData()
    }
    setSubmit(false);
  };

  useEffect(() => {
    if (firstUpdate.current) {
      getValues();
      return;
    }
    //getCandidate();
    // if (submit && checkInputs() === 0) {
    //   sendData()
    // }
    // setSubmit(false)
  }, []);

  return (
    <>
      <div className="card">
        <div className="card-header" id="headingEigth">
          <h5 className="mb-0">
            <button
              className="btn btn-link collapsed"
              data-toggle="collapse"
              data-target="#collapseEigth"
              aria-expanded="false"
              aria-controls="collapseEigth"
            >
              Datos para CRM
            </button>
            <span
              className="mas"
              data-toggle="collapse"
              data-target="#collapseEigth"
              aria-expanded="false"
              aria-controls="collapseEigth"
            >
              +
            </span>
          </h5>
        </div>
        {/* <div id="collapseEigth" className={1 === 1 ? "collapse" : "collapse disabled-accordion"} aria-labelledby="headingEigth" > */}
        <div
          id="collapseEigth"
          className={"collapse show"}
          aria-labelledby="headingEigth"
        >
          {/* <div id="collapseEigth" className={module.writable === 1 ? "collapse" : "collapse disabled-accordion"} aria-labelledby="headingEigth" > */}
          <div
            className={`card-body ${
              disableEdit ? "disabled" : !(moduleWritable === 1) && "disabled"
            }`}
          >
            {/* <div className='card-body'> */}

            <div className="barra-superior">
              <div
                className={
                  disableEdit
                    ? "left disabled-accordion"
                    : moduleWritable === 0
                    ? "left disabled-accordion"
                    : "left"
                }
              >
                {/* <div className={`left ${!(module.writable === 0) && "disabled"}`}> */}
                <div className="item">
                  <span>Fecha</span>
                  {/* <label>{(module.data.date!=='') ? module.data.date : moment(new Date()).format("DD/MM/YYYY") }</label> */}
                  <label>{moment(new Date()).format("DD/MM/YYYY")}</label>
                </div>
                <div className="item">
                  <span>Proceso realizado por</span>
                  {
                    <label>
                      {module.data.interviewer.username !== ""
                        ? module.data.interviewer.username
                        : ""}
                    </label>
                  }

                  {/* <label>{module.data.interviewer.username}</label> */}
                </div>
              </div>
              {moduleWritable === 0 && module.visible === 1 && (
                <div className="right">
                  <CopyToClipboard text={`${module.link}`}>
                    {/* <button className='boton-copy-link' id="copiar" onClick={() => openLink('Link copiado en el portapapeles')}>
                        COPIAR LINK
                      </button> */}
                    <div
                      style={{ width: "auto" }}
                      className="curriculum notas"
                      id="copiar"
                      onClick={() =>
                        openLink("Link copiado en el portapapeles")
                      }
                    >
                      COPiAR LINK
                    </div>
                  </CopyToClipboard>
                </div>
              )}
            </div>
            <div
              className={`content-complete-information ${
                (disableEdit || moduleWritable === 0) && "disabled-accordion"
              }`}
            >
              <div className="line">
                <div className="item">
                  <span>Género</span>
                  <select
                    className="input"
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                  >
                    <option value="">Seleccionar</option>
                    {genderOptions.map((item) => (
                      <option
                        key={`${item.name}_${item.value}`}
                        value={item.value}
                      >
                        {item.name}
                      </option>
                    ))}
                  </select>
                  <span className={genderError ? "span-error" : "d-none"}>
                    Seleccione una opción
                  </span>
                </div>
                <div className="item">
                  <span>Área</span>
                  <select
                    className="input"
                    value={area}
                    onChange={(e) => setArea(e.target.value)}
                  >
                    <option value="">Seleccionar</option>
                    {areaOptions.map((item) => (
                      <option
                        key={`${item.name}_${item.value}`}
                        value={item.value}
                      >
                        {item.name}
                      </option>
                    ))}
                  </select>
                  <span className={areaError ? "span-error" : "d-none"}>
                    Seleccione una opción
                  </span>
                </div>
              </div>
              <div className="line">
                <div className="item">
                  <span>Sector</span>
                  <select
                    className="input"
                    value={sector}
                    onChange={(e) => setSector(e.target.value)}
                  >
                    <option value="">Seleccionar</option>
                    {sectorOptions.map((item) => (
                      <option
                        key={`${item.name}_${item.value}`}
                        value={item.value}
                      >
                        {item.name}
                      </option>
                    ))}
                  </select>
                  <span className={sectorError ? "span-error" : "d-none"}>
                    Seleccione una opción
                  </span>
                </div>
                <div className="item">
                  <span>Centro de costos</span>
                  <select
                    className="input"
                    value={costs}
                    onChange={(e) => setCosts(e.target.value)}
                  >
                    <option value="">Seleccionar</option>

                    {costsOptions.map((item) => (
                      <option
                        key={`${item.name}_${item.value}`}
                        value={item.value}
                      >
                        {item.name}
                      </option>
                    ))}
                  </select>
                  <span className={costsError ? "span-error" : "d-none"}>
                    Seleccione una opción
                  </span>
                </div>
              </div>
              <div className="line">
                <div className="item">
                  <span>Supervisor</span>
                  <select
                    className="input"
                    value={supervisor}
                    onChange={(e) => setSupervisor(e.target.value)}
                  >
                    <option value="">Seleccionar</option>
                    {supervisorOptions.map((item) => (
                      <option
                        key={`${item.name}_${item.value}`}
                        value={item.value}
                      >
                        {item.name}
                      </option>
                    ))}
                  </select>
                  <span className={supervisorError ? "span-error" : "d-none"}>
                    Seleccione una opción
                  </span>
                </div>
                <div className="item">
                  <span>Director</span>
                  <select
                    className="input"
                    value={director}
                    onChange={(e) => setDirector(e.target.value)}
                  >
                    <option value="">Seleccionar</option>
                    {directorOptions.map((item) => (
                      <option
                        key={`${item.name}_${item.value}`}
                        value={item.value}
                      >
                        {item.name}
                      </option>
                    ))}
                  </select>
                  <span className={directorError ? "span-error" : "d-none"}>
                    Seleccione una opción
                  </span>
                </div>
              </div>
              <div className="line">
                <div className="item"></div>
              </div>
              {/* <span className={feedbackError ? 'span-error' : 'd-none'}>Campo Vacio</span> */}
            </div>
            {moduleWritable === 1 && (
              // {1 === 1 &&
              <div className="botones-vista">
                <button id="aprobar" disabled={isLoading ? true : false} onClick={() => handleClick()}>
                  REGISTRAR
                </button>
              </div>
            )}
            {dataModule.completed === 1 &&
              moduleWritable === 0 &&
              module.editable === "all" && (
                <div>
                  <EditModule
                    editionMode={editionMode}
                    showModal={showModal}
                    handleClose={handleClose}
                    setShowModal={setShowModal}
                  ></EditModule>
                </div>
              )}

            {/* {module.writable === 0 &&
            {0 === 0 &&
            <div className="estado">
              {(() => {
                switch (module.status) {
                  case 0:
                    return (<span className="pausado">PENDIENTE</span>);
                    // break;
                  case 1:
                    return (<span className="aprobado">AVANZA</span>);
                    // break;
                  case 2:
                    return (<span className="negado">NO AVANZA</span>);
                    // break;
                  case 3:
                    return (<span className="pausado">GUARDADO PARA OTRA BÚSQUEDA</span>);
                    // break;
                  default:
                    return (<span className="pausado">PENDIENTE</span>);
                }
              })()}

            </div>
            } */}
          </div>
        </div>
      </div>

      {/* Modal de avanza candidato */}
      <div
        className="modal fade"
        id="avanza-busqueda-8"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="basicModal"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-hidden="true"
              >
                &times;
              </button>
            </div>
            <div className="modal-body ">
              <span className="texto-general">
                ¿Estas seguro de suministrar esta información?
              </span>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                id="ok_avanza"
                data-dismiss="modal"
                onClick={() => sendData()}
              >
                Ok
              </button>
              <button
                type="button"
                className="btn btn-primary"
                id="cancel"
                data-dismiss="modal"
                onClick={() => {
                  toggleClass()
                }}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal de avanza candidato */}
      {/* <div className="modal fade" id="guardar-busqueda-2" tabIndex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            </div>
            <div className="modal-body ">
              <span className="texto-general">¿Estas seguro que deseas guardar este perfil para otra búsqueda?</span>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" id="ok_guarda" data-dismiss="modal"
                // onClick={e => handleInterview2(e)}
              >Ok</button>
              <button type="button" className="btn btn-primary" id="cancel" data-dismiss="modal">Cancelar</button>
            </div>
          </div>
        </div>
      </div> */}

      {/* Modal de no avanza candidato */}
      {/* <div className="modal fade" id="no-avanza-busqueda-2" tabIndex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            </div>
            <div className="modal-body ">
              <span className="texto-general">¿Estas seguro que deseas rechazar este candidato?</span>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" id="ok_no_avanza" data-dismiss="modal"
                // onClick={(e) => handleInterview2(e)}
              >Ok</button>
              <button type="button" className="btn btn-primary" id="cancel" data-dismiss="modal">Cancelar</button>
            </div>
          </div>
        </div>
      </div> */}

      {/* Modal de decidir mas tarde */}
      {/* <div className="modal fade" id="decidir-busqueda-2" tabIndex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-hidden="true">&times;</button>
            </div>
            <div className="modal-body ">
              <span className="texto-general">¿Estas seguro que deseas decidir mas tarde?</span>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-primary" id="ok_dicidir" data-dismiss="modal"
                // onClick={(e) => handleInterview2(e)}
              >Ok</button>
              <button type="button" className="btn btn-primary" id="cancel" data-dismiss="modal">Cancelar</button>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Accordion8;
